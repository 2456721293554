import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { Alert } from '@material-ui/lab';
import { UserType } from 'app/enums/UserType';
import { Formik } from 'formik';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

export interface ILoginOption {
  value: 'Contact' | 'Employee' | 'Facilitator' | 'Learner' | string;
  display: string;
}

const LoginJWT: React.FC<any> = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const availableLoginAs = useMemo<ILoginOption[]>(
    () => [
      { value: 'Contact', display: 'Client' },
      { value: 'Employee', display: 'Employee' },
      { value: 'Facilitator', display: 'Facilitator' },
      { value: 'Learner', display: 'Learner' },
    ],
    [],
  );
  const userType = useMemo<keyof typeof UserType>(() => {
    const userTypeParam = searchParams.get('userType') ?? '';
    if (availableLoginAs.map((e) => e.value).includes(userTypeParam)) {
      return userTypeParam as keyof typeof UserType;
    } else {
      return 'Learner';
    }
  }, [searchParams, availableLoginAs]);

  useEffect(() => {
    console.log(searchParams.get('userType'));
  }, [searchParams]);

  return (
    <Formik
      initialValues={{
        idOrEmail: '',
        password: '',
        userType: userType,
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        userType: Yup.string().required(),
        idOrEmail: Yup.string().required('ID or Email is required'),
        password: Yup.string().required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.idOrEmail, values.password, values.userType);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box sx={{ mb: 1 }}>
            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={12} sm={5}>
                <Alert icon={<SupervisedUserCircleIcon />} severity='info'>
                  <Typography variant='subtitle2'>I am a </Typography>
                </Alert>
              </Grid>
              <Grid item xs={12} sm={7}>
                <FormControl fullWidth variant='outlined'>
                  <Select
                    value={values.userType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name='userType'
                    variant='outlined'
                  >
                    {availableLoginAs.map((userType) => (
                      <MenuItem key={userType.value} value={userType.value}>
                        {userType.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <TextField
            autoFocus
            error={Boolean(touched.idOrEmail && errors.idOrEmail)}
            fullWidth
            helperText={touched.idOrEmail && errors.idOrEmail}
            label='Email or ID Number'
            margin='normal'
            name='idOrEmail'
            onBlur={handleBlur}
            onChange={handleChange}
            type='idOrEmail'
            value={values.idOrEmail}
            variant='outlined'
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label='Password'
            margin='normal'
            name='password'
            onBlur={handleBlur}
            onChange={handleChange}
            type='password'
            value={values.password}
            variant='outlined'
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color='primary'
              disabled={isSubmitting || !isValid || !dirty}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
