import { Breadcrumbs, Grid, Typography, Link, makeStyles, Box, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const GenericHeader: React.FC<IProps> = (props) => {
  const { className, title, breadCrumbs, action, chips } = props;

  const classes = useStyles();

  return (
    <Grid container justifyContent='space-between' spacing={2} className={className}>
      <Grid item>
        <Typography color='textPrimary' variant='h4'>
          {title}
        </Typography>
        {breadCrumbs && (
          <Breadcrumbs
            aria-label='breadcrumb'
            separator={<ChevronRightIcon fontSize='small' />}
            className={classes.breadcrumbs}
          >
            {breadCrumbs.map((breadCrumb) =>
              breadCrumb.to ? (
                <Link
                  color='textPrimary'
                  component={RouterLink}
                  to={breadCrumb.to}
                  variant='subtitle2'
                  key={breadCrumb.title}
                >
                  {breadCrumb.title}
                </Link>
              ) : (
                <Typography color='textSecondary' variant='subtitle2' key={breadCrumb.title}>
                  {breadCrumb.title}
                </Typography>
              ),
            )}
          </Breadcrumbs>
        )}
        {chips && (
          <Box sx={{ mt: 1 }}>
            {chips.map((chip) => (
              <Chip
                variant='default'
                label={chip.label}
                size='small'
                color='primary'
                style={{ backgroundColor: chip.color }}
                className={classes.chip}
                icon={chip.icon}
              />
            ))}
          </Box>
        )}
      </Grid>
      <Grid item>{action}</Grid>
    </Grid>
  );
};

interface IProps {
  className?: string;
  title: string;
  breadCrumbs?: IBreadcrumb[];
  action?: React.ReactNode;
  chips?: IChip[];
}

interface IBreadcrumb {
  title: string;
  to?: string;
}

interface IChip {
  label: string;
  color: string;
  icon?: any;
}

export default GenericHeader;
