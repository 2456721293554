import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useCallback, useState, useEffect, ChangeEvent } from 'react';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import buildQuery, { Expand } from 'odata-query';
import { IProject } from 'app/models/responses/IProject';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import useMounted from 'hooks/useMounted';
import { rootConfig } from 'config';
import { ApprovalRequestStatus } from 'app/enums/ApprovalRequestStatus';
import { IProjectExtraTimeRequest } from 'app/models/responses/IProjectExtraTimeRequest';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Formik } from 'formik';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { SelectOneAsync } from 'components/_dashboard';
import { isTSConstructSignatureDeclaration } from '@babel/types';
import { CourseType } from 'app/enums/CourseType';
import { IClient } from 'app/models/responses/IClient';
import { IProjectClient } from 'app/models/entities/IProjectClient';
import { IProjectLearnerMetricResponse } from 'app/models/responses/IProjectLearnerMetricResponse';

interface IProps {
  projectClient: IProjectClient;
  onUpdate: (updatedProjectClient: IProjectClient) => void;
  project: IProject;
}

const ProjectClientItem: React.FC<IProps> = (props) => {
  const { projectClient, onUpdate, project } = props;
  const theme = useTheme();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setEditing] = useState(false);
  const [metrics, setMetrics] = useState<IProjectLearnerMetricResponse | null>(null);

  const getMetrics = useCallback(async () => {
    try {
      const response = await axios.get(
        `${rootConfig.odataRoute}/projectClients(${projectClient.id})/getLearnerMetrics()`,
      );
      if (mounted.current) {
        setMetrics(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, projectClient.id, enqueueSnackbar]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  if (!metrics) return <LinearProgress />;

  return (
    <Formik
      initialValues={{
        statusId: projectClient.statusId,
        totalLearnerRequirement: projectClient.totalLearnerRequirement,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        statusId: Yup.number().integer().positive().required('Status is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const updatedEntity: any = {
            ...projectClient,
            ...values,
          };
          const diff = jsonpatch.compare(projectClient, updatedEntity);
          const expand: Expand<IProjectClient> = ['client', 'status'];
          const queryString = buildQuery({ expand });
          const response = await axios.patch<IProjectClient>(
            `${rootConfig.odataRoute}/projectClients(${projectClient.id})${queryString}`,
            diff,
          );
          const updatedProjectClient = response.data;
          resetForm({
            values: {
              statusId: updatedProjectClient.statusId,
              totalLearnerRequirement: updatedProjectClient.totalLearnerRequirment,
            },
          });
          onUpdate(updatedProjectClient);
          enqueueSnackbar(`Successfully updated project client.`, { variant: 'success' });
          setStatus({ success: true });
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
          setEditing(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        resetForm,
        dirty,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props} style={{ width: '100%' }}>
          <div>
            {isEditing ? (
              <>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectOneAsync
                        name='statusId'
                        entityId={values.statusId || null}
                        entityType='projectStatuses'
                        labelFields={['name']}
                        filterFields={['name']}
                        onChange={(event: any, value: any) =>
                          setFieldValue('statusId', value?.id || null)
                        }
                        label='Status'
                      />
                    </Grid>
                    {!project.recruitmentRequest && (
                      <Grid item xs={12}>
                        <TextField
                          value={values.totalLearnerRequirement ?? ''}
                          variant='outlined'
                          fullWidth
                          name='totalLearnerRequirement'
                          onChange={(event) =>
                            setFieldValue('totalLearnerRequirement', event.target.value)
                          }
                          label='Total Learners'
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <Divider />
              </>
            ) : (
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Expected</TableCell>
                      <TableCell>Metric</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography color='textPrimary' variant='subtitle2'>
                          Total Learners
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {project.recruitmentRequest
                          ? metrics.expectedLearnerTotal
                          : projectClient.totalLearnerRequirement ?? '-'}
                      </TableCell>
                      <TableCell>{metrics.learnerTotal ?? '-'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}

            <CardActions>
              {isEditing ? (
                <>
                  <Button
                    color='primary'
                    disabled={isSubmitting || !isValid || !dirty}
                    type='submit'
                    variant='contained'
                  >
                    Save Changes
                  </Button>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      setEditing(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  color='primary'
                  variant='outlined'
                  onClick={() => setEditing(true)}
                >
                  Edit
                </Button>
              )}
            </CardActions>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProjectClientItem;
