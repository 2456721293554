import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';
import { EntityContext } from 'app/enums/EntityContext';
import { ProjectUserRole } from 'app/enums/ProjectUserRole';
import { IProject } from 'app/models/responses/IProject';
import { IProjectUser } from 'app/models/responses/IProjectUser';
import { ExceptionHandler } from 'components';
import { GenericAvatar } from 'components/_dashboard';
import { rootConfig } from 'config';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { EditMember } from './components';

const useStyles = makeStyles((theme) => ({
  root: {},
  skeleton: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
}));

interface IProps {
  project: IProject;
}

const Team: React.FC<IProps> = (props) => {
  const { project } = props;
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const classes = useStyles();

  const [projectCoordinator, setProjectCoordinator] = useState<IProjectUser | null | undefined>(
    undefined,
  );
  const [learningCoordinator, setLearningCoordinator] = useState<IProjectUser | null | undefined>(
    undefined,
  );
  const [learningSolutionsConsultant, setLearningSolutionsConsultant] = useState<
    IProjectUser | null | undefined
  >(undefined);
  const [facilitator, setFacilitator] = useState<IProjectUser | null | undefined>(undefined);
  const [assessor, setAssessor] = useState<IProjectUser | null | undefined>(undefined);
  const [moderator, setModerator] = useState<IProjectUser | null | undefined>(undefined);
  const [selectedMember, setSelectedMember] = useState<IProjectUser | null>(null);
  const [selectedRole, setSelectedRole] = useState<ProjectUserRole | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const getProjectCoordinator = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.ProjectCoordinator],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setProjectCoordinator(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);
  const getLearningCoordinator = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.LearningCoordinator],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setLearningCoordinator(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);
  const getLearningSolutionsConsultant = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.LearningSolutionsConsultant],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setLearningSolutionsConsultant(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);
  const getFacilitator = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.Facilitator],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setFacilitator(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);
  const getAssessor = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.Assessor],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setAssessor(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);
  const getModerator = useCallback(async () => {
    try {
      const expand = {
        user: { select: ['id', 'fullName', 'userName'], expand: { avatarFileItem: {} } as any },
      };
      const filter = {
        projectId: project.id,
        role: ProjectUserRole[ProjectUserRole.Moderator],
      };
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/projectUsers${queryString}`);
      if (mounted.current) {
        const users = response.data.value as IProjectUser[];
        setModerator(users.length > 0 ? response.data.value[0] : null);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, {
        variant: 'error',
      });
    }
  }, [mounted, project, enqueueSnackbar]);

  useEffect(() => {
    getProjectCoordinator();
  }, [getProjectCoordinator]);

  useEffect(() => {
    getLearningCoordinator();
  }, [getLearningCoordinator]);

  useEffect(() => {
    getLearningSolutionsConsultant();
  }, [getLearningSolutionsConsultant]);

  useEffect(() => {
    getFacilitator();
  }, [getFacilitator]);

  useEffect(() => {
    getAssessor();
  }, [getAssessor]);

  useEffect(() => {
    getModerator();
  }, [getModerator]);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleMemberSelection = (member: IProjectUser | null, role: ProjectUserRole) => {
    setSelectedMember(member);
    setSelectedRole(role);
    setEditModalOpen(true);
  };

  const handleSave = (projectUser: IProjectUser | null) => {
    switch (selectedRole) {
      case ProjectUserRole.ProjectCoordinator:
        setProjectCoordinator(projectUser);
        break;
      case ProjectUserRole.LearningCoordinator:
        setLearningCoordinator(projectUser);
        break;
      case ProjectUserRole.LearningSolutionsConsultant:
        setLearningSolutionsConsultant(projectUser);
        break;
      case ProjectUserRole.Facilitator:
        setFacilitator(projectUser);
        break;
      case ProjectUserRole.Assessor:
        setAssessor(projectUser);
        break;
      case ProjectUserRole.Moderator:
        setModerator(projectUser);
        break;
      default:
        return;
    }
  };

  if (
    projectCoordinator === undefined ||
    learningCoordinator === undefined ||
    learningSolutionsConsultant === undefined ||
    facilitator === undefined ||
    assessor === undefined ||
    moderator === undefined
  ) {
    return <Skeleton variant='rect' height={500} className={classes.skeleton} />;
  }

  return (
    <Card>
      <CardHeader title='Project Team' />
      <Divider />
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={projectCoordinator?.user?.userName || ''}
                avatarFile={projectCoordinator?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {projectCoordinator?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Project Coordinator
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() =>
                    handleMemberSelection(projectCoordinator, ProjectUserRole.ProjectCoordinator)
                  }
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={learningCoordinator?.user?.userName || ''}
                avatarFile={learningCoordinator?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {learningCoordinator?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Learning Coordinator
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() =>
                    handleMemberSelection(learningCoordinator, ProjectUserRole.LearningCoordinator)
                  }
                  disabled={!projectCoordinator && !learningCoordinator}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={learningSolutionsConsultant?.user?.userName || ''}
                avatarFile={learningSolutionsConsultant?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {learningSolutionsConsultant?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Learning Solutions Consultant
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() =>
                    handleMemberSelection(
                      learningSolutionsConsultant,
                      ProjectUserRole.LearningSolutionsConsultant,
                    )
                  }
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={facilitator?.user?.userName || ''}
                avatarFile={facilitator?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {facilitator?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Facilitator
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() => handleMemberSelection(facilitator, ProjectUserRole.Facilitator)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={assessor?.user?.userName || ''}
                avatarFile={assessor?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {assessor?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Assessor
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() => handleMemberSelection(assessor, ProjectUserRole.Assessor)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <GenericAvatar
                name={moderator?.user?.userName || ''}
                avatarFile={moderator?.user?.avatarFileItem}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {moderator?.user?.fullName || '-'}
                </Typography>
              }
              secondary={
                <Typography color='textSecondary' variant='body2'>
                  Moderator
                </Typography>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title='Edit' placement='top'>
                <IconButton
                  onClick={() => handleMemberSelection(moderator, ProjectUserRole.Moderator)}
                >
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
      {isEditModalOpen && (
        <EditMember
          open={isEditModalOpen}
          onClose={handleEditModalClose}
          projectUser={selectedMember}
          project={project}
          role={selectedRole!}
          onSave={handleSave}
        />
      )}
    </Card>
  );
};

export default Team;
