import { IProject } from 'app/models/responses/IProject';
import { Progress } from 'pages/_dashboard/ProjectDetails/components';
import React from 'react';

interface IProps {
  project: IProject;
}

const ProjectProgress = ({ project }: IProps) => {
  return <Progress project={project} />;
};

export default ProjectProgress;
