import { Box, ListItem, ListItemText, Theme, Typography, makeStyles } from '@material-ui/core';

interface IStyleProps {
  disableGutters?: boolean;
  dense?: boolean;
  align?: 'horizontal' | 'vertical';
}

const useStyles = (props: IStyleProps) =>
  makeStyles((theme: Theme) => ({
    root: {
      paddingLeft: props.disableGutters ? 0 : 3,
      paddingRight: props.disableGutters ? 0 : 3,

      paddingTop: props.dense ? 1 : 1.5,
      paddingBottom: props.dense ? 1 : 1.5,
    },
    text: {
      display: 'flex',
      flexDirection: props.align === 'vertical' ? 'column' : 'row',
      marginTop: 0,
      marginBottom: 0,
    },
    textSecondary: {
      flex: 1,
      marginTop: props.align === 'vertical' ? 0.5 : 0,
    },
  }));

interface IProps {
  align?: 'horizontal' | 'vertical';
  disableGutters?: boolean;
  label: string;
  value: React.ReactNode;
  [x: string]: any;
  dense?: boolean;
  children?: React.ReactNode;
}

const PropertyListItem: React.FC<IProps> = (props: IProps) => {
  const { align = 'vertical', children, disableGutters, value, label, dense, ...rest } = props;

  const classes = useStyles({ disableGutters, dense, align })();

  return (
    <ListItem className={classes.root} {...rest}>
      <ListItemText
        disableTypography
        primary={
          <Typography
            style={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant='subtitle2'
          >
            {label}
          </Typography>
        }
        secondary={
          <Box className={classes.textSecondary}>
            {children || (
              <Typography color='textSecondary' variant='body2'>
                {value}
              </Typography>
            )}
          </Box>
        }
        className={classes.text}
      />
    </ListItem>
  );
};

export default PropertyListItem;
