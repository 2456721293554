export enum EntityContext {
  ApplicationUser,
  ApplicationRole,
  ApplicationRoleClaim,
  Client,
  Comment,
  Contact,
  DemographicRequirement,
  Employee,
  Facilitator,
  HostSite,
  HostingRequest,
  Learner,
  Learnership,
  LearnershipRevision,
  LearnershipRevisionSection,
  Mentor,
  Project,
  ProjectLearner,
  ProjectLearnerMilestone,
  ProjectTrainingSession,
  RecruitmentRequest,
  Seta,
  SkillsProgramme,
  SkillsProgrammeRevision,
  User,
  Venue,
  Verification,
}
