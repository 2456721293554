import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import buildQuery from 'odata-query';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';
import { AttendanceStatus } from 'app/enums/AttendanceStatus';
import { Scrollbar } from 'components';
import moment from 'moment';
import * as jsonpatch from 'fast-json-patch';
import { CourseSubmission } from 'app/enums/CourseSubmission';
import { CourseOutcome } from 'app/enums/CourseOutcome';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { CourseOutcomeExemption } from 'app/enums/CourseOutcomeExemption';
import { SelectOneAsync } from 'components/_dashboard';
import { IProjectLearnerMilestone } from 'app/models/responses/IProjectLearnerMilestone';
import deepCopy from 'utils/deepCopy';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import { EntityContext } from 'app/enums/EntityContext';
import _ from 'lodash';

interface IProps {
  learners: IProjectLearner[];
  section?: ILearnershipRevisionSection;
  onSave: (updatedLearners: IProjectLearner[]) => void;
}

const EditMilestone: React.FC<IProps> = (props) => {
  const { learners, section, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);
  const [formStates, setFormStates] = useState<IProjectLearner[]>([]);

  useEffect(() => {
    setFormStates([...learners]);
  }, [learners]);

  const handleClose = () => {
    setOpen(false);
    setFormStates([...learners]);
  };

  const handleSave = () => {
    try {
      let updatedLearners: IProjectLearner[] = [];
      formStates!.forEach(async (projectLearner) => {
        const oldProjectLearner = (deepCopy(learners) as IProjectLearner[]).find(
          (e) => e.id === projectLearner.id,
        )!;
        const expand = ['learner'];
        const queryString = buildQuery({ expand });
        const diff = jsonpatch.compare(oldProjectLearner, projectLearner);
        const response = await axios.patch<IProjectLearner>(
          `${rootConfig.odataRoute}/projectLearners(${projectLearner.id})${queryString}`,
          diff,
        );
        updatedLearners = [...updatedLearners].concat(response.data);
        onSave(updatedLearners.sort((a, b) => a.id! - b.id!));
      });

      enqueueSnackbar('Successfully updated result.', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating result. Please try again later.', {
        variant: 'error',
      });
    } finally {
      setOpen(false);
    }
  };

  const handleChange = (
    eventTargetName: string,
    projectLearnerMilestone: IProjectLearnerMilestone | null | undefined,
    value: any,
    projectLearner: IProjectLearner,
  ) => {
    if (projectLearnerMilestone) {
      const updatedMilestone: IProjectLearnerMilestone = {
        ...projectLearnerMilestone,
        [eventTargetName]: value,
      };
      const updatedMilestones = [...(projectLearner.projectLearnerMilestones || [])];
      updatedMilestones[
        updatedMilestones
          .map((e) => `${e.parentContext!}${e.parentContextId!}`)
          .indexOf(
            `${projectLearnerMilestone.parentContext!}${projectLearnerMilestone.parentContextId!}`,
          )
      ] = updatedMilestone;
      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerMilestones: updatedMilestones,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    } else {
      const updatedMilestone: IProjectLearnerMilestone = {
        [eventTargetName]: value,
        parentContext: EntityContext[
          EntityContext.LearnershipRevisionSection
        ] as keyof typeof EntityContext,
        parentContextId: section?.id,
      };
      const updatedMilestones = [...(projectLearner.projectLearnerMilestones || [])].concat(
        updatedMilestone,
      );

      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerMilestones: updatedMilestones,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    }

    // const targetLearners = [...providedLearners];
    // const targetLearner = { ...learner };

    // const learnerMilestone: IProjectLearnerMilestone = {
    //   ...(section
    //     ? learner.projectLearnerMilestones!.find(
    //         (e) =>
    //           e.parentContext === EntityContext[EntityContext.LearnershipRevisionSection] &&
    //           e.parentContextId === section.id,
    //       )!
    //     : learner.projectLearnerMilestones!.length > 0
    //     ? learner.projectLearnerMilestones![0]
    //     : undefined),
    // };

    // if (learnerMilestone) {
    //   targetLearner.projectLearnerMilestones![
    //     targetLearner.projectLearnerMilestones!.indexOf(learnerMilestone)
    //   ] = {
    //     ...projectLearnerMilestone,
    //     [eventTargetName]: value,
    //   };
    // }

    // targetLearners[targetLearners.indexOf(learner)] = targetLearner;
  };

  // if (!formMilestones) return null;

  return (
    <>
      <Tooltip title='Manage submission and assessment results.'>
        <IconButton onClick={() => setOpen(true)} color='secondary'>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth='lg' fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{section ? section.name : 'Project'} Outcome</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems='center'>
            {formStates.map((projectLearner) => {
              const learnerMilestone = section
                ? projectLearner.projectLearnerMilestones?.find(
                    (learnerMilestone) =>
                      learnerMilestone.parentContext ===
                        EntityContext[EntityContext.LearnershipRevisionSection] &&
                      learnerMilestone.parentContextId === section.id,
                  )
                : projectLearner.projectLearnerMilestones &&
                  projectLearner.projectLearnerMilestones.length >= 1
                ? projectLearner.projectLearnerMilestones[0]
                : null;
              return (
                <Fragment key={projectLearner.id}>
                  <Grid item xs={1}>
                    <Typography variant='body2'>{projectLearner?.learner?.fullName}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant='body2'>{projectLearner?.learner?.idNumber}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <SelectOneAsync
                      name='outcomeExemptionId'
                      entityId={learnerMilestone?.outcomeExemptionId || null}
                      entityType='projectLearnerMilestoneExemptions'
                      labelFields={['name', 'code']}
                      filterFields={['name', 'code']}
                      onChange={(event: any, value: any) =>
                        handleChange(
                          'outcomeExemptionId',
                          learnerMilestone,
                          value?.id,
                          projectLearner,
                        )
                      }
                      label='Exemption'
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>POE Submission</InputLabel>
                      <Select
                        label='POE Submission'
                        name='submission'
                        variant='outlined'
                        disabled={!!learnerMilestone?.outcomeExemptionId}
                        value={learnerMilestone?.submission}
                        onChange={(event: React.ChangeEvent<any>) =>
                          handleChange(
                            event.target.name,
                            learnerMilestone,
                            event.target.value,
                            projectLearner,
                          )
                        }
                      >
                        {odataOptionsArrayFromEnum(CourseSubmission, false).map((submission) => (
                          <MenuItem key={submission.value} value={submission.value}>
                            {submission.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>1st Attempt</InputLabel>
                      <Select
                        label='1st Attempt'
                        name='firstAttemptOutcome'
                        variant='outlined'
                        disabled={!!learnerMilestone?.outcomeExemptionId}
                        value={learnerMilestone?.firstAttemptOutcome}
                        onChange={(event: React.ChangeEvent<any>) =>
                          handleChange(
                            event.target.name,
                            learnerMilestone,
                            event.target.value,
                            projectLearner,
                          )
                        }
                      >
                        {odataOptionsArrayFromEnum(CourseOutcome, false).map((outcome) => (
                          <MenuItem key={outcome.value} value={outcome.value}>
                            {outcome.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>2nd Attempt</InputLabel>
                      <Select
                        label='2nd Attempt'
                        name='secondAttemptOutcome'
                        variant='outlined'
                        disabled={!!learnerMilestone?.outcomeExemptionId}
                        value={learnerMilestone?.secondAttemptOutcome}
                        onChange={(event: React.ChangeEvent<any>) =>
                          handleChange(
                            event.target.name,
                            learnerMilestone,
                            event.target.value,
                            projectLearner,
                          )
                        }
                      >
                        {odataOptionsArrayFromEnum(CourseOutcome, false).map((outcome) => (
                          <MenuItem key={outcome.value} value={outcome.value}>
                            {outcome.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>3rd Attempt</InputLabel>
                      <Select
                        label='3rd Attempt'
                        name='thirdAttemptOutcome'
                        variant='outlined'
                        disabled={!!learnerMilestone?.outcomeExemptionId}
                        value={learnerMilestone?.thirdAttemptOutcome}
                        onChange={(event: React.ChangeEvent<any>) =>
                          handleChange(
                            event.target.name,
                            learnerMilestone,
                            event.target.value,
                            projectLearner,
                          )
                        }
                      >
                        {odataOptionsArrayFromEnum(CourseOutcome, false).map((outcome) => (
                          <MenuItem key={outcome.value} value={outcome.value}>
                            {outcome.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSave} color='primary'>
            Save
          </Button>
          <Button variant='outlined' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditMilestone;
