import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import PropertyList from 'components/_dashboard/PropertyList';
import PropertyListItem from 'components/_dashboard/PropertyListItem';
import moment from 'moment';

interface IProps {
  projectEvent: IProjectEvent;
  onClose: () => void;
}

const EventDetails: React.FC<IProps> = (props) => {
  const { projectEvent, onClose } = props;
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open fullScreen={mobileDevice}>
      <DialogTitle>Event Details</DialogTitle>
      <DialogContent>
        <PropertyList>
          <PropertyListItem
            label='Course'
            value={projectEvent.project?.courseRevision?.course?.name}
            align='horizontal'
          />
          <PropertyListItem
            label='Type'
            value={projectEvent.projectEventType?.title}
            align='horizontal'
          />
          <PropertyListItem
            label='Notes'
            value={projectEvent.description || '-'}
            align='horizontal'
          />
          <PropertyListItem
            label={projectEvent.allDay ? 'Date' : 'Start'}
            value={moment(projectEvent.start).format(
              `DD MMMM YYYY ${projectEvent.allDay ? '' : ' hh:mmA'}`,
            )}
            align='horizontal'
          />
          {projectEvent.end && !projectEvent.allDay && (
            <PropertyListItem
              label='End'
              value={moment(projectEvent.end).format('DD MMMM YYYY hh:mmA')}
              align='horizontal'
            />
          )}

          <PropertyListItem
            label='All Day?'
            value={projectEvent.allDay ? <CheckIcon /> : <ClearIcon />}
            align='horizontal'
          />
        </PropertyList>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDetails;
