import { List } from '@material-ui/core';

interface IProps {
  children: React.ReactNode;
}

const PropertyList: React.FC<IProps> = (props: IProps) => {
  const { children } = props;

  return <List disablePadding>{children}</List>;
};

export default PropertyList;
