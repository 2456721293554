import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import React, { useEffect, useMemo, useState } from 'react';
import buildQuery, { Expand, Select } from 'odata-query';
import axios from 'utils/axios';
import { IFileItem } from 'app/models/responses/IFileItem';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { id } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { Box, Button, TableCell, TableRow, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import getFileUrl from 'utils/getFileUrl';

interface IProps {
  course: IProjectLearner;
}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const RowItem = ({ course }: IProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [resultsFile, setResultsFile] = useState<IFileItem | null>(null);
  const [certificateFile, setCertificateFile] = useState<IFileItem | null>(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const query = buildQuery({});
        const response = await axios.get<IFileItem>(
          `${rootConfig.odataRoute}/projectLearners(${course.id})/getStatementOfResults()${query}`,
        );
        if (mounted) {
          setResultsFile(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const query = buildQuery({});
        const response = await axios.get<IFileItem>(
          `${rootConfig.odataRoute}/projectLearners(${course.id})/getCertificate()${query}`,
        );
        if (mounted) {
          setCertificateFile(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, course]);

  const handleFileDownload = async (file: IFileItem | null) => {
    if (!file) return;

    try {
      saveAs(getFileUrl(file), file.displayName! + file.extension!);
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  return (
    <TableRow>
      <TableCell>{course.project?.courseRevision?.course?.name}</TableCell>
      <TableCell>
        {certificateFile ? (
          <Button
            size='small'
            startIcon={<GetAppIcon />}
            color='secondary'
            variant='contained'
            onClick={() => handleFileDownload(certificateFile)}
          >
            Download
          </Button>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell>
        {resultsFile ? (
          <Button
            size='small'
            startIcon={<GetAppIcon />}
            color='secondary'
            variant='contained'
            onClick={() => handleFileDownload(resultsFile)}
          >
            Download
          </Button>
        ) : (
          '-'
        )}
      </TableCell>
    </TableRow>
  );
};

export default RowItem;
