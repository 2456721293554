import variableCaseToSentence from './variableCaseToSentence';

interface IOption {
  value: string;
  display: string;
}

const odataOptionsArrayFromEnum = (enumeration: any, showUpperCase: boolean = false) => {
  let arr: IOption[] = [];

  for (let val in Object.keys(enumeration).filter((key) => !isNaN(Number(key)))) {
    const text = enumeration[val];
    const result = showUpperCase ? text : variableCaseToSentence(text);
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    arr.push({
      value: enumeration[val],
      display: showUpperCase ? finalResult.toUpperCase() : finalResult,
    });
  }

  return arr;
};

export default odataOptionsArrayFromEnum;
