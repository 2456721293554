import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
  },
}));

const LoadingScreen: React.FC = () => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <Box className={classes.root} />;
};

export default LoadingScreen;
