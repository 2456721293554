import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Page } from 'components/_dashboard';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from 'utils/axios';
import buildQuery, { Expand, Select } from 'odata-query';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { id } from 'date-fns/locale';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import RowItem from './RowItem';

interface IProps {}

const Certificates = (props: IProps) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<IProjectLearner[]>([]);

  useEffect(() => {
    let mounted = true;

    if (!user) return undefined;

    (async () => {
      try {
        const select: Select<IProjectLearner> = ['id', 'status'];
        const expand: Expand<IProjectLearner> = {
          project: {
            select: ['id'] as any,
            expand: {
              courseRevision: {
                select: ['id', 'durationInDays'] as any,
                expand: {
                  avatarFileItem: {},
                  course: {
                    select: ['id', 'name', 'type'] as any,
                  },
                },
              },
            },
          },
        };
        const filter = {
          learnerId: user.id!,
        };
        const query = buildQuery({ expand, select, filter });
        const response = await axios.get<IODataResponse<IProjectLearner[]>>(
          `${rootConfig.odataRoute}/projectLearners${query}`,
        );
        if (mounted) {
          setCourses(response.data.value ?? []);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, user]);

  return (
    <Page title='Certificates'>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={`/`} color='textPrimary'>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>Home</Typography>
          </Box>
        </Link>
      </Box>
      <Typography variant='h4'>Certificates</Typography>
      <Typography variant='subtitle2' color='textSecondary'>
        Download your results
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Card>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Course</TableCell>
                  <TableCell>Certificate</TableCell>
                  <TableCell>Statement of Results</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.map((course) => (
                  <RowItem key={course.id} course={course} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Page>
  );
};

export default Certificates;
