import { matchPath } from 'react-router-dom';
import { List, ListSubheader, makeStyles } from '@material-ui/core';
import { NavItem } from 'components/_dashboard';
import { rootConfig } from 'config';

const useStyles = makeStyles((theme) => ({
  navSectionListSubHeader: {
    color: theme.palette.text.primary,
    fontSize: '0.70rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));

const renderNavItems = ({ depth = 0, items, pathname }: any) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc: any, item: any) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      [],
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }: any) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: rootConfig.basename + item.path,
          end: true,
        },
        pathname,
      )
    : false;

  if (item.children) {
    const partialMatch = item.path
      ? !!matchPath(
          {
            path: rootConfig.basename + item.path,
            end: false,
          },
          pathname,
        )
      : false;

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
      />,
    );
  }

  return acc;
};

interface IProps {
  items: [] | any;
  pathname: string;
  title: string;
  className?: string;
}

const NavSection: React.FC<IProps> = (props) => {
  const { items, pathname, title, className, ...other } = props;

  const classes = useStyles();

  return (
    <List
      subheader={
        <ListSubheader disableGutters disableSticky className={classes.navSectionListSubHeader}>
          {title}
        </ListSubheader>
      }
      className={className}
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  );
};

export default NavSection;
