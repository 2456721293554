import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { EntityContext } from 'app/enums/EntityContext';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { IProject } from 'app/models/responses/IProject';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { v4 as uuid } from 'uuid';
import {
  COURSEOUTCOMELEGEND,
  COURSESUBMISSIONLEGEND,
  ATTENDANCESTATUSLEGEND,
} from '../../../../../../../constants';
import useMounted from 'hooks/useMounted';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Fragment } from 'react';

import EditAttendance from '../EditAttendance';
import EditLearnerFeedback from '../EditLearnerFeedback';
import EditMilestone from '../EditMilestone';
import clsx from 'clsx';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import EditProjectFeedback from '../EditProjectFeedback';
import { IProjectLearnerMilestoneExemption } from 'app/models/responses/IProjectLearnerMilestoneExemption';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  borderlessCell: {
    borderBottom: 'none',
  },
  dividerCell: {
    borderBottom: 'none',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  mainHeader: {
    // backgroundColor: theme.palette.primary.main,
    // '& *': {
    //   color: '#fff'
    // }
  },
  sectionHeader: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface IProps {
  project: IProject;
  trainingSessions: IProjectEvent[];
  learners: IProjectLearner[];
  onAttendanceSave: (updatedLearners: IProjectLearner[]) => void;
  onMilestoneSave: (updatedLearners: IProjectLearner[]) => void;
  onFeedbackSave: (updatedLearners: IProjectLearner[]) => void;
  milestoneExemptionLookups: IProjectLearnerMilestoneExemption[];
  editable?: boolean;
}

const DefaultProgress: React.FC<IProps> = (props) => {
  const {
    project,
    trainingSessions,
    learners,
    onAttendanceSave,
    onMilestoneSave,
    onFeedbackSave,
    milestoneExemptionLookups,
    editable,
  } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.mainHeader}>
          <TableCell className={classes.borderlessCell} align='center' colSpan={3}>
            Learner Information
          </TableCell>
          <TableCell
            className={classes.dividerCell}
            align='center'
            colSpan={trainingSessions.length + 4}
          >
            <Grid container spacing={1} alignItems='center' justifyContent='center'>
              <Grid item>{project.courseRevision?.course?.name}</Grid>
              {editable && (
                <>
                  <Grid item>
                    <EditMilestone onSave={onMilestoneSave} learners={learners} />
                  </Grid>
                  <Grid item>
                    <EditLearnerFeedback onSave={onFeedbackSave} learners={learners} />
                  </Grid>
                  <Grid item>
                    <EditProjectFeedback project={project} />
                  </Grid>
                </>
              )}
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow className={classes.sectionHeader}>
          <TableCell className={classes.borderlessCell}>Name</TableCell>
          <TableCell className={classes.borderlessCell}>Surname</TableCell>
          <TableCell className={clsx(classes.borderlessCell, classes.borderRight)}>
            ID Number
          </TableCell>
          {trainingSessions.map((trainingSession) => (
            <TableCell className={classes.borderlessCell} key={trainingSession.id}>
              <Grid container spacing={1} alignItems='center' justifyContent='center'>
                <Grid item>{moment(trainingSession.start).format('DD/MM/YYYY')}</Grid>
                {editable && (
                  <Grid item>
                    <EditAttendance
                      onSave={onAttendanceSave}
                      learners={learners}
                      trainingSession={trainingSession}
                    />
                  </Grid>
                )}
              </Grid>
            </TableCell>
          ))}
          <TableCell className={classes.borderlessCell}>POE Submission</TableCell>
          <TableCell className={classes.borderlessCell}>1st Attempt</TableCell>
          <TableCell className={classes.borderlessCell}>2nd Attempt</TableCell>
          <TableCell className={clsx(classes.borderlessCell, classes.borderRight)}>
            3rd Attempt
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {learners.map((projectLearner) => {
          const learnerMilestone = projectLearner.projectLearnerMilestones!.find(
            (learnerMilestone) =>
              learnerMilestone.parentContext ===
              EntityContext[EntityContext.LearnershipRevisionSection],
          );

          return (
            <TableRow key={projectLearner.id}>
              <TableCell className={classes.borderlessCell}>
                {projectLearner.learner!.firstName}
              </TableCell>
              <TableCell className={classes.borderlessCell}>
                {projectLearner.learner!.lastName}
              </TableCell>
              <TableCell className={clsx(classes.borderlessCell, classes.borderRight)}>
                {projectLearner.learner!.idNumber}
              </TableCell>
              {trainingSessions.map((trainingSession) => {
                const learnerTrainingSession = projectLearner.projectLearnerEventAttendances?.find(
                  (learnerTrainingSession) =>
                    trainingSession.id === learnerTrainingSession.projectEventId,
                );
                return (
                  <TableCell
                    className={classes.borderlessCell}
                    key={learnerTrainingSession?.projectEventId || uuid()}
                  >
                    {milestoneExemptionLookups.find(
                      (e) => e.id === learnerMilestone?.outcomeExemptionId,
                    )?.code ||
                      (learnerTrainingSession?.attendanceStatus
                        ? ATTENDANCESTATUSLEGEND[
                            learnerTrainingSession.attendanceStatus
                              .toString()
                              .toUpperCase() as keyof typeof ATTENDANCESTATUSLEGEND
                          ]
                        : '')}
                  </TableCell>
                );
              })}
              <TableCell className={classes.borderlessCell}>
                {milestoneExemptionLookups.find(
                  (e) => e.id === learnerMilestone?.outcomeExemptionId,
                )?.code ||
                  (learnerMilestone?.submission
                    ? COURSESUBMISSIONLEGEND[
                        learnerMilestone.submission
                          .toString()
                          .toUpperCase() as keyof typeof COURSESUBMISSIONLEGEND
                      ]
                    : '')}
              </TableCell>
              <TableCell className={classes.borderlessCell}>
                {milestoneExemptionLookups.find(
                  (e) => e.id === learnerMilestone?.outcomeExemptionId,
                )?.code ||
                  (learnerMilestone?.firstAttemptOutcome
                    ? COURSEOUTCOMELEGEND[
                        learnerMilestone?.firstAttemptOutcome
                          .toString()
                          .toUpperCase() as keyof typeof COURSEOUTCOMELEGEND
                      ]
                    : '')}
              </TableCell>
              <TableCell className={classes.borderlessCell}>
                {milestoneExemptionLookups.find(
                  (e) => e.id === learnerMilestone?.outcomeExemptionId,
                )?.code ||
                  (learnerMilestone?.secondAttemptOutcome
                    ? COURSEOUTCOMELEGEND[
                        learnerMilestone?.secondAttemptOutcome
                          .toString()
                          .toUpperCase() as keyof typeof COURSEOUTCOMELEGEND
                      ]
                    : '')}
              </TableCell>
              <TableCell className={clsx(classes.borderlessCell, classes.borderRight)}>
                {milestoneExemptionLookups.find(
                  (e) => e.id === learnerMilestone?.outcomeExemptionId,
                )?.code ||
                  (learnerMilestone?.thirdAttemptOutcome
                    ? COURSEOUTCOMELEGEND[
                        learnerMilestone?.thirdAttemptOutcome
                          .toString()
                          .toUpperCase() as keyof typeof COURSEOUTCOMELEGEND
                      ]
                    : '')}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default DefaultProgress;
