import { Link as RouterLink } from 'react-router-dom';
import { Box, AppBar, Toolbar, makeStyles, Theme } from '@material-ui/core';
import { LanguagePopover, SettingsPopover } from 'components';
import { rootConfig } from 'config';

const useStyles = makeStyles((theme: Theme | any) => ({
  root: {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      color: theme.palette.text.primary,
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
    }),
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const AuthBanner: React.FC = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to='/'>
          <img
            alt='iLearn'
            src={`${rootConfig.staticFileRoot}/static/logos/core.svg`}
            height='50'
            style={{ marginTop: 5, padding: 6 }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <LanguagePopover />
        </Box> */}
        <Box sx={{ ml: 1 }}>
          <SettingsPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AuthBanner;
