import React, { Fragment, useRef, useState, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';

const useStyles = makeStyles(() => ({
  menu: {
    width: 200,
    maxWidth: '100%',
  },
}));

interface IProps {
  onCommentDelete: () => void;
  onCommentEdit: () => void;
  onCommentReply: () => void;
}

const OptionsButton: React.FC<IProps> = (props) => {
  const { onCommentDelete, onCommentEdit, onCommentReply } = props;
  const classes = useStyles();
  const theme = useTheme();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleCommentDelete = () => {
    setOpenMenu(false);
    onCommentDelete();
  };

  const handleCommentEdit = () => {
    setOpenMenu(false);
    onCommentEdit();
  };

  const handleCommentReply = () => {
    setOpenMenu(false);
    onCommentReply();
  };

  return (
    <Fragment>
      <Tooltip title='Options'>
        <IconButton
          {...props}
          onClick={() => setOpenMenu(true)}
          ref={moreRef}
          size='small'
          style={{ color: theme.palette.text.secondary }}
        >
          <MoreIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.menu }}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleCommentReply}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <ReplyIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Reply' />
        </MenuItem>
        <MenuItem onClick={handleCommentEdit}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={handleCommentDelete}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default memo(OptionsButton);
