import { Breadcrumbs, Link, makeStyles, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IBreadcrumb } from 'app/models/IBreadcrumb';

const useStyles = makeStyles(() => ({
  root: {},
  breadcrumbLink: {
    cursor: 'pointer',
  },
}));

interface IProps {
  breadcrumbs: IBreadcrumb[];
  onNavigate?: (breadcrumb: IBreadcrumb) => void;
}

const Navigation: React.FC<IProps> = (props) => {
  const { breadcrumbs, onNavigate } = props;

  const classes = useStyles();

  return (
    <Breadcrumbs aria-label='breadcrumb' separator={<ChevronRightIcon fontSize='small' />}>
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment>
          {index === breadcrumbs.length - 1 || !onNavigate ? (
            <Typography color='textSecondary' variant='subtitle2'>
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              color='textPrimary'
              variant='subtitle2'
              className={classes.breadcrumbLink}
              onClick={() => onNavigate(breadcrumb)}
            >
              {breadcrumb.name}
            </Link>
          )}
        </Fragment>
      ))}
    </Breadcrumbs>
  );
};

export default Navigation;
