import { Box } from '@material-ui/core';
import { IChangeEvent } from '@rjsf/core';
import { UiSchema, RJSFSchema } from '@rjsf/utils';
import { IProjectEventTypeTask } from 'app/models/entities/IProjectEventTypeTask';
import { RenderForm } from 'components/_dashboard';
import { useSnackbar } from 'notistack';
import { IProjectEventTypeTaskBasicForm } from 'pages/_dashboard/_setups/ProjectEventTypeDetails/components/Tasks/components/BasicFormEdit/BasicFormEdit';
import React, { useEffect, useState } from 'react';

export interface IProjectEventTypeTaskAttemptBasicForm {
  schema?: RJSFSchema;
  uiSchema?: UiSchema;
  formData?: Record<string, any>;
}

interface IProps {
  task: IProjectEventTypeTask;
}

const BasicFormTask = ({ task }: IProps) => {
  const [uiSchema, setUiSchema] = useState<UiSchema>({});
  const [schema, setSchema] = useState<RJSFSchema>({});
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [uiSchemaJson, setUiSchemaJson] = useState('');
  const [schemaJson, setSchemaJson] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (task.rawContent) {
      try {
        const parsed = JSON.parse(task.rawContent) as IProjectEventTypeTaskBasicForm;
        setUiSchema(parsed.uiSchema ?? {});
        setUiSchemaJson(JSON.stringify(parsed.uiSchema ?? {}));
        setSchemaJson(JSON.stringify(parsed.schema ?? {}));
        setSchema(parsed.schema ?? {});
      } catch {
        return;
      }
    }
  }, [task]);

  const handleChange = (data: Record<string, any>) => {
    setFormData(data);
    console.log(data);
  };

  const handleSave = (event: IChangeEvent<any, RJSFSchema, any>) => {
    console.log(event);
    enqueueSnackbar('Successfully saved form.', { variant: 'success' });
  };

  return (
    <Box sx={{ p: 1 }}>
      <RenderForm
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onChange={handleChange}
        onSubmit={handleSave}
      />
    </Box>
  );
};

export default BasicFormTask;
