import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ProjectNotification } from 'app/enums/ProjectNotification';
import { CourseOutcome } from 'app/enums/CourseOutcome';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';
import EmailIcon from '@material-ui/icons/Email';
import axios from 'utils/axios';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { IProject } from 'app/models/responses/IProject';
import { useSnackbar } from 'notistack';

interface IProps {
  onNext: () => void;
  onNotificationChange: (notification: keyof typeof ProjectNotification) => void;
  selectedNotification: keyof typeof ProjectNotification | null;
  project: IProject;
  onClose: () => void;
}

const StepOne: React.FC<IProps> = (props) => {
  const { onNext, onNotificationChange, selectedNotification, project, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [notification, setNotification] = useState<keyof typeof ProjectNotification | null>(
    selectedNotification,
  );

  const handleSubmit = async () => {
    try {
      await axios.post(`${rootConfig.odataRoute}/projects(${project.id})/sendLearnerWelcomeEmails`);
      enqueueSnackbar('Successfully sent notifications.', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    const val = event.target.value as keyof typeof ProjectNotification;
    setNotification(val);
    onNotificationChange(val);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth variant='outlined'>
          <InputLabel>Notification</InputLabel>
          <Select
            label='Notification'
            name='notification'
            variant='outlined'
            value={notification}
            onChange={handleChange}
          >
            {odataOptionsArrayFromEnum(ProjectNotification, false).map((outcome) => (
              <MenuItem key={outcome.value} value={outcome.value}>
                {outcome.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Grid item>
          <Button
            endIcon={<EmailIcon />}
            variant='contained'
            color='primary'
            disabled={!notification}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepOne;
