import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { INextOfKin } from 'app/models/entities/INextOfKin';

interface IProps {
  parentEntityType: 'learners';
  parentEntityId: number;
  nextOfKin: INextOfKin | null;
  onNextOfKinUpdate?: (updatedNextOfKin: INextOfKin) => void;
}

const GenericNextOfKinForm: React.FC<IProps> = (props) => {
  const { nextOfKin, parentEntityId, parentEntityType, onNextOfKinUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        emailAddress: nextOfKin?.emailAddress || '',
        firstName: nextOfKin?.firstName || '',
        lastName: nextOfKin?.lastName || '',
        cellNumber: nextOfKin?.cellNumber || '',
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        emailAddress: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
        cellNumber: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            nextOfKin,
          };
          const updatedEntity: any = {
            nextOfKin: {
              ...nextOfKin,
              ...values,
            },
          };
          delete updatedEntity.nextOfKin['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedNextOfKin: INextOfKin = response.data.nextOfKin;
          resetForm({
            values: {
              emailAddress: updatedNextOfKin?.emailAddress || '',
              firstName: updatedNextOfKin?.firstName || '',
              lastName: updatedNextOfKin?.lastName || '',
              cellNumber: updatedNextOfKin?.cellNumber || '',
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated next of kin.`, { variant: 'success' });
          setStatus({ success: true });
          onNextOfKinUpdate && onNextOfKinUpdate(updatedNextOfKin);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Next of Kin' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label='First Name'
                    name='firstName'
                    onChange={handleChange}
                    value={values.firstName}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label='Last Name'
                    name='lastName'
                    onChange={handleChange}
                    value={values.lastName}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    fullWidth
                    helperText={touched.emailAddress && errors.emailAddress}
                    label='Email'
                    name='emailAddress'
                    onChange={handleChange}
                    value={values.emailAddress}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.cellNumber && errors.cellNumber)}
                    fullWidth
                    helperText={touched.cellNumber && errors.cellNumber}
                    label='Cell Number'
                    name='cellNumber'
                    onChange={handleChange}
                    value={values.cellNumber}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericNextOfKinForm;
