import { Button, Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ProjectNotification } from 'app/enums/ProjectNotification';
import EmailIcon from '@material-ui/icons/Email';

interface IProps {
  onNext: () => void;
  onBack: () => void;
  notification: keyof typeof ProjectNotification | null;
}

const StepTwo: React.FC<IProps> = (props) => {
  const { onNext, onBack, notification } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Grid item>
        <Button onClick={onBack}>Back</Button>
      </Grid>
      <Grid item>
        <Button endIcon={<EmailIcon />} onClick={onNext} variant='contained' color='primary'>
          Send
        </Button>
      </Grid>
    </Grid>
  );
};

export default StepTwo;
