import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Tooltip,
  Typography,
  Link,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { IComment } from 'app/models/responses/IComment';
import { ConfirmationModal, DocViewer, GenericAvatar } from 'components/_dashboard';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import useAuth from 'hooks/useAuth';
import { CommentEdit, OptionsButton } from './components';
import buildQuery from 'odata-query';
import ReplyIcon from '@material-ui/icons/Reply';
import useMounted from 'hooks/useMounted';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import { ExceptionHandler } from 'components';
import { useSnackbar } from 'notistack';
import { EntityContext } from 'app/enums/EntityContext';
import { IFileItem } from 'app/models/responses/IFileItem';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import getFileUrl from 'utils/getFileUrl';
import CommentAttachment from './components/CommentAttachment';
import { HubConnection } from '@microsoft/signalr';

const useStyles = makeStyles((theme) => ({
  root: {},
  subheader: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
  },
  infoIcon: {
    color: 'inherit',
    fontSize: '14px',
    height: 14,
    width: 14,
    marginRight: 5,
  },
  content: {
    '& .mention': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: '2px 8px',
    },
    '& .ql-mention-denotation-char': {
      display: 'none',
    },
    paddingTop: 0,
  },
  myPost: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
  },
  actionReplyContent: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  embeddedComment: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  embeddedCommentAvatar: {
    marginRight: 4,
    width: '13px',
    height: '13px',
  },
  filePlaceholder: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1),
  },
  fileItem: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface IProps {
  comment: IComment;
  onDelete: (commentToDelete: IComment) => void;
  onReply: (parentComment: IComment) => void;
  onFocus: (targetComments: IComment[]) => void;
  className?: string;
  connection: HubConnection | null;
}

const Comment: React.FC<IProps> = (props) => {
  const { comment, onDelete, onReply, onFocus, className, connection } = props;

  const mounted = useMounted();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [parentComment, setParentComment] = useState<IComment | null>(null);
  const [files, setFiles] = useState<IFileItem[]>([]);

  const getParentComment = useCallback(async () => {
    if (!comment.parentCommentId) {
      return undefined;
    }

    try {
      const expand = ['user/avatarFileItem'];
      const queryString = buildQuery({ expand });
      const response = await axios.get<IComment>(
        `${rootConfig.odataRoute}/comments(${comment.parentCommentId})${queryString}`,
      );
      if (mounted.current) {
        setParentComment(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, comment.parentCommentId, enqueueSnackbar]);

  const getFiles = useCallback(async () => {
    try {
      const filter = [
        { parentContextId: comment.id },
        { parentContext: EntityContext[EntityContext.Comment] },
      ];
      const queryString = buildQuery({ filter });
      const response = await axios.get<IODataResponse<IFileItem[]>>(
        `${rootConfig.odataRoute}/fileItems${queryString}`,
      );
      if (mounted.current) {
        setFiles(response.data.value!);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, comment.id, enqueueSnackbar]);

  useEffect(() => {
    getParentComment();
  }, [getParentComment]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const handleParentFocus = (event: any) => {
    onFocus([parentComment!]);
  };

  const handleFileDownload = (file: IFileItem) => {
    try {
      window.open(getFileUrl(file), '_blank')!.focus();
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  return (
    <Card
      className={clsx(className, classes.root, {
        [classes.myPost]: user?.id === comment.user?.id,
      })}
      id={`comment-${comment.id}`}
    >
      <CardHeader
        action={
          <OptionsButton
            onCommentDelete={() => setOpenDelete(true)}
            onCommentEdit={() => setOpenEdit(true)}
            onCommentReply={() => onReply(comment)}
          />
        }
        avatar={
          <GenericAvatar name={comment.user?.userName!} avatarFile={comment.user?.avatarFileItem} />
        }
        title={
          <div>
            <Tooltip title={comment.user?.email!} placement='top' interactive>
              <Link color='textPrimary' component={RouterLink} to={`/`} variant='h6'>
                {comment.user?.userName!}
              </Link>
            </Tooltip>
            {comment.isEdited && (
              <Typography variant='body2' color='textSecondary'>
                (edited)
              </Typography>
            )}
          </div>
        }
        subheader={
          <div className={classes.subheader}>
            <AccessTimeIcon className={classes.infoIcon} />
            <Typography variant='body2' color='inherit'>
              {moment(comment.createDate).fromNow()}
            </Typography>
          </div>
        }
      />
      <CardContent className={classes.content}>
        {parentComment && (
          <div className={classes.embeddedComment}>
            <div className={classes.actionReplyContent} onClick={handleParentFocus}>
              <ReplyIcon className={classes.infoIcon} />
              <GenericAvatar
                avatarFile={parentComment?.user?.avatarFileItem}
                name={parentComment?.user?.userName!}
                className={classes.embeddedCommentAvatar}
              />
              <Typography variant='body2' color='inherit'>
                {parentComment?.user?.userName!}
              </Typography>
            </div>
          </div>
        )}
        <span dangerouslySetInnerHTML={{ __html: comment.body! }} />
      </CardContent>
      {files.length > 0 && (
        <Fragment>
          <Divider />
          <CardContent>
            <Typography variant='body1' color='textPrimary' gutterBottom>
              Attachments
            </Typography>
            {files.map((file) => (
              <CommentAttachment
                key={file.id}
                file={file}
                onDownload={() => handleFileDownload(file)}
              />
            ))}
          </CardContent>
        </Fragment>
      )}
      <ConfirmationModal
        open={openDelete}
        onCancel={() => setOpenDelete(false)}
        onConfirm={() => onDelete(comment)}
        title='Delete Comment'
        message='Are you sure you wish to permanently delete this comment?'
        error={true}
      />
      <CommentEdit
        comment={comment}
        onClose={() => setOpenEdit(false)}
        open={openEdit}
        connection={connection}
      />
    </Card>
  );
};

export default Comment;
