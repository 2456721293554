import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useCallback, useState, useEffect, ChangeEvent } from 'react';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import buildQuery, { Expand } from 'odata-query';
import { IProject } from 'app/models/responses/IProject';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import useMounted from 'hooks/useMounted';
import { rootConfig } from 'config';
import { ApprovalRequestStatus } from 'app/enums/ApprovalRequestStatus';
import { IProjectExtraTimeRequest } from 'app/models/responses/IProjectExtraTimeRequest';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Formik } from 'formik';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { SelectOneAsync } from 'components/_dashboard';
import { isTSConstructSignatureDeclaration } from '@babel/types';
import { CourseType } from 'app/enums/CourseType';
import { IClient } from 'app/models/responses/IClient';
import { ProjectLearnerType } from 'app/enums/ProjectLearnerType';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';

interface IProps {
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const Team: React.FC<IProps> = (props) => {
  const { project, onProjectUpdate } = props;
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setEditing] = useState(false);

  const [approvedExtraTimeTotal, setApprovedExtraTimeTotal] = useState<number>(0);

  const getExtraTimeRequests = useCallback(async () => {
    try {
      const select = ['durationInDays'];
      const filter = {
        projectId: project.id,
        status: ApprovalRequestStatus[ApprovalRequestStatus.Approved],
      };
      const queryString = buildQuery({ select, filter });
      const response = await axios.get(
        `${rootConfig.odataRoute}/projectExtraTimeRequests${queryString}`,
      );
      if (mounted.current) {
        setApprovedExtraTimeTotal(
          response.data.value.reduce(
            (total: number, currentValue: IProjectExtraTimeRequest) =>
              total + (currentValue?.durationInDays || 0),
            0,
          ),
        );
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, project.id, enqueueSnackbar]);

  useEffect(() => {
    getExtraTimeRequests();
  }, [getExtraTimeRequests]);

  return (
    <Formik
      initialValues={{
        venueId: project.venueId,
        projectDeliveryMethodId: project.projectDeliveryMethodId,
        isAccredited: project.isAccredited,
        isEpoe: project.isEpoe,
        notes: project.notes,
        learnerType: project.learnerType,
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const updatedEntity: any = {
            ...project,
            ...values,
          };
          delete updatedEntity['submit'];
          const diff = jsonpatch.compare(project, updatedEntity);
          const expand: Expand<IProject> = [
            'projectClients/client',
            'projectClients/status',
            'createdByUser',
            'courseRevision/course',
            'projectLearners/learner',
            'projectEvents',
            'projectDeliveryMethod',
            'venue',
            'projectPhase',
            'projectStatus',
          ];
          const queryString = buildQuery({ expand });
          const response = await axios.patch<IProject>(
            `${rootConfig.odataRoute}/projects(${project.id})${queryString}`,
            diff,
          );
          const updatedProject = response.data;
          resetForm({
            values: {
              venueId: updatedProject.venueId,
              isAccredited: updatedProject.isAccredited,
              projectDeliveryMethodId: updatedProject.projectDeliveryMethodId,
              isEpoe: updatedProject.isEpoe,
              notes: updatedProject.notes,
              learnerType: updatedProject.learnerType,
              submit: null,
            },
          });
          onProjectUpdate(updatedProject);
          enqueueSnackbar(`Successfully updated project.`, { variant: 'success' });
          setStatus({ success: true });
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
          setEditing(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        resetForm,
        dirty,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='General' />
            <Divider />

            {isEditing ? (
              <>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <SelectOneAsync
                        name='venueId'
                        entityId={values.venueId || null}
                        entityType='venues'
                        labelFields={['name']}
                        filterFields={['name']}
                        onChange={(event: any, value: any) =>
                          setFieldValue('venueId', value?.id || null)
                        }
                        label='Venue'
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectOneAsync
                        name='projectDeliveryMethodId'
                        entityId={values.projectDeliveryMethodId || null}
                        entityType='projectDeliveryMethods'
                        labelFields={['name']}
                        filterFields={['name']}
                        onChange={(event: any, value: any) =>
                          setFieldValue('projectDeliveryMethodId', value?.id || null)
                        }
                        label='Delivery Method'
                      />
                    </Grid>
                    {project.courseRevision!.course!.type ===
                      CourseType[CourseType.SkillsProgramme] && (
                      <Grid item xs={12}>
                        <Box sx={{ px: 2 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.isAccredited}
                                color='secondary'
                                edge='start'
                                name='isAccredited'
                                onChange={(event) =>
                                  setFieldValue('isAccredited', event.target.checked)
                                }
                              />
                            }
                            label={
                              <div>
                                <Typography variant='subtitle1'>Is Accredited?</Typography>
                                <Typography color='textSecondary' variant='caption' component='p'>
                                  Indicate whether or not this project is accredited.
                                </Typography>
                              </div>
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box sx={{ px: 2 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.isEpoe}
                              color='secondary'
                              edge='start'
                              name='isEpoe'
                              onChange={(event) => setFieldValue('isEpoe', event.target.checked)}
                            />
                          }
                          label={
                            <div>
                              <Typography variant='subtitle1'>EPOE</Typography>
                              <Typography color='textSecondary' variant='caption' component='p'>
                                Indicate whether or not this project is on EPOE.
                                {/**confirm wording */}
                              </Typography>
                            </div>
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.notes && errors.notes)}
                        fullWidth
                        helperText={touched.notes && errors.notes}
                        label='Overview'
                        name='notes'
                        onChange={handleChange}
                        value={values.notes}
                        variant='outlined'
                        multiline
                        maxRows={6}
                        minRows={3}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
              </>
            ) : (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Course
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                          <Typography color='textSecondary' variant='body2'>
                            {project.courseRevision!.course!.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={project.courseRevision!.course!.type}
                            variant='outlined'
                            color='secondary'
                            size='small'
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Course Revision
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={1} alignItems='center'>
                        <Grid item>
                          <Typography color='textSecondary' variant='body2'>
                            {project.courseRevision!.revision}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Duration
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.courseRevision!.durationInDays}
                        {approvedExtraTimeTotal ? ` + ${approvedExtraTimeTotal} ` : ''}
                        {' days'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Delivery Method
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.projectDeliveryMethod?.name!}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {variableCaseToSentence(project.type!)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Learner Type
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {variableCaseToSentence(project.learnerType!)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {project.projectClients && (
                    <TableRow>
                      <TableCell>
                        <Typography color='textPrimary' variant='subtitle2'>
                          Client(s)
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {project.projectClients.map((projectClient, index) => (
                          <Typography color='textSecondary' variant='body2'>
                            {projectClient.client!.name}
                            {index < project.projectClients!.length - 1 && ', '}
                          </Typography>
                        ))}
                      </TableCell>
                    </TableRow>
                  )}

                  {project.group && (
                    <TableRow>
                      <TableCell>
                        <Typography color='textPrimary' variant='subtitle2'>
                          Group
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color='textSecondary' variant='body2'>
                          {project.group}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Created By
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.createdByUser?.fullName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Venue
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.venue?.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {project.courseRevision!.course!.type ===
                    CourseType[CourseType.SkillsProgramme] && (
                    <TableRow>
                      <TableCell>
                        <Typography color='textPrimary' variant='subtitle2'>
                          Is Accredited?
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color='textSecondary' variant='body2'>
                          {project.isAccredited ? (
                            <CheckIcon fontSize='small' />
                          ) : (
                            <ClearIcon fontSize='small' />
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        EPOE
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.isEpoe ? (
                          <CheckIcon fontSize='small' />
                        ) : (
                          <ClearIcon fontSize='small' />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography color='textPrimary' variant='subtitle2'>
                        Overview
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color='textSecondary' variant='body2'>
                        {project.notes || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

            <CardActions>
              {isEditing ? (
                <>
                  <Button
                    color='primary'
                    disabled={isSubmitting || !isValid || !dirty}
                    type='submit'
                    variant='contained'
                  >
                    Save Changes
                  </Button>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={() => {
                      setEditing(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  startIcon={<EditOutlinedIcon />}
                  color='primary'
                  variant='outlined'
                  onClick={() => setEditing(true)}
                >
                  Edit
                </Button>
              )}
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default Team;
