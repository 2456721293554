import variableCaseToSentence from './variableCaseToSentence';

const odataLookupObjFromEnum = (enumeration: any, showUpperCase: boolean = false) => {
  let obj: any = {};

  let test: any = Object.keys(enumeration).filter((key) => !isNaN(Number(key)));

  for (let val in test) {
    const text = enumeration[val];
    const result = showUpperCase ? text : variableCaseToSentence(text);
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

    obj[enumeration[val]] = showUpperCase ? finalResult.toUpperCase() : finalResult;
  }

  return obj;
};

export default odataLookupObjFromEnum;
