import { Box, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ExceptionHandler } from 'components';
import { Page } from 'components/_dashboard';
import { rootConfig } from 'config';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import buildQuery, { Expand, Select } from 'odata-query';
import Course from 'pages/_dashboard/Overview/components/LearnerDashboard/components/Courses/components/Course';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import axios from 'utils/axios';

interface IProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CourseHistory = (props: IProps) => {
  const { id } = useParams();
  const classes = useStyles();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [courses, setCourses] = useState<IProjectLearner[]>([]);

  useEffect(() => {
    let mounted = true;

    if (!user) return undefined;

    (async () => {
      try {
        const select: Select<IProjectLearner> = ['id', 'status'];
        const expand: Expand<IProjectLearner> = {
          project: {
            select: ['id'] as any,
            expand: {
              courseRevision: {
                select: ['id', 'durationInDays'] as any,
                expand: {
                  avatarFileItem: {},
                  course: {
                    select: ['id', 'name', 'type'] as any,
                  },
                },
              },
            },
          },
        };
        const filter = {
          learnerId: user.id!,
        };
        const query = buildQuery({ expand, select, filter });
        const response = await axios.get<IODataResponse<IProjectLearner[]>>(
          `${rootConfig.odataRoute}/projectLearners${query}`,
        );
        if (mounted) {
          setCourses(response.data.value ?? []);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, user]);

  return (
    <Page title='Courses'>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={`/`} color='textPrimary'>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>Home</Typography>
          </Box>
        </Link>
      </Box>
      <Typography variant='h4'>Courses</Typography>
      <Typography variant='subtitle2' color='textSecondary'>
        Browse your courses
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems='stretch'>
          {courses.map((enrolment) => (
            <Grid key={enrolment.id} item xs={12} md={4} lg={3}>
              <Course enrolment={enrolment} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Page>
  );
};

export default CourseHistory;
