import {
  Stepper,
  Step,
  StepLabel,
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  makeStyles,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  colors,
  Typography,
} from '@material-ui/core';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import React from 'react';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AdjustIcon from '@material-ui/icons/Adjust';
import Color from 'color';

const useStyles = makeStyles((theme) => ({
  taskMenuItem: {
    borderRadius: theme.shape.borderRadius,
  },
  activeTaskMenuItem: {
    backgroundColor: Color(theme.palette.secondary.main).alpha(0.1).toString(),
    '&:hover': {
      backgroundColor: Color(theme.palette.secondary.main).alpha(0.1).toString(),
    },
  },
}));

interface IProps {
  activeStep: number;
  tasks: IProjectEvent[];
  onSelectStep: (step: number) => void;
}

const Tasks = (props: IProps) => {
  const { activeStep, tasks, onSelectStep } = props;
  const classes = useStyles();

  return (
    <List dense disablePadding>
      {tasks.map((projectEvent) => (
        <MenuItem
          key={projectEvent.id}
          onClick={() => onSelectStep(projectEvent.id!)}
          className={clsx(
            classes.taskMenuItem,
            activeStep === projectEvent.id ? classes.activeTaskMenuItem : undefined,
          )}
          dense
        >
          <ListItemIcon>
            {activeStep === projectEvent.id ? (
              <AdjustIcon color='secondary' />
            ) : (
              <RadioButtonUncheckedIcon color='secondary' />
            )}
          </ListItemIcon>
          <ListItemText style={{ flexWrap: 'wrap', whiteSpace: 'break-spaces' }}>
            <Typography variant='body2'>
              {projectEvent.title || projectEvent.projectEventType?.title}
            </Typography>
          </ListItemText>
          {/* <ListItemSecondaryAction>
            <Chip label='10pts' color='secondary' size='small' />
          </ListItemSecondaryAction> */}
        </MenuItem>
      ))}
    </List>
  );
};

export default Tasks;
