import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import useAuth from 'hooks/useAuth';
import GenericAvatar from '../GenericAvatar';
import { UserType } from 'app/enums/UserType';
import variableCaseToSentence from 'utils/variableCaseToSentence';

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  avatar: {
    height: 32,
    width: 32,
  },
  popover: {
    width: 240,
  },
}));

const AccountPopover: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <ButtonBase onClick={handleOpen} ref={anchorRef} className={classes.avatarContainer}>
        <GenericAvatar avatarFile={user?.avatarFileItem} name={user?.userName!} />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='subtitle2'>
            {user?.userName}
          </Typography>
          <Typography color='textSecondary' variant='subtitle2'>
            {variableCaseToSentence(user?.type!)}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to='/profile'>
            <ListItemIcon style={{ color: theme.palette.text.secondary }}>
              <PersonIcon fontSize='small' color='inherit' />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Profile
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color='primary' fullWidth onClick={handleLogout} variant='outlined'>
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
