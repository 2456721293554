import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
  Typography,
  Chip,
  Box,
} from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler, Scrollbar } from 'components';
import { SelectOneAsync } from 'components/_dashboard';
import { IMentor } from 'app/models/responses/IMentor';
import useMounted from 'hooks/useMounted';
import AddIcon from '@material-ui/icons/Add';
import { IClient } from 'app/models/responses/IClient';
import { IHostSite } from 'app/models/responses/IHostSite';
import { Mentor, MentorAdd } from './components';

interface IProps {
  parentEntityType: 'clients' | 'hostSites';
  parentEntity: IClient | IHostSite;
  onMentorsUpdate: (mentorIds: number[]) => void;
}

const GenericAvailableMentorsForm: React.FC<IProps> = (props) => {
  const { parentEntityType, parentEntity, onMentorsUpdate } = props;
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [mentorAddOpen, setMentorAddOpen] = useState(false);
  const [availableMentors, setMentors] = useState<IMentor[] | null>(null);
  const [mentorIds, setMentorIds] = useState(
    parentEntity.availableMentors?.map((e) => e.mentorId) || [],
  );

  useEffect(() => {
    setMentorIds(parentEntity.availableMentors?.map((e) => e.mentorId) || []);
  }, [parentEntity]);

  const getMentors = useCallback(async () => {
    try {
      const filter = {
        id: {
          in: mentorIds,
        },
      };
      const expand = ['avatarFileItem'];
      const queryString = buildQuery({ filter, expand });
      const response = await axios.get(`${rootConfig.odataRoute}/mentors${queryString}`);
      if (mounted.current) {
        setMentors(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, mentorIds]);

  useEffect(() => {
    getMentors();
  }, [getMentors]);

  const handleAdd = (mentorId: number) => {
    const updatedIds = [...(mentorIds as number[])].concat(mentorId);
    onMentorsUpdate(updatedIds);
  };

  const handleDelete = async (mentor: IMentor) => {
    try {
      const updatedMentors = parentEntity.availableMentors
        ? [...parentEntity.availableMentors]
        : [];
      const updatedEntity: IHostSite | IClient = {
        ...parentEntity,
        availableMentors: updatedMentors.filter((e) => e.mentorId !== mentor.id),
      };

      const diff = jsonpatch.compare(parentEntity, updatedEntity);
      await axios.patch(`${rootConfig.odataRoute}/${parentEntityType}(${parentEntity.id})`, diff);
      const updatedIds = [...(mentorIds as number[])].filter((e) => e !== mentor.id);
      onMentorsUpdate(updatedIds);
      enqueueSnackbar(`Successfully removed mentor.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  if (!availableMentors) return null;

  return (
    <Card>
      <CardHeader title='Mentors' />
      <Divider />

      {availableMentors.length > 0 && (
        <>
          <Scrollbar options={{ suppressScrollX: true }}>
            <CardContent style={{ maxHeight: 420 }}>
              <Grid container spacing={2}>
                {availableMentors.map((mentor) => (
                  <Mentor key={mentor.id} mentor={mentor} onDelete={() => handleDelete(mentor)} />
                ))}
              </Grid>
            </CardContent>
          </Scrollbar>

          <Divider />
        </>
      )}

      <CardActions>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          color='secondary'
          onClick={() => setMentorAddOpen(true)}
        >
          Add
        </Button>
      </CardActions>
      <MentorAdd
        open={mentorAddOpen}
        onClose={() => setMentorAddOpen(false)}
        onAdd={handleAdd}
        parentEntityType={parentEntityType}
        parentEntity={parentEntity}
      />
    </Card>
  );
};

export default GenericAvailableMentorsForm;
