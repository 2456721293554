import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { EntityContext } from 'app/enums/EntityContext';
import { ProjectUserRole } from 'app/enums/ProjectUserRole';
import { IFacilitatorCourseRate } from 'app/models/responses/IFacilitatorCourseRate';
import { IProject } from 'app/models/responses/IProject';
import { IProjectUser } from 'app/models/responses/IProjectUser';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import { ExceptionHandler } from 'components';
import { SelectOneAsync } from 'components/_dashboard';
import { rootConfig } from 'config';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'utils/axios';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import { CURRENCYSYMBOL } from '../../../../../../../../../constants';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSave: (projectUser: IProjectUser | null) => void;
  projectUser: IProjectUser | null;
  role: ProjectUserRole;
  project: IProject;
}

const EditMember: React.FC<IProps> = (props) => {
  const { open, onClose, projectUser, project, role, onSave } = props;
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const [selectedUser, setSelectedUser] = useState<IApplicationUser | null>(
    projectUser?.user || null,
  );
  const [facilitatorRate, setFacilitatorRate] = useState<IFacilitatorCourseRate>({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (role !== ProjectUserRole.Facilitator || !selectedUser?.id) {
      if (mounted) {
        setFacilitatorRate({});
        return undefined;
      }
    }

    (async () => {
      try {
        const filter = {
          facilitatorId: selectedUser!.id,
          courseId: project.courseRevision?.courseId,
        };
        const expand = ['fee/prices'];
        const queryString = buildQuery({ filter, expand });
        const response = await axios.get(
          `${rootConfig.odataRoute}/facilitatorCourseRates${queryString}`,
        );
        if (mounted) {
          setFacilitatorRate(response.data.value[0]);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [selectedUser?.id, project.courseRevision?.courseId]);

  const handleSubmit = async (e: ChangeEvent<any>) => {
    try {
      setIsSubmitting(true);
      if (projectUser) {
        const response = await axios.delete(
          `${rootConfig.odataRoute}/projectUsers(${projectUser.id})`,
        );
        onSave(null);
      }

      if (selectedUser?.id) {
        const newProjectUser: IProjectUser = {
          projectId: project.id,
          role: ProjectUserRole[role] as keyof typeof ProjectUserRole,
          userId: selectedUser!.id!,
        };
        const expand = {
          user: { select: ['fullName', 'userName', 'id'], expand: { avatarFileItem: {} } as any },
        };
        const queryString = buildQuery({ expand });
        const response = await axios.post<IProjectUser>(
          `${rootConfig.odataRoute}/projectUsers${queryString}`,
          newProjectUser,
        );
        onSave(response.data);
      }

      enqueueSnackbar('Successfully updated member.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth='sm' onClose={onClose} open={open} fullScreen={mobileDevice}>
      <DialogTitle>Edit {variableCaseToSentence(ProjectUserRole[role])}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <SelectOneAsync
            name='userId'
            entityId={selectedUser?.id || null}
            entityType={
              role === ProjectUserRole.Facilitator ||
              role === ProjectUserRole.Assessor ||
              role === ProjectUserRole.Moderator
                ? 'facilitators'
                : 'employees'
            }
            labelFields={['userName', 'fullName']}
            filterFields={['userName', 'firstName', 'lastName']}
            onChange={(event: any, value: any) => setSelectedUser(value || null)}
            label='User'
          />
        </Box>
        {role === ProjectUserRole.Facilitator && (
          <Box sx={{ mt: 2 }}>
            {facilitatorRate?.fee?.prices && (
              <>
                <Typography>Rate</Typography>
                {facilitatorRate?.fee?.prices?.map((feePrice) => (
                  <Typography variant='h6'>
                    {CURRENCYSYMBOL[feePrice.price?.currency as keyof typeof CURRENCYSYMBOL]}{' '}
                    {feePrice.price?.amount}
                  </Typography>
                ))}
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box flexGrow={1} />
        <Button color='primary' disabled={isSubmitting} variant='contained' onClick={handleSubmit}>
          Confirm
        </Button>
        <Button color='primary' onClick={onClose} variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMember;
