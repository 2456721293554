import React, { useState, useRef } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import { ExceptionHandler } from 'components';
import { IFileItem } from 'app/models/responses/IFileItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import getFileUrl from 'utils/getFileUrl';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { ConfirmationModal } from 'components/_dashboard';
import { IFolderItem } from 'app/models/responses/IFolderItem';
import { FolderEdit } from './components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fileContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menu: {
    maxWidth: '100%',
    width: 200,
  },
  placeholder: {
    height: 140,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'inset 0 0 30px 2px rgba(0,0,0,0.2)',
    },
  },
  folderIcon: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    fontSize: theme.spacing(6),
  },
  displayName: {
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
}));

interface IProps {
  folder: IFolderItem;
  onOpen: () => void;
  onDelete: () => void;
  onUpdate: (updatedFolder: IFolderItem) => void;
}
const FolderCard: React.FC<IProps> = (props) => {
  const { folder, onOpen, onUpdate, onDelete } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleDownload = async () => {};

  const handleDelete = () => {
    setOpenDelete(false);
    onDelete();
  };

  const handleConfirmDelete = () => {
    setOpenDelete(true);
    setOpenMenu(false);
  };

  const handleCardEdit = () => {
    setOpenEdit(true);
    setOpenMenu(false);
  };

  return (
    <Card>
      <div className={classes.placeholder} onClick={onOpen}>
        <FolderOpenIcon className={classes.folderIcon} />
        <Typography variant='overline'>
          {
            (((folder as any)['childFileItems@odata.count'] as number) +
              (folder as any)['childFolderItems@odata.count']) as number
          }
        </Typography>
      </div>
      <CardContent className={classes.fileContent}>
        <div>
          {folder.name!.length > 45 ? (
            <Tooltip title={folder.name!}>
              <Typography color='textPrimary' variant='subtitle2' className={classes.displayName}>
                {folder.name!.substr(0, 45)}...
              </Typography>
            </Tooltip>
          ) : (
            <Typography color='textPrimary' variant='subtitle2' className={classes.displayName}>
              {folder.name}
            </Typography>
          )}
        </div>
        <div>
          <Tooltip title='Options'>
            <IconButton
              edge='end'
              onClick={() => setOpenMenu(true)}
              ref={moreRef}
              size='small'
              style={{ color: theme.palette.text.secondary }}
            >
              <MoreIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color='primary' fullWidth variant='text' onClick={handleDownload}>
          <Grid container spacing={2} alignItems='center' justifyContent='center'>
            <GetAppIcon fontSize='small' />
            <Grid item>Download</Grid>
          </Grid>
        </Button>
      </CardActions>
      <Menu
        anchorEl={moreRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        elevation={1}
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        PaperProps={{
          className: classes.menu,
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={handleCardEdit}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </MenuItem>
        <MenuItem onClick={handleConfirmDelete}>
          <ListItemIcon style={{ color: theme.palette.text.secondary }}>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
      <ConfirmationModal
        title={`Delete Folder`}
        message={`Are you sure you wish to permanently delete '${folder.name}' and all of its contents?`}
        onCancel={() => setOpenDelete(false)}
        onConfirm={handleDelete}
        open={openDelete}
        error={true}
        confirmText={{
          matchText: folder.name!,
          message: 'Please enter the folder name to confirm.',
        }}
      />
      <FolderEdit
        onUpdate={onUpdate}
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        folder={folder}
      />
    </Card>
  );
};

export default FolderCard;
