import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  TextField,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  errorButton: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

interface IConfirmText {
  matchText: string;
  message: string;
}

interface IProps {
  open: boolean;
  title: string;
  message: any;
  onConfirm: () => void;
  onCancel: () => void;
  confirmCheckbox?: string;
  confirmText?: IConfirmText;
  error?: boolean;
  loading?: boolean;
}

const ConfirmationModal: React.FC<IProps> = (props) => {
  const {
    open,
    title,
    message,
    onConfirm,
    onCancel,
    confirmCheckbox,
    error,
    confirmText,
    loading,
  } = props;

  const [confirmTextInput, setConfirmTextInput] = useState('');
  const [confirmChecked, setConfirmChecked] = useState(false);
  const classes = useStyles();

  const handleConfirmChange = (event: any) => {
    setConfirmChecked(event.target.checked);
  };

  const handleCancel = () => {
    onCancel();
    setConfirmChecked(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setConfirmChecked(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {confirmText && (
          <>
            <DialogContentText>
              <Box sx={{ mb: 0.5 }}>{confirmText.message}</Box>

              <TextField
                fullWidth
                name='confirm'
                onChange={(event: any) => setConfirmTextInput(event.target.value)}
                value={confirmTextInput}
                variant='outlined'
                required
              />
            </DialogContentText>
          </>
        )}
        {confirmCheckbox && (
          <DialogContentText>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={confirmChecked} onChange={handleConfirmChange} />}
                label={confirmCheckbox}
              />
            </FormGroup>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color='primary'
          className={error ? classes.errorButton : undefined}
          variant='contained'
          disabled={
            (confirmCheckbox !== undefined && confirmCheckbox.length > 0 && !confirmChecked) ||
            (confirmText != undefined && confirmText.matchText !== confirmTextInput) ||
            loading
          }
        >
          Confirm
        </Button>
        <Button onClick={handleCancel} color='primary' variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
