import ChatIcon from '@material-ui/icons/Chat';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { IProject } from 'app/models/responses/IProject';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery, { Expand } from 'odata-query';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import Color from 'color';
import { ExceptionHandler } from 'components';
import { Page } from 'components/_dashboard';
import { rootConfig } from 'config';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'utils/axios';
import { ProjectPlan, ProjectProgress } from './components';

const useStyles = makeStyles((theme) => ({
  coursesRoot: {
    marginTop: theme.spacing(4),
  },
  placeholder: {
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemCard: {
    height: '100%',
  },
  itemCardActionArea: {
    height: '100%',
  },
  content: {
    paddingTop: theme.spacing(1),
    height: '100%',
  },
  iconContainer: {
    '& svg': {
      width: '100%',
      height: '100%',
    },
    height: 120,
    width: 120,

    padding: theme.spacing(3),
    borderRadius: '50%',
  },
}));

interface IProps {}

const ProjectDashboard = ({}: IProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] = useState<IProject | null>(null);
  const classes = useStyles();
  const [view, setView] = useState<'home' | 'progress' | 'plan' | 'learner-evaluation'>('home');

  const getProject = useCallback(async () => {
    try {
      const expand: Expand<IProject> = [
        'projectClients/client',
        'projectClients/status',
        'createdByUser',
        'courseRevision/course',
        'projectLearners/learner',
        'projectEvents',
        'projectDeliveryMethod',
        'venue',
        'projectPhase',
        'projectStatus',
        'recruitmentRequest',
        'hostingRequest',
      ];
      const queryString = buildQuery({ expand });
      const response = await axios.get(`${rootConfig.odataRoute}/projects(${id})${queryString}`);
      if (mounted.current) {
        setProject(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, id, enqueueSnackbar]);

  useEffect(() => {
    getProject();
  }, [getProject]);

  const handleBack = () => {
    if (view === 'home') {
      navigate('/');
    } else {
      setView('home');
    }
  };

  if (!project) return <LinearProgress />;

  return (
    <Page title='Project Details'>
      <Box sx={{ mb: 2 }}>
        <Link onClick={handleBack} color='textPrimary' style={{ cursor: 'pointer' }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>{view === 'home' ? 'Projects' : 'Project Dashboard'}</Typography>
          </Box>
        </Link>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography color='textPrimary' variant='h4'>
          {project.name}
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {view === 'plan' ? 'Project Plan' : view === 'progress' ? 'Progress Report' : ''}
        </Typography>
      </Box>

      {view === 'home' && (
        <Grid container spacing={2} alignItems='stretch'>
          <Grid item xs={12} md={3}>
            <Card className={classes.itemCard}>
              <CardActionArea
                component={RouterLink}
                to='/communication/chat'
                className={classes.itemCardActionArea}
              >
                <div className={classes.placeholder}>
                  <Box
                    className={classes.iconContainer}
                    style={{ backgroundColor: Color(colors.orange[500]).alpha(0.1).toString() }}
                  >
                    <ChatIcon style={{ color: colors.orange[500] }} />
                  </Box>
                </div>
                <CardContent className={classes.content}>
                  <Typography variant='subtitle1'>Project Overview</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    View project details.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.itemCard}>
              <CardActionArea
                className={classes.itemCardActionArea}
                onClick={() => setView('plan')}
              >
                <div className={classes.placeholder}>
                  <Box
                    className={classes.iconContainer}
                    style={{ backgroundColor: Color(colors.green[500]).alpha(0.1).toString() }}
                  >
                    <AssignmentOutlinedIcon style={{ color: colors.green[500] }} />
                  </Box>
                </div>
                <CardContent className={classes.content}>
                  <Typography variant='subtitle1'>Project Plan</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    View and export project plan.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.itemCard}>
              <CardActionArea
                className={classes.itemCardActionArea}
                onClick={() => setView('progress')}
              >
                <div className={classes.placeholder}>
                  <Box
                    className={classes.iconContainer}
                    style={{ backgroundColor: Color(colors.purple[500]).alpha(0.1).toString() }}
                  >
                    <LinearScaleIcon style={{ color: colors.purple[500] }} />
                  </Box>
                </div>
                <CardContent className={classes.content}>
                  <Typography variant='subtitle1'>Project Progress</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    View and export project progress report.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={3}>
            <Card className={classes.itemCard}>
              <CardActionArea
                onClick={() => setView('learner-evaluation')}
                className={classes.itemCardActionArea}
              >
                <div className={classes.placeholder}>
                  <Box
                    className={classes.iconContainer}
                    style={{ backgroundColor: Color(colors.pink[500]).alpha(0.1).toString() }}
                  >
                    <RecordVoiceOverOutlinedIcon style={{ color: colors.pink[500] }} />
                  </Box>
                </div>
                <CardContent className={classes.content}>
                  <Typography variant='subtitle1'>Learner Feedback</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    View individual learner evaluation feedback.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card className={classes.itemCard}>
              <CardActionArea
                component={RouterLink}
                to='/communication/chat'
                className={classes.itemCardActionArea}
              >
                <div className={classes.placeholder}>
                  <Box
                    className={classes.iconContainer}
                    style={{ backgroundColor: Color(colors.blue[500]).alpha(0.1).toString() }}
                  >
                    <ChatIcon style={{ color: colors.blue[500] }} />
                  </Box>
                </div>
                <CardContent className={classes.content}>
                  <Typography variant='subtitle1'>Chat</Typography>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Chat with iLearn and get insight into your project.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      )}

      {view === 'progress' && <ProjectProgress project={project} />}
      {view === 'plan' && <ProjectPlan project={project} />}
      {view === 'learner-evaluation' && <></>}
    </Page>
  );
};

export default ProjectDashboard;
