import { Box, Button, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { EntityContext } from 'app/enums/EntityContext';
import { IComment } from 'app/models/responses/IComment';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import axios from 'utils/axios';
import { Comment, CommentAdd } from './components';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import clsx from 'clsx';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import StatusIndicator from '../StatusIndicator';
import { IConnectionStatus } from 'app/models/IConnectionStatus';

const useStyles = makeStyles((theme) => ({
  root: {},
  addButton: {
    marginRight: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  connectionStatus: {
    display: 'inline',
  },
  comment: {
    borderRight: `0px solid ${theme.palette.secondary.main}`,
  },
  commentFocused: {
    borderRightWidth: `4px`,
    transition: '0.1s',
    transitionDelay: '0.3s',
  },
}));

interface IProps {
  context: EntityContext;
  contextId: number;
}

const GenericCommentsEditor: React.FC<IProps> = (props) => {
  const { contextId, context } = props;
  const mounted = useMounted();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [focusedComments, setFocusedComments] = useState<IComment[] | null>(null);
  const [comments, setComments] = useState<IComment[]>([]);
  const latestComments: any = useRef(null);
  latestComments.current = comments;
  const [commentAddOpen, setCommentAddOpen] = useState(false);
  const [commentAddParent, setCommentAddParent] = useState<IComment | undefined>(undefined);
  const [sortBy, setSortBy] = useState('desc');
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${rootConfig.serverUrl}/hubs/comments`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result) => {
          connection.invoke('JoinGroup', context, contextId).catch((e) => console.error(e));

          connection.on('CreateComment', async (comment: IComment) => {
            try {
              const updatedComments = [...latestComments.current];
              if (sortBy === 'asc') {
                updatedComments.push(comment);
              } else {
                updatedComments.unshift(comment);
              }
              setComments(updatedComments);
            } catch (error) {
              enqueueSnackbar(<ExceptionHandler exception={error} />, {
                variant: 'error',
              });
            }
          });

          connection.on('UpdateComment', async (updatedComment: IComment) => {
            try {
              const updatedComments = [...latestComments.current];
              updatedComments[updatedComments.map((e) => e.id).indexOf(updatedComment.id)] =
                updatedComment;
              setComments(updatedComments);
            } catch (error) {
              enqueueSnackbar(<ExceptionHandler exception={error} />, {
                variant: 'error',
              });
            }
          });

          connection.on('DeleteComment', (deletedComment: IComment) => {
            const updatedComments = [...latestComments.current].filter(
              (comment) => comment.id !== deletedComment.id,
            );
            setComments(updatedComments);
          });
        })
        .catch((e) =>
          enqueueSnackbar(
            <ExceptionHandler
              exception={'Connection failed. Comments will now only update on refresh.'}
            />,
            { variant: 'error' },
          ),
        );
    }
  }, [connection]);

  const getComments = useCallback(async () => {
    try {
      const orderBy = [`createDate ${sortBy}`];
      const filter = [{ contextId }, { context: EntityContext[context] }];
      const expand = ['user/avatarFileItem'];
      const queryString = buildQuery({ expand, filter, orderBy });
      const response = await axios.get(`${rootConfig.odataRoute}/comments${queryString}`);
      if (mounted.current) {
        setComments(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, contextId, enqueueSnackbar, context, sortBy]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleCommentDelete = async (commentToDelete: IComment) => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${rootConfig.odataRoute}/comments(${commentToDelete.id})`,
      );
      if (connection) await connection.send('DeleteComment', commentToDelete);
      enqueueSnackbar(`Successfully deleted comment.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const handleReplyAdd = (parentComment: IComment) => {
    setCommentAddParent(parentComment);
    setCommentAddOpen(true);
  };

  const handleCommentAddClose = () => {
    setCommentAddParent(undefined);
    setCommentAddOpen(false);
  };

  const handleCommentsFocus = (targetComments: IComment[]) => {
    setFocusedComments(targetComments);

    var focusPost = document.getElementById(`comment-${targetComments[0].id}`);
    focusPost?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12}>
        <CommentAdd
          open={commentAddOpen}
          onClose={handleCommentAddClose}
          context={context}
          contextId={contextId}
          parentComment={commentAddParent}
          connection={connection}
        />
        <Button
          className={classes.addButton}
          color='secondary'
          variant='contained'
          onClick={() => setCommentAddOpen(true)}
        >
          <AddIcon className={classes.buttonIcon} fontSize='small' />
          Add
        </Button>
        {sortBy === 'asc' ? (
          <Button onClick={() => setSortBy('desc')}>
            <ArrowUpwardIcon className={classes.buttonIcon} fontSize='small' />
            Old to new
          </Button>
        ) : (
          <Button onClick={() => setSortBy('asc')}>
            <ArrowDownwardIcon className={classes.buttonIcon} fontSize='small' />
            New to old
          </Button>
        )}
      </Grid>
      {comments.length > 0 ? (
        <Fragment>
          {comments.map((comment) => (
            <Grid key={comment.id!} item xs={12}>
              <Comment
                connection={connection}
                comment={comment}
                onDelete={handleCommentDelete}
                onReply={handleReplyAdd}
                onFocus={handleCommentsFocus}
                className={clsx(classes.comment, {
                  [classes.commentFocused]: focusedComments?.find((e) => e.id === comment.id),
                })}
              />
            </Grid>
          ))}
        </Fragment>
      ) : (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            style={{ width: '100%', maxHeight: 250, marginBottom: 10, marginTop: 30 }}
            src={`${rootConfig.staticFileRoot}/static/illustrations/undraw_manage_chats_re_0yoj.svg`}
            alt='Welcome'
          />
          <Typography variant='h6'>No comments at this moment...</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default GenericCommentsEditor;
