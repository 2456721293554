import React, { useState } from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    paddingRight: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingRight: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

interface IProps {
  active?: boolean;
  children?: React.ReactNode;
  depth: number;
  icon?: any;
  info?: React.ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: React.FC<IProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon: Icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);
  const classes = useStyles();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft,
  };

  // Branch
  if (children) {
    return (
      <ListItem disableGutters className={classes.item} {...other}>
        <Button className={classes.button} onClick={handleToggle} style={style} variant='text'>
          {Icon && <Icon className={classes.icon} fontSize='small' />}
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color='inherit' />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color='inherit' />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem disableGutters className={classes.itemLeaf}>
      <Button
        /* @ts-ignore */
        component={path && RouterLink}
        style={style}
        className={clsx(classes.buttonLeaf, {
          [classes.active]: active,
        })}
        variant='text'
        to={path}
      >
        {Icon && <Icon className={classes.icon} fontSize='small' />}
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
