import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  colors,
} from '@material-ui/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import React, { useEffect, useState } from 'react';
import RenderForm from '../RenderForm';
import JSONInput from 'react-json-editor-ajrm';
//@ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import axios from 'utils/axios';
import ExceptionHandler from 'components/ExceptionHandler';
import { rootConfig } from 'config';

interface IProps {
  schema: RJSFSchema;
  uiSchema: UiSchema;
  onSchemaChange: (json: string) => void;
  onUiSchemaChange: (json: string) => void;
}

const FormBuilder = ({
  schema: providedSchema,
  uiSchema: providedUiSchema,
  onSchemaChange,
  onUiSchemaChange,
}: IProps) => {
  const [schema, setSchema] = useState<RJSFSchema>(providedSchema);
  const [uiSchema, setUiSchema] = useState<UiSchema>(providedUiSchema);

  const handleSchemaChange = (data: any) => {
    if (!data || !data?.jsObject || !data?.json) return;

    setSchema(data.jsObject);
    onSchemaChange(data.json);
  };

  const handleUiSchemaChange = (data: any) => {
    if (!data || !data?.jsObject || !data?.json) return;

    setUiSchema(data.jsObject);
    onUiSchemaChange(data.json);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant='caption'>Schema</Typography>
        <JSONInput
          placeholder={schema}
          locale={locale}
          height='500px'
          onChange={(data: any) => handleSchemaChange(data)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant='caption'>UI Schema</Typography>

        <JSONInput
          placeholder={uiSchema}
          locale={locale}
          height='500px'
          onChange={(data: any) => handleUiSchemaChange(data)}
        />
      </Grid>

      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardHeader title='Form Preview' />
          <Divider />
          <CardContent>
            <RenderForm schema={schema} uiSchema={uiSchema} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FormBuilder;
