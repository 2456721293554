import { Avatar, Box, Card, LinearProgress, Typography, colors, useTheme } from '@material-ui/core';
import { IProjectEventTypeTask } from 'app/models/entities/IProjectEventTypeTask';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import axios from 'utils/axios';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import buildQuery, { Filter } from 'odata-query';
import { ProjectEventType } from 'app/enums/ProjectEventType';
import { ProjectEventTypeTaskAction } from 'app/enums/ProjectEventTypeTaskAction';
import { BasicFormTask } from './components';
import Color from 'color';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

interface IProps {
  projectEvent: IProjectEvent;
}

const TaskContent = ({ projectEvent }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [tasks, setTasks] = useState<IProjectEventTypeTask[]>();

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const filter: Filter = {
          projectEventTypeId: {
            eq: {
              type: 'guid',
              value: projectEvent.projectEventTypeId,
            },
          },
        };
        const query = buildQuery({ filter });
        const response = await axios.get<IODataResponse<IProjectEventTypeTask[]>>(
          `${rootConfig.odataRoute}/projectEventTypeTasks${query}`,
        );
        if (mounted) {
          setTasks(response.data.value ?? []);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, projectEvent]);

  if (tasks === undefined) return <LinearProgress />;

  return (
    <Fragment>
      {tasks.length <= 0 ? (
        <></>
      ) : (
        <Box>
          {tasks.map((task) => (
            <Box sx={{ mb: 2 }} key={task.id}>
              <Card variant='outlined'>
                <Box sx={{ p: 1 }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: Color(colors.purple[500]).alpha(0.1).toString() }}
                    >
                      <AssignmentTurnedInOutlinedIcon style={{ color: colors.purple[500] }} />
                    </Avatar>
                    <Typography variant='h6'>{task.description}</Typography>
                  </Box>
                  {task.action ===
                    ProjectEventTypeTaskAction[ProjectEventTypeTaskAction.BasicForm] && (
                    <BasicFormTask task={task} />
                  )}
                </Box>
              </Card>
            </Box>
          ))}
        </Box>
      )}
    </Fragment>
  );
};

export default TaskContent;
