import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';
import { AttendanceStatus } from 'app/enums/AttendanceStatus';
import { Scrollbar } from 'components';
import * as jsonpatch from 'fast-json-patch';
import buildQuery from 'odata-query';
import moment from 'moment';
import axios from 'utils/axios';
import { rootConfig } from 'config';
import deepCopy from 'utils/deepCopy';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { IProjectLearnerEventAttendance } from 'app/models/entities/IProjectLearnerEventAttendance';

interface IProps {
  learners: IProjectLearner[];
  trainingSession: IProjectEvent;
  onSave: (updatedLearners: IProjectLearner[]) => void;
}

const EditAttendance: React.FC<IProps> = (props) => {
  const { learners, trainingSession, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [open, setOpen] = useState(false);
  const [formStates, setFormStates] = useState<IProjectLearner[]>([]);

  useEffect(() => {
    setFormStates([...learners]);
  }, [learners]);

  const handleSave = async () => {
    try {
      let updatedLearners: IProjectLearner[] = [];
      formStates!.forEach(async (projectLearner) => {
        const oldProjectLearner = (deepCopy(learners) as IProjectLearner[]).find(
          (e) => e.id === projectLearner.id,
        )!;
        const expand = ['learner'];
        const queryString = buildQuery({ expand });
        const diff = jsonpatch.compare(oldProjectLearner, projectLearner);
        const response = await axios.patch<IProjectLearner>(
          `${rootConfig.odataRoute}/projectLearners(${projectLearner.id})${queryString}`,
          diff,
        );
        updatedLearners = [...updatedLearners].concat(response.data);
        onSave(updatedLearners.sort((a, b) => a.id! - b.id!));
      });

      enqueueSnackbar('Successfully updated attendance.', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating attendance. Please try again later.', {
        variant: 'error',
      });
    } finally {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFormStates([...learners]);
  };

  const handleChange = (
    projectLearner: IProjectLearner,
    learnerTrainingSession: IProjectLearnerEventAttendance | null | undefined,
    value: keyof typeof AttendanceStatus,
  ) => {
    if (learnerTrainingSession) {
      const updatedTrainingSession: IProjectLearnerEventAttendance = {
        ...learnerTrainingSession,
        attendanceStatus: value,
      };
      const updatedAttendances = [...(projectLearner.projectLearnerEventAttendances || [])];
      updatedAttendances[
        updatedAttendances
          .map((e) => e.projectEventId)
          .indexOf(learnerTrainingSession.projectEventId)
      ] = updatedTrainingSession;
      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerEventAttendances: updatedAttendances,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    } else {
      const updatedTrainingSession: IProjectLearnerEventAttendance = {
        attendanceStatus: value,
        projectEventId: trainingSession.id,
      };
      const updatedAttendances = [...(projectLearner.projectLearnerEventAttendances || [])].concat(
        updatedTrainingSession,
      );

      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerEventAttendances: updatedAttendances,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    }
  };

  return (
    <>
      <Tooltip title='Take attendance.'>
        <IconButton onClick={() => setOpen(true)} color='secondary'>
          <EventAvailableIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Attendance for {moment(trainingSession.start).format('DD MMMM YYYY')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems='center'>
            {formStates.map((projectLearner) => {
              const learnerTrainingSession = projectLearner.projectLearnerEventAttendances?.find(
                (e) => e.projectEventId === trainingSession.id,
              );
              console.log(projectLearner);
              return (
                <Fragment key={projectLearner.id}>
                  <Grid item xs={3}>
                    <Typography variant='body2'>{projectLearner.learner?.fullName}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body2'>{projectLearner.learner?.idNumber}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel>Attendance</InputLabel>
                      <Select
                        value={learnerTrainingSession?.attendanceStatus || ''}
                        label='Attendance'
                        onChange={(e: any) =>
                          handleChange(projectLearner, learnerTrainingSession, e.target.value)
                        }
                        name='status'
                        variant='outlined'
                      >
                        {odataOptionsArrayFromEnum(AttendanceStatus, false).map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSave} color='primary'>
            Save
          </Button>
          <Button variant='outlined' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditAttendance;
