import { CircularProgress, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import buildQuery, { Filter, OrderBy } from 'odata-query';
import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';

import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { Autocomplete } from '@material-ui/lab';

interface IProps {
  endPoint: string;
  entityValue: string | null;
  field: string;
  onChange: (event: any, value: string) => void;
  name: string;
  label?: React.ReactNode;
  filter?: Filter;
  required?: boolean;
  disabled?: boolean;
  orderBy?: OrderBy<any>;
  error?: boolean;
  top?: number;
  placeholder?: string;
}

const SelectTextAsync: React.FC<IProps> = (props) => {
  const {
    name,
    endPoint,
    entityValue,
    filter: providedFilter,
    onChange,
    field,
    label,
    required,
    disabled,
    error,
    orderBy,
    top: providedTop,
    placeholder,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[] | null>(null);
  const [optionValue, setOptionValue] = useState(entityValue || '');
  const loading = open && options === null;

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const top = providedTop || 50;
        const select = [field];
        const filter = { [field]: { contains: optionValue } };
        const queryString = buildQuery({ select, filter, orderBy, top });
        const response = await axios.get(`${rootConfig.odataRoute}/${endPoint}${queryString}`);
        if (mounted) {
          setOptions(response.data.value.map((option: string) => option[field as any]));
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [
    loading,
    enqueueSnackbar,
    endPoint,
    field,
    providedFilter,
    orderBy,
    optionValue,
    providedTop,
  ]);

  useEffect(() => {
    if (!open) {
      setOptions(null);
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<any>, value: string) => {
    setOptionValue(value);
    onChange(event, value);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option}
      options={options || []}
      loading={loading}
      disabled={disabled || false}
      value={optionValue}
      freeSolo
      onChange={(event, value) => handleChange(event, value || '')}
      onInputChange={(event, value, _reason) => handleChange(event, value || '')}
      inputValue={optionValue}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={error || false}
          required={required || false}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectTextAsync;
