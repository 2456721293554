import React, { Fragment, useEffect, useState } from 'react';
import {
  colors,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { AxiosError, CanceledError } from 'axios';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme) => ({
  viewDetailTitle: {
    textDecoration: 'underline',
    color: colors.blueGrey[50],
    cursor: 'pointer',
  },
}));

interface ValidationErrorProps {
  errors: string[];
}
const ValidationErrors = ({ errors }: ValidationErrorProps) => {
  return (
    <Table>
      <TableBody>
        {errors.map((error, index) => (
          <TableRow selected={index % 2 !== 0} key={uuid()}>
            <TableCell>{error}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface IProps {
  exception: any;
}

const ExceptionHandler: React.FC<IProps> = (props) => {
  const { exception } = props;
  const classes = useStyles();
  const defaultError = 'Something went wrong. Please try again later.';
  const [title, setTitle] = useState(defaultError);
  const [validationErrors, setValidationErrors] = useState<string[] | null>(null);
  const [viewDetail, setViewDetail] = useState(false);

  useEffect(() => {
    try {
      if (exception instanceof CanceledError) {
        setTitle('Request cancelled.');
      } else if (exception instanceof AxiosError) {
        setTitle(exception.response?.data?.exception || exception.message || defaultError);
      }
      setValidationErrors(exception.response.data.messages as string[]);
    } catch (error) {}
  }, [exception]);

  return (
    <Fragment>
      <span>
        {title}
        {validationErrors && (
          <span className={classes.viewDetailTitle} onClick={() => setViewDetail(true)}>
            View details.
          </span>
        )}
      </span>
      <Dialog open={viewDetail} onClose={() => setViewDetail(false)}>
        <DialogTitle>Error Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {validationErrors && <ValidationErrors errors={validationErrors} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewDetail(false)} color='primary' variant='outlined'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ExceptionHandler;
