import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { CircularProgress, TextField, useTheme } from '@material-ui/core/';
import Autocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import buildQuery, { Filter, OrderBy } from 'odata-query';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { ExceptionHandler } from 'components';
import { IEntityBase } from 'app/models/responses/IEntityBase';
import { rootConfig } from 'config';
import _ from 'lodash';
import deepCopy from 'utils/deepCopy';

interface IProps {
  entityType: string;
  endPoint?: string;
  entityIds: number[];
  labelFields: string[];
  filterFields: string[];
  onChange: any;
  name: string;
  label?: React.ReactNode;
  filter?: Filter;
  orderBy?: OrderBy<any>;
  top?: number;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const SelectManyAsync: React.FC<IProps> = (props) => {
  const {
    name,
    entityType,
    endPoint,
    filter: providedFilter,
    entityIds,
    onChange,
    labelFields,
    label,
    filterFields,
    orderBy,
    required,
    top: providedTop,
    error = false,
    helperText = '',
  } = props;

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IEntityBase[] | null>(null);
  const [optionValues, setOptionValues] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options === null;

  useEffect(() => {
    let mounted = true;
    if (entityIds.length <= 0) {
      return undefined;
    }

    (async () => {
      try {
        const select = [...labelFields, 'id'];
        const filter = { id: { in: entityIds } };
        const queryString = buildQuery({ select, filter });
        const response = await axios.get(`${rootConfig.odataRoute}/${entityType}${queryString}`);
        if (mounted) {
          setOptionValues(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, entityType, entityIds, labelFields]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const top = providedTop || 250;
        const select = [...labelFields, 'id'];

        let filter: any = [{ or: [] }];
        filterFields.forEach((field) => {
          filter[0].or.push({
            [field]: { contains: inputValue },
          });
        });
        filter.push(providedFilter);

        const queryString = buildQuery({ select, filter, top, orderBy });
        const response = await axios.get(
          `${rootConfig.odataRoute}/${endPoint || entityType}${queryString}`,
        );
        if (mounted) {
          setOptions(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [
    loading,
    enqueueSnackbar,
    entityType,
    labelFields,
    providedFilter,
    inputValue,
    providedTop,
    filterFields,
  ]);

  useEffect(() => {
    if (!open) {
      setOptions(null);
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<{}>, value: IEntityBase | any) => {
    setOptionValues(value);
    onChange(event, value);
  };

  const handleOptionLabelBuild = (option: IEntityBase | any) => {
    let label = '';

    for (let i = 0; i < labelFields.length; i++) {
      if (i === 0) {
        label += option[labelFields[i]];
      } else {
        label += ` | ${option[labelFields[i]]}`;
      }
    }

    return label;
  };

  const handleInput = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(value);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => handleOptionLabelBuild(option)}
      options={options || []}
      loading={loading}
      multiple
      filterSelectedOptions
      value={optionValues}
      onChange={handleChange}
      onInputChange={handleInput}
      inputValue={inputValue}
      popupIcon={<ArrowDropDownIcon style={{ fill: theme.palette.text.secondary }} />}
      closeIcon={<CloseIcon style={{ fill: theme.palette.text.secondary }} />}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          variant='outlined'
          required={required}
          label={label}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

SelectManyAsync.defaultProps = {
  filter: {},
};

export default SelectManyAsync;
