import React, { useCallback, useState, useEffect, Fragment } from 'react';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import useMounted from 'hooks/useMounted';
import buildQuery from 'odata-query';
import { IFileItem } from 'app/models/responses/IFileItem';
import { rootConfig } from 'config';
import { EntityContext } from 'app/enums/EntityContext';
import {
  Grid,
  LinearProgress,
  Typography,
  Breadcrumbs,
  Box,
  Link,
  makeStyles,
} from '@material-ui/core';
import { FileCard, FileAdd, FolderCard, FolderAdd, Navigation } from './components';
import { IFolderItem } from 'app/models/responses/IFolderItem';
import { IBreadcrumb } from 'app/models/IBreadcrumb';

const useStyles = makeStyles(() => ({
  root: {},
  breadcrumbLink: {
    cursor: 'pointer',
  },
}));

interface IProps {
  contextId: number;
  context: EntityContext;
}

const GenericDocumentsEditor: React.FC<IProps> = (props) => {
  const { contextId, context } = props;
  const mounted = useMounted();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<IFileItem[] | null>(null);
  const [folders, setFolders] = useState<IFolderItem[] | null>(null);
  const [openedFolder, setOpenedFolder] = useState<number | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([
    {
      name: 'Home',
      id: contextId,
    },
  ]);

  const getFiles = useCallback(async () => {
    try {
      const filter = [
        { parentContextId: contextId },
        { parentContext: EntityContext[context] },
        { parentFolderItemId: openedFolder },
      ];
      const expand = ['fileType'];
      const queryString = buildQuery({ expand, filter });
      const response = await axios.get(`${rootConfig.odataRoute}/fileItems${queryString}`);
      if (mounted.current) {
        setFiles(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, contextId, enqueueSnackbar, context, openedFolder]);

  const getFolders = useCallback(async () => {
    try {
      const expand = {
        childFileItems: { top: 0, count: true },
        childFolderItems: { top: 0, count: true },
      };
      const filter = [
        { parentContextId: contextId },
        { parentContext: EntityContext[context] },
        { parentFolderItemId: openedFolder },
      ];
      const queryString = buildQuery({ filter, expand });
      const response = await axios.get(`${rootConfig.odataRoute}/folderItems${queryString}`);
      if (mounted.current) {
        setFolders(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, contextId, enqueueSnackbar, context, openedFolder]);

  useEffect(() => {
    getFolders();
    getFiles();
  }, [getFiles, getFolders]);

  const handleFileDelete = async (fileToDelete: IFileItem) => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(`${rootConfig.odataRoute}/fileItems(${fileToDelete.id})`);
      setFiles((prevFiles) => (prevFiles as IFileItem[]).filter((file) => file !== fileToDelete));
      enqueueSnackbar(`Successfully deleted file.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const handleFolderDelete = async (folderToDelete: IFolderItem) => {
    try {
      // eslint-disable-next-line
      const response = await axios.delete(
        `${rootConfig.odataRoute}/folderItems(${folderToDelete.id})`,
      );
      setFolders((prevFolders) =>
        (prevFolders as IFolderItem[]).filter((file) => file !== folderToDelete),
      );
      enqueueSnackbar(`Successfully deleted folder.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  const handleFileAdd = (filesToAdd: IFileItem[]) => {
    setFiles([...filesToAdd, ...(files as IFileItem[])]);
  };

  const handleFolderAdd = (folderToAdd: IFolderItem) => {
    setFolders([folderToAdd, ...(folders as IFolderItem[])]);
  };

  const handleFileUpdate = (oldFile: IFileItem, updatedFile: IFileItem) => {
    const updatedFiles = [...(files as IFileItem[])];
    updatedFiles[updatedFiles.indexOf(oldFile)] = updatedFile;
    setFiles(updatedFiles);
  };

  const handleFolderUpdate = (oldFolder: IFolderItem, updatedFolder: IFolderItem) => {
    const updatedFolders = [...(folders as IFolderItem[])];
    updatedFolders[updatedFolders.indexOf(oldFolder)] = updatedFolder;
    setFolders(updatedFolders);
  };

  const handleFolderOpen = (folder: IFolderItem) => {
    setFiles(null);
    setFolders(null);
    setBreadcrumbs((breadcrumbs) =>
      [...(breadcrumbs as IBreadcrumb[])].concat({
        id: folder.id!,
        name: folder.name!,
      }),
    );
    setTimeout(function () {
      setOpenedFolder(folder.id!);
    }, 150);
  };

  const handleFolderNavigate = (breadcrumb: IBreadcrumb) => {
    setFiles(null);
    setFolders(null);
    setBreadcrumbs((breadcrumbs) =>
      [...(breadcrumbs as IBreadcrumb[])].slice(0, breadcrumbs.indexOf(breadcrumb) + 1),
    );
    setTimeout(function () {
      setOpenedFolder(breadcrumbs.indexOf(breadcrumb) === 0 ? null : breadcrumb.id);
    }, 150);
  };

  if (!folders || !files)
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Navigation breadcrumbs={breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Navigation breadcrumbs={breadcrumbs} onNavigate={handleFolderNavigate} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
        <Box sx={{ mb: 2 }}>
          <FileAdd
            onFileAdd={handleFileAdd}
            parentContext={context}
            parentContextId={contextId}
            parentFolderItemId={openedFolder}
          />
        </Box>
        <FolderAdd
          onAdd={handleFolderAdd}
          parentContext={context}
          parentContextId={contextId}
          parentFolderItemId={openedFolder}
        />
      </Grid>
      {files.length === 0 && folders.length === 0 ? (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <img
            style={{ width: '100%', maxHeight: 250, marginBottom: 10, marginTop: 30 }}
            src={`${rootConfig.staticFileRoot}/static/illustrations/undraw_personal_file_re_5joy.svg`}
            alt='Welcome'
          />
          <Typography variant='h6'>No documents at this moment...</Typography>
        </Grid>
      ) : (
        <Fragment>
          {folders.map((folder) => (
            <Grid key={folder.id!} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FolderCard
                folder={folder}
                onDelete={() => handleFolderDelete(folder)}
                onOpen={() => handleFolderOpen(folder)}
                onUpdate={(updatedFolder: IFolderItem) => handleFolderUpdate(folder, updatedFolder)}
              />
            </Grid>
          ))}
          {files.map((file) => (
            <Grid key={file.id!} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FileCard
                file={file}
                onDelete={() => handleFileDelete(file)}
                onUpdate={(updatedFile: IFileItem) => handleFileUpdate(file, updatedFile)}
              />
            </Grid>
          ))}
        </Fragment>
      )}
    </Grid>
  );
};

export default GenericDocumentsEditor;
