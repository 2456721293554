import { IProject } from 'app/models/responses/IProject';
import {
  Progress,
  ProjectPlan as ProjectPlanDisplay,
} from 'pages/_dashboard/ProjectDetails/components';
import React from 'react';

interface IProps {
  project: IProject;
}

const ProjectPlan = ({ project }: IProps) => {
  return <ProjectPlanDisplay project={project} />;
};

export default ProjectPlan;
