import { format } from 'date-fns';
import { Box, Button, ButtonGroup, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';

const viewOptions = [
  {
    icon: ViewConfigIcon,
    label: 'Month',
    value: 'dayGridMonth',
  },
  {
    icon: ViewWeekIcon,
    label: 'Week',
    value: 'timeGridWeek',
  },
  {
    icon: ViewDayIcon,
    label: 'Day',
    value: 'timeGridDay',
  },
  {
    icon: ViewAgendaIcon,
    label: 'Agenda',
    value: 'listWeek',
  },
];

export type View = 'dayGridMonth' | 'timeGridWeek' | 'timeGridWeek' | 'timeGridDay' | 'listWeek';

interface IProps {
  date: Date;
  onDateNext: any;
  onDatePrev: any;
  onDateToday: any;
  onViewChange: any;
  view: View;
}

const Toolbar: React.FC<IProps> = (props) => {
  const { date, onDateNext, onDatePrev, onDateToday, onViewChange, view, ...other } = props;

  const handleViewChange = (newView: View) => {
    if (onViewChange) {
      onViewChange(newView);
    }
  };

  return (
    <Grid alignItems='center' container justifyContent='space-between' spacing={3} {...other}>
      <Grid item>
        <ButtonGroup size='small'>
          <Button onClick={onDatePrev}>Prev</Button>
          <Button onClick={onDateToday}>Today</Button>
          <Button onClick={onDateNext}>Next</Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <Typography color='textPrimary' variant='h4'>
          {format(date, view === 'timeGridDay' ? 'dd MMMM y' : 'MMMM y')}
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ color: 'text.primary' }}>
          {viewOptions.map((viewOption) => {
            const Icon = viewOption.icon;
            return (
              <Tooltip key={viewOption.value} title={viewOption.label}>
                <IconButton
                  color={viewOption.value === view ? 'primary' : 'inherit'}
                  onClick={() => handleViewChange(viewOption.value as View)}
                >
                  <Icon fontSize='small' />
                </IconButton>
              </Tooltip>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Toolbar;
