import { isEqual } from 'lodash';
import { useRef } from 'react';

export function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>();

  // Check if the provided values are deeply equal
  if (value !== undefined && !isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
