import { Avatar, useTheme } from '@material-ui/core';
import { IFileItem } from 'app/models/responses/IFileItem';
import { AvatarStyleType } from 'app/types/AvatarStyleType';
import { rootConfig } from 'config';
import React, { useEffect, useState } from 'react';
import getFileUrl from 'utils/getFileUrl';

interface IProps {
  avatarFile?: IFileItem | null;
  name: string;
  className?: string;
  component?: any;
  to?: string;
  avatarType?: AvatarStyleType;
}

const GenericAvatar: React.FC<IProps> = (props) => {
  const { className, avatarFile, avatarType, name, component, to, ...rest } = props;
  const theme = useTheme();
  const [avatarUrl, setAvatarUrl] = useState(
    avatarFile
      ? getFileUrl(avatarFile)
      : `${rootConfig.avatarFallbackRoute}/${avatarType}/svg?seed=${name}`,
  );

  useEffect(() => {
    setAvatarUrl(
      avatarFile
        ? getFileUrl(avatarFile)
        : `${rootConfig.avatarFallbackRoute}/${avatarType}/svg?seed=${name}`,
    );
  }, [name, avatarFile]);

  return (
    <Avatar
      className={className}
      component={component}
      to={to}
      {...rest}
      alt={name}
      src={name ? avatarUrl : undefined}
    />
  );
};

GenericAvatar.defaultProps = {
  avatarType: 'identicon',
};

export default GenericAvatar;
