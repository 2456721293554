import { IProject } from 'app/models/responses/IProject';
import { ODataGrid } from 'components/_dashboard';
import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { ILearner } from 'app/models/responses/ILearner';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';
import { ApprovalRequestStatus } from 'app/enums/ApprovalRequestStatus';
import { Chip, LinearProgress } from '@material-ui/core';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import { EXTRATIMEREQUESTCOLORS } from '../../../../../constants';

interface IProps {
  project: IProject;
}

const ExtraTime: React.FC<IProps> = (props) => {
  const { project } = props;
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [userLookup, setUserLookup] = useState<any>(undefined);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const select = ['id', 'userName', 'fullName'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/users${queryString}`);
        if (mounted) {
          const userLookups: any = {};
          response.data.value.forEach((user: IApplicationUser) => {
            userLookups[user.id!] = `${user.userName} | ${user.fullName}`;
          });
          setUserLookup(userLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  if (!userLookup) {
    return <LinearProgress />;
  }

  return (
    <ODataGrid
      entityType='projectExtraTimeRequests'
      filter={{ projectId: project.id }}
      columns={[
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'durationInDays',
          title: 'Number of Days',
          type: 'numeric',
          editable: 'onAdd',
          validate: (rowData) =>
            rowData.durationInDays === undefined ||
            (!rowData.durationInDays && rowData.durationInDays !== 0) ||
            rowData.durationInDays < 0
              ? {
                  isValid: false,
                  helperText: 'Number of Days must be a positive integer',
                }
              : true,
        },
        {
          field: 'reason',
          title: 'Reason',
          editable: 'onAdd',
          validate: (rowData) =>
            !rowData.reason ? { isValid: false, helperText: 'Reason is required' } : true,
        },
        {
          field: 'requestedByUserId',
          title: 'Requested By',
          editable: 'never',
          lookup: userLookup,
          initialEditValue: user!.id,
        },
        {
          field: 'status',
          title: 'Status',
          lookup: odataLookupObjFromEnum(ApprovalRequestStatus),
          initialEditValue: ApprovalRequestStatus[ApprovalRequestStatus.Pending],
          editable: 'onUpdate',
          render: (rowData) =>
            rowData.status && (
              <Chip
                variant='default'
                label={rowData.status}
                style={{
                  backgroundColor:
                    EXTRATIMEREQUESTCOLORS[
                      rowData.status.toString().toUpperCase() as keyof typeof EXTRATIMEREQUESTCOLORS
                    ],
                }}
                color='primary'
                size='small'
              />
            ),
        },
        {
          field: 'projectId',
          title: 'Project',
          initialEditValue: project.id,
          filtering: false,
          render: (rowData) => <span>{project.name}</span>,
          hidden: true,
        },
      ]}
      canAdd
      canDelete
      canEdit
    />
  );
};

export default ExtraTime;
