import {
  makeStyles,
  Button,
  Card,
  CardActions,
  Grid,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Badge,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useRef } from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { SelectOneAsync } from 'components/_dashboard';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import { IFileItem } from 'app/models/responses/IFileItem';
import { rootConfig } from 'config';
import { EntityContext } from 'app/enums/EntityContext';
import buildQuery from 'odata-query';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme) => ({
  root: {},
  bodyInput: {
    minHeight: 320,
    marginBottom: theme.spacing(2),
  },
  embeddedComment: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  embeddedCommentAvatar: {
    marginRight: 4,
    width: '13px',
    height: '13px',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    height: 14,
    width: 14,
    marginRight: 5,
  },
  actionReplyContent: {
    display: 'flex',
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  placeholder: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'help',
    padding: theme.spacing(1),
  },
  clearAttachments: {
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
  },
}));

interface IProps {
  onFileAdd: any;
  parentContext: EntityContext;
  parentContextId: number;
  parentFolderItemId: number | null;
}

const FileAdd: React.FC<IProps> = (props) => {
  const { onFileAdd, parentContext, parentContextId, parentFolderItemId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [uploadingFile, setUploadingFile] = useState(false);
  const fileInputRef: any = useRef(null);
  const [fileTypeId, setFileTypeId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();
  const [files, setFiles] = useState<any>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFilesSelect = () => {
    fileInputRef.current.click();
  };

  const handleAttach = (event: any) => {
    setFiles((files: any) => [...files, ...event.target.files]);
  };

  const handleClose = () => {
    setFileTypeId(null);
    setFiles([]);
    setUploadingFile(false);
    setOpen(false);
  };

  const handleAttachmentRemove = (index: number) => {
    setFiles((prevFiles: []) =>
      prevFiles.filter((file: any, oldIndex: number) => oldIndex !== index),
    );
  };

  const handleUpload = async () => {
    try {
      setUploadingFile(true);
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      formData.append('fileTypeId', fileTypeId!.toString());
      formData.append(
        'parentFolderItemId',
        parentFolderItemId === null ? 'null' : parentFolderItemId.toString(),
      );
      formData.append('parentContextId', parentContextId.toString());
      formData.append('parentContext', EntityContext[parentContext]);
      const expand = ['fileType'];
      const queryString = buildQuery({ expand });
      const response = await axios.post(
        `${rootConfig.odataRoute}/fileItems${queryString}`,
        formData,
      );
      onFileAdd(response.data.value);
      enqueueSnackbar('Successfully uploaded files.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setUploadingFile(false);
      setFiles([]);
      setFileTypeId(null);
      setOpen(false);
    }
  };

  return (
    <Card>
      <CardActions>
        <Button color='secondary' fullWidth variant='text' onClick={handleClickOpen}>
          <Grid container spacing={2} alignItems='center' justifyContent='center'>
            <FileCopyOutlinedIcon fontSize='small' />
            <Grid item>Upload Files</Grid>
          </Grid>
        </Button>
      </CardActions>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle>Upload Files</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12}>
              <input
                className={classes.fileInput}
                ref={fileInputRef}
                type='file'
                name='files[]'
                multiple
                onChange={handleAttach}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
              />
              {files.length > 0 && (
                <Grid container spacing={2} alignItems='center'>
                  {files.map((file: any, index: number) => (
                    <Grid item key={index}>
                      <Badge
                        badgeContent={
                          <Tooltip title='Remove' placement='top'>
                            <IconButton
                              disabled={uploadingFile}
                              onClick={() => handleAttachmentRemove(index)}
                              color='secondary'
                              size='small'
                            >
                              <HighlightOffIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        }
                      >
                        <Tooltip title={file.name} placement='top'>
                          <Card>
                            <div className={classes.placeholder}>
                              <InsertDriveFileIcon />
                            </div>
                          </Card>
                        </Tooltip>
                      </Badge>
                    </Grid>
                  ))}
                  <Grid item className={classes.clearAttachments}>
                    <Tooltip title='Remove All' placement='right'>
                      <IconButton
                        color='secondary'
                        onClick={() => setFiles([])}
                        disabled={uploadingFile}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <SelectOneAsync
                name='fileTypeId'
                entityId={fileTypeId}
                entityType='fileTypes'
                labelFields={['name']}
                filterFields={['name']}
                filter={{ not: { id: { in: [1, 2, 3] } } }}
                onChange={(event: any, value: any) => setFileTypeId(value?.id || null)}
                label='File Type'
                disabled={uploadingFile}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleFilesSelect}
            color='secondary'
            variant='outlined'
            disabled={uploadingFile}
          >
            Browse Files
          </Button>
          <Button
            variant='contained'
            onClick={handleUpload}
            color='primary'
            disabled={files.length === 0 || !fileTypeId || uploadingFile}
          >
            {uploadingFile ? <CircularProgress size={25} /> : 'Add'}
          </Button>
          <Button variant='outlined' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default FileAdd;
