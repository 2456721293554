import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AuthBanner } from 'components/_authentication';
import { LoginJWT, ResetPassword } from './components';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { rootConfig } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    paddingTop: '80px',
    paddingBottom: '80px',
    marginTop: theme.spacing(5),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const { platform } = useAuth();
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box className={classes.root}>
        <AuthBanner />
        <Container maxWidth='sm' className={classes.container}>
          <Card>
            <CardContent className={classes.content}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mb: 3,
                }}
              >
                <img
                  alt='iLearn'
                  src={`${rootConfig.staticFileRoot}/static/logos/core_min.svg`}
                  height='50'
                  style={{ marginBottom: 12 }}
                />
                <Typography color='textPrimary' gutterBottom variant='h4'>
                  Log in
                </Typography>
                <Typography color='textSecondary' gutterBottom variant='subtitle2'>
                  Sign in to the iLearn platform
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider className={classes.divider} />
              <Link
                color='textSecondary'
                variant='body2'
                onClick={() => setResetPasswordOpen(true)}
                style={{ cursor: 'pointer' }}
              >
                Forgot your password?
              </Link>
            </CardContent>
          </Card>
        </Container>
        <ResetPassword open={isResetPasswordOpen} onClose={() => setResetPasswordOpen(false)} />
      </Box>
    </>
  );
};

export default Login;
