import { Badge, IconButton, Tooltip } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import { Link as RouterLink } from 'react-router-dom';

const ChatPopover = () => {
  return (
    <>
      <Tooltip title='Chats'>
        <IconButton color='inherit' component={RouterLink} to='/communication/chat'>
          <Badge color='error'>
            <CommentIcon fontSize='small' />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ChatPopover;
