import { Box, Grid, LinearProgress, Link, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { EntityContext } from 'app/enums/EntityContext';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ExceptionHandler } from 'components';
import { Page } from 'components/_dashboard';
import { rootConfig } from 'config';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import buildQuery, { Expand, Select } from 'odata-query';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import axios from 'utils/axios';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import { Content, Section, Tasks } from './components';

interface IProps {}

export type SectionCompositeKey = `${keyof typeof EntityContext}-${number}` | 'standalone' | string;

const Course = (props: IProps) => {
  const { id } = useParams();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [enrolment, setEnrolment] = useState<IProjectLearner | null | undefined>(undefined);
  const [tasks, setTasks] = useState<IProjectEvent[] | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [stepContent, setStepContent] = useState<IProjectEvent>();

  useEffect(() => {
    if (!tasks || tasks.length <= 0) return;

    setActiveStep(tasks[0].id!);
  }, [tasks]);

  const groupedSections = useMemo<Record<SectionCompositeKey, IProjectEvent[]> | null>(() => {
    if (!tasks) return null;

    const groupedEvents: Record<SectionCompositeKey, IProjectEvent[]> = {};

    tasks.forEach((task) => {
      let key: SectionCompositeKey;

      // Check if both parentContext and parentContextId are defined
      if (task.parentContext != null && task.parentContextId != null) {
        // Create a composite key
        key = `${task.parentContext}-${task.parentContextId}`;
      } else {
        // Handle the case where one or both are missing
        key = 'standalone';
      }

      // Check if the key exists in the groupedEvents object
      if (!groupedEvents[key]) {
        groupedEvents[key] = [];
      }

      // Add the event to the appropriate group
      groupedEvents[key].push(task);
    });

    if (Object.keys(groupedEvents).length === 1 && Object.keys(groupedEvents)[0] === 'standalone')
      return null;

    return groupedEvents;
  }, [tasks]);

  useEffect(() => {
    if (!tasks) return;

    const task = tasks.find((e) => e.id === activeStep);
    if (!task) return;

    setStepContent(task);
  }, [activeStep, tasks]);

  useEffect(() => {
    let mounted = true;

    if (!user || !id) return undefined;

    (async () => {
      try {
        const select: Select<IProjectLearner> = ['id', 'status', 'projectId'];
        const expand: Expand<IProjectLearner> = {
          project: {
            select: ['id'] as any,
            expand: {
              courseRevision: {
                select: ['id', 'durationInDays'] as any,
                expand: {
                  course: {
                    select: ['id', 'name', 'type'] as any,
                  },
                },
              },
            },
          },
        };
        const query = buildQuery({ expand, select });
        const response = await axios.get<IProjectLearner>(
          `${rootConfig.odataRoute}/projectLearners(${id})${query}`,
        );
        if (mounted) {
          setEnrolment(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, user, id]);

  useEffect(() => {
    let mounted = true;

    if (!user || !enrolment) return undefined;

    (async () => {
      try {
        const expand: Expand<IProjectEvent> = {
          project: {
            expand: {
              courseRevision: {
                expand: {
                  course: {
                    select: ['id', 'name'] as any,
                  },
                },
              },
            },
          },
          venue: {},
          projectEventType: {},
        };
        const orderBy = ['start asc'];
        const query = buildQuery({ expand, orderBy });
        const response = await axios.get<IODataResponse<IProjectEvent[]>>(
          `${rootConfig.odataRoute}/projectEvents/getLearnerCourseEvents(learnerId=${user.id},projectId=${enrolment.projectId})${query}`,
        );
        if (mounted) {
          setTasks(response.data.value ?? []);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, user, enrolment]);

  const handleStepSelect = (step: number) => {
    setActiveStep(step);
  };

  if (enrolment === undefined || !tasks) return <LinearProgress />;
  if (enrolment === null) return null;

  return (
    <Page title='Course'>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={`/learn/course-history`} color='textPrimary'>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>Courses</Typography>
          </Box>
        </Link>
      </Box>
      <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '10px', flexWrap: 'wrap' }}>
        <Box>
          <Typography variant='h4'>{enrolment.project!.courseRevision!.course!.name}</Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            {variableCaseToSentence(enrolment.project!.courseRevision!.course!.type!)}
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', marginTop: '3px' }}>
            <ScheduleIcon fontSize='small' />
            <Typography variant='body2' color='textSecondary'>
              {enrolment.project!.courseRevision!.durationInDays} days
            </Typography>
          </Box>
        </Box>
        {/* <Button startIcon={<ChatIcon />} color='secondary' variant='contained'>
          Chat
        </Button> */}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* <Box display='flex' alignItems='center' sx={{ mb: 2 }}>
              <Box width='100%' mr={1}>
                <LinearProgress variant='determinate' value={60} style={{ height: '6px' }} />
              </Box>
              <Box minWidth={35}>
                <Typography variant='body2' color='textSecondary'>{`${60}%`}</Typography>
              </Box>
            </Box> */}
            <Box>
              {groupedSections ? (
                <>
                  {Object.keys(groupedSections).map((compositeKey) => (
                    <Section
                      key={compositeKey}
                      activeStep={activeStep}
                      compositeKey={compositeKey as SectionCompositeKey}
                      tasks={groupedSections[compositeKey as SectionCompositeKey]}
                      onSelectStep={handleStepSelect}
                    />
                  ))}
                </>
              ) : (
                <Tasks activeStep={activeStep} tasks={tasks} onSelectStep={handleStepSelect} />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Content
              stepContent={stepContent}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              tasks={tasks}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Course;
