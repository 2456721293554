import React, { useState, useRef } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  ButtonGroup,
  Button,
  Grid,
  SvgIconTypeMap,
  PropTypes,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface IOption {
  title: string;
  icon?: OverridableComponent<SvgIconTypeMap>;
  disabled?: boolean;
}

interface IProps {
  defaultTitle: string;
  options: IOption[];
  onClick: (selectedOption: number) => void;
  color: PropTypes.Color;
  disabled?: boolean;
}

interface IOptionIconProps {
  icon: OverridableComponent<SvgIconTypeMap>;
}

const OptionIcon: React.FC<IOptionIconProps> = (props) => {
  const { icon: Icon } = props;
  return <Icon fontSize='small' />;
};

const SplitButton: React.FC<IProps> = (props) => {
  const { defaultTitle, color, options: providedOptions, onClick, disabled } = props;

  const [options] = useState([{ title: defaultTitle }, ...providedOptions]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = () => {
    onClick(selectedIndex);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction='column' alignItems='center'>
      <Grid item xs={12}>
        <ButtonGroup variant='contained' color={color} ref={anchorRef}>
          <Button disabled={selectedIndex === 0 || disabled} onClick={handleClick}>
            <Grid container spacing={2} alignItems='center'>
              {options[selectedIndex].icon && <OptionIcon icon={options[selectedIndex].icon!} />}
              <Grid item>{options[selectedIndex].title}</Grid>
            </Grid>
          </Button>
          <Button color={color} size='small' onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 5000 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.title}
                        selected={index === selectedIndex}
                        disabled={index === 0 || option.disabled}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Grid container spacing={2} alignItems='center'>
                          {option.icon && <OptionIcon icon={option.icon} />}
                          <Grid item>{option.title}</Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

export default SplitButton;
