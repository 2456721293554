import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './i18n';
import { SplashScreen } from 'components';
import { rootConfig } from './config';
import useAuth from 'hooks/useAuth';
import useScrollReset from 'hooks/useScrollReset';
import routes from './routes';

const App: React.FC = () => {
  const content = useRoutes(routes);
  const auth = useAuth();

  useScrollReset();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CssBaseline />
      {auth.isInitialized ? content : <SplashScreen />}
    </MuiPickersUtilsProvider>
  );
};

export default App;
