import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  colors,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import { ProjectEventTypeStaticContent } from 'app/enums/ProjectEventTypeStaticContent';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import moment from 'moment';
import { useMemo } from 'react';
import { StaticContent, TaskContent } from './components';
import SubjectIcon from '@material-ui/icons/Subject';
import Color from 'color';
import PlaceIcon from '@material-ui/icons/Place';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import ScheduleIcon from '@material-ui/icons/Schedule';

const useStyles = makeStyles((theme) => ({
  coursesRoot: {
    marginTop: theme.spacing(4),
  },
  placeholder: {
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingTop: theme.spacing(1),
  },
  iconContainer: {
    '& svg': {
      width: '100%',
      height: '100%',
    },
    height: 120,
    width: 120,

    padding: theme.spacing(3),
    borderRadius: '50%',
  },
  stepHeader: {
    paddingBottom: theme.spacing(1),
  },
  stepContent: {
    marginBottom: theme.spacing(3),
    height: '100%',
    padding: theme.spacing(2),
  },
}));

interface IProps {
  stepContent?: IProjectEvent;
  tasks: IProjectEvent[];
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const Content = ({ stepContent, tasks, activeStep, setActiveStep }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const nextDisabled = useMemo(() => {
    if (!tasks) return true;
    const currentStep = tasks.map((e) => e.id).indexOf(activeStep);

    return currentStep >= tasks.length - 1;
  }, [tasks, activeStep]);

  const backDisabled = useMemo(() => {
    if (!tasks) return true;
    const currentStep = tasks.map((e) => e.id).indexOf(activeStep);

    return currentStep <= 0;
  }, [tasks, activeStep]);

  const handleNext = () => {
    if (!tasks) return;

    const currentStep = tasks.map((e) => e.id).indexOf(activeStep);
    const newStep = tasks[currentStep >= tasks.length - 1 ? currentStep : currentStep + 1];

    setActiveStep(newStep.id!);
  };

  const handleBack = () => {
    if (!tasks) return;

    const currentStep = tasks.map((e) => e.id).indexOf(activeStep);
    const newStep = tasks[currentStep <= 0 ? currentStep : currentStep - 1];

    setActiveStep(newStep.id!);
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Card style={{ height: '100%', minHeight: '365px' }}>
        <CardHeader
          title={stepContent?.title || stepContent?.projectEventType?.title}
          className={classes.stepHeader}
          action={
            <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
              <Avatar
                style={{
                  backgroundColor: Color(theme.palette.primary.main).alpha(0.1).toString(),
                }}
              >
                <EventIcon style={{ color: theme.palette.primary.main }} />
              </Avatar>
              <Typography variant='h6'>
                {moment(stepContent?.start).format('DD MMMM YYYY')}
              </Typography>
            </Box>
          }
        />
        <CardContent className={classes.stepContent}>
          {stepContent?.projectEventType?.learnerPortalTimeDisplay && (
            <Box sx={{ mb: 2 }}>
              <Card variant='outlined'>
                <Box sx={{ p: 1 }}>
                  <Box style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                    <Avatar
                      style={{ backgroundColor: Color(colors.pink[500]).alpha(0.1).toString() }}
                    >
                      <ScheduleIcon style={{ color: colors.pink[500] }} />
                    </Avatar>

                    <Typography variant='h6'>
                      {moment(stepContent!.start).format('HH:mm')}
                      {stepContent!.end && `-${moment(stepContent!.end).format('HH:mm')}`}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}
          {stepContent?.venue && stepContent?.projectEventType?.learnerPortalVenueDisplay && (
            <Box sx={{ mb: 2 }}>
              <Card variant='outlined'>
                <Box sx={{ p: 1 }}>
                  <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    {stepContent.venue!.isVirtual ? (
                      <Avatar
                        style={{ backgroundColor: Color(colors.red[500]).alpha(0.1).toString() }}
                      >
                        <VideocamOutlinedIcon style={{ color: colors.red[500] }} />
                      </Avatar>
                    ) : (
                      <Avatar
                        style={{ backgroundColor: Color(colors.red[500]).alpha(0.1).toString() }}
                      >
                        <PlaceIcon style={{ color: colors.red[500] }} />
                      </Avatar>
                    )}
                    <Typography variant='h6'>{stepContent.venue!.name}</Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          )}

          {stepContent?.description && (
            <Box sx={{ mb: 2 }}>
              <Card variant='outlined'>
                <Box sx={{ p: 1 }}>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                      marginBottom: '7px',
                    }}
                  >
                    <Avatar
                      style={{ backgroundColor: Color(colors.orange[500]).alpha(0.1).toString() }}
                    >
                      <SubjectIcon style={{ color: colors.orange[500] }} />
                    </Avatar>

                    <Typography variant='h6'>Notes</Typography>
                  </Box>
                  <Typography variant='body1'>{stepContent.description}</Typography>
                </Box>
              </Card>
            </Box>
          )}
          {stepContent?.projectEventType?.learnerPortalStaticContent ===
            ProjectEventTypeStaticContent[ProjectEventTypeStaticContent.CourseUnit] && (
            <StaticContent projectEvent={stepContent} />
          )}
          {stepContent && <TaskContent projectEvent={stepContent} />}

          {/* {stepContent?.projectEventType?.expectedFiles?.map(
            (file: IAllocFileType, index: number) => (
              <Box key={uuid()}>
                <PropertyList>
                  <PropertyListItem
                    label={file.description!}
                    value={<input type='file' required={file.isRequired} />}
                  />
                </PropertyList>
              </Box>
            ),
          )} */}
        </CardContent>
      </Card>
      <Box
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: '-25px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Card>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gridGap: '10px',
              p: 1,
            }}
          >
            <Button onClick={handleBack} disabled={backDisabled}>
              Back
            </Button>
            <Typography variant='subtitle2' color='textSecondary'>
              {tasks.map((e) => e.id).indexOf(activeStep) + 1}/{tasks?.length ?? 1}
            </Typography>
            <Button onClick={handleNext} disabled={nextDisabled}>
              Next
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Content;
