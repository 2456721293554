import useAccessControl, { AccessControlParams } from 'hooks/useAccessControl';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface Props<T extends Record<string, any>> extends AccessControlParams<T> {}

const RouterAccessControl = <T extends Record<string, any>>(props: Props<T>) => {
  const permitted = useAccessControl({
    ...props,
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!permitted) {
      enqueueSnackbar('You are not permitted to view this page.', { variant: 'warning' });
      navigate('/');
    }
  }, [permitted, navigate, enqueueSnackbar]);

  if (!permitted) return null;

  return <Outlet />;
};

export default RouterAccessControl;
