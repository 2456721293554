import {
  Box,
  Card,
  CardActionArea,
  Collapse,
  LinearProgress,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import React, { useEffect, useMemo, useState } from 'react';
import Tasks from '../Tasks';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { Skeleton } from '@material-ui/lab';
import buildQuery, { Expand, Select } from 'odata-query';
import axios from 'utils/axios';
import { IODataResponse } from 'app/models/responses/IODataResponse';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { useSnackbar } from 'notistack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { SectionCompositeKey } from '../../Course';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import clsx from 'clsx';

interface IProps {
  compositeKey: SectionCompositeKey;
  tasks: IProjectEvent[];
  activeStep: number;
  onSelectStep: (step: number) => void;
}

const useStyles = makeStyles((theme) => ({
  activeSectionCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
}));

const Section = (props: IProps) => {
  const { compositeKey, tasks, activeStep, onSelectStep } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const sectionId = useMemo<number | null>(
    () => (compositeKey === 'standalone' ? null : Number(compositeKey.split('-')[1])),
    [compositeKey],
  );
  const [expanded, setExpanded] = useState(false);
  const [section, setSection] = useState<ILearnershipRevisionSection | null>(null);
  const activeTask = useMemo(() => tasks.find((e) => e.id === activeStep), [tasks, activeStep]);

  useEffect(() => {
    if (activeTask) {
      setExpanded(activeTask.parentContextId === sectionId);
    }
  }, [activeTask, sectionId]);

  useEffect(() => {
    let mounted = true;

    if (!sectionId) return undefined;

    (async () => {
      try {
        const expand: Expand<ILearnershipRevisionSection> = {};
        const query = buildQuery({ expand });
        const response = await axios.get<ILearnershipRevisionSection>(
          `${rootConfig.odataRoute}/learnershipRevisionSections(${sectionId})${query}`,
        );
        if (mounted) {
          setSection(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, sectionId]);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Box>
      {section && (
        <Card
          className={clsx(
            activeTask?.parentContextId === sectionId ? classes.activeSectionCard : undefined,
          )}
        >
          <CardActionArea onClick={handleExpand}>
            <Box sx={{ my: 1, mx: 2, display: 'flex', alignItems: 'center' }}>
              <Typography variant='subtitle2'>{section?.name}</Typography>
              <Box flexGrow={1} />
              {expanded ? (
                <ArrowUpwardIcon fontSize='small' style={{ color: theme.palette.text.secondary }} />
              ) : (
                <ArrowDownwardIcon
                  fontSize='small'
                  style={{ color: theme.palette.text.secondary }}
                />
              )}
            </Box>
          </CardActionArea>
        </Card>
      )}
      <Box sx={{ my: 1 }}>
        <Collapse in={expanded}>
          <Tasks activeStep={activeStep} tasks={tasks} onSelectStep={onSelectStep} />
        </Collapse>
      </Box>
    </Box>
  );
};

export default Section;
