import { useCallback, useState, useEffect, ChangeEvent, useMemo } from 'react';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import buildQuery from 'odata-query';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import useMounted from 'hooks/useMounted';
import { Link as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  GenericCommentsEditor,
  GenericDocumentsEditor,
  GenericHeader,
  SelectManyAsync,
  SelectOneAsync,
} from 'components/_dashboard';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { EntityContext } from 'app/enums/EntityContext';
import { IProject } from 'app/models/responses/IProject';
import { IEntityBase } from 'app/models/responses/IEntityBase';
import { ProjectLearnerStatus } from 'app/enums/ProjectLearnerStatus';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';

interface IProps {
  project: IProject;
  projectLearnerId: number;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const LearnerReplacement: React.FC<IProps> = (props) => {
  const { project, projectLearnerId, onClose, open, onConfirm } = props;
  const classes = useStyles();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [replacementLearnerId, setReplacementLearnerId] = useState(null);
  const [replacedProjectLearnerStatus, setReplacedProjectLearnerStatus] = useState<
    keyof typeof ProjectLearnerStatus | null
  >(null);
  const clientIds = useMemo(() => project.projectClients?.map((e) => e.clientId!) || [], [project]);
  const [clientId, setClientId] = useState<number>();

  useEffect(() => {
    if (clientIds.length === 1) {
      setClientId(clientIds[0]);
    }
  }, [clientIds]);

  const handleClose = () => {
    setReplacementLearnerId(null);
    setReplacedProjectLearnerStatus(null);
    setClientId(undefined);
    onClose();
  };

  const handleConfirm = async () => {
    if (!clientId) return;

    try {
      setSubmitting(true);
      const payload = {
        replacedProjectLearnerId: projectLearnerId,
        replacementLearnerId,
        replacedProjectLearnerStatus,
        clientId,
      };
      const response = await axios.post(
        `${rootConfig.odataRoute}/projectLearnerReplacements`,
        payload,
      );
      enqueueSnackbar('Successfully replaced learner.', { variant: 'success' });
      onConfirm();
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>Replace Learner</DialogTitle>
      <DialogContent>
        <Box>
          <FormControl required fullWidth variant='outlined'>
            <InputLabel>Reason</InputLabel>
            <Select
              value={replacedProjectLearnerStatus}
              label='Status'
              onChange={(event: React.ChangeEvent<any>) =>
                setReplacedProjectLearnerStatus(event.target.value)
              }
              name='status'
              variant='outlined'
            >
              {odataOptionsArrayFromEnum(ProjectLearnerStatus)
                .filter((e) => e.value !== ProjectLearnerStatus[ProjectLearnerStatus.Enrolled])
                .map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.display}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          <SelectOneAsync
            name='learnerId'
            entityId={replacementLearnerId || null}
            endPoint={`projects(${project.id!})/getAllowedLearners()`}
            entityType='learners'
            labelFields={['fullName', 'idNumber', 'email']}
            filterFields={['email', 'idNumber', 'firstName', 'lastName']}
            onChange={(event: any, value: any) => setReplacementLearnerId(value?.id || null)}
            label='Replacement Learner'
            required
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <SelectOneAsync
            name='clientId'
            entityId={clientId || null}
            filter={{
              ...(clientIds.length > 0 && {
                id: {
                  in: clientIds,
                },
              }),
            }}
            entityType='clients'
            labelFields={['name']}
            filterFields={['name']}
            onChange={(event: any, value: any) => setClientId(value?.id || null)}
            label='Client'
            disabled={clientIds.length === 1}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleConfirm}
          color='primary'
          disabled={
            !replacementLearnerId || isSubmitting || !clientId || !replacedProjectLearnerStatus
          }
        >
          Confirm
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LearnerReplacement;
