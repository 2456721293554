import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ILearner } from 'app/models/responses/ILearner';
import { ExceptionHandler } from 'components';
import {
  GenericAddressForm,
  GenericContactInfoForm,
  GenericNextOfKinForm,
  Page,
  SelectTextAsync,
} from 'components/_dashboard';
import { rootConfig } from 'config';
import useAuth from 'hooks/useAuth';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { General } from './components';
import { IContactInfo } from 'app/models/responses/IContactInfo';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ILearnerChangeDetailsRequest } from 'app/models/entities/ILearnerChangeDetailsRequest';

interface IProps {}

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ChangeDetails = (props: IProps) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const mounted = useMounted();
  const [learner, setLearner] = useState<ILearner | null>(null);

  const getLearner = useCallback(async () => {
    if (!user) return;

    try {
      const expand = {};
      const queryString = buildQuery({ expand });
      const response = await axios.get(
        `${rootConfig.odataRoute}/learners(${user.id})${queryString}`,
      );
      if (mounted.current) {
        setLearner(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, user, enqueueSnackbar]);

  useEffect(() => {
    getLearner();
  }, [getLearner]);

  if (!learner) return <LinearProgress />;

  return (
    <Page title='Course'>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={`/`} color='textPrimary'>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>Home</Typography>
          </Box>
        </Link>
      </Box>
      <Typography variant='h4'>Edit Details</Typography>
      <Typography variant='subtitle2' color='textSecondary'>
        Submit a request to update your personal details
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Formik
          initialValues={{
            emailAddress: learner.contactInfo?.emailAddress || '',
            workNumber: learner.contactInfo?.workNumber || '',
            homeNumber: learner.contactInfo?.homeNumber || '',
            cellNumber: learner.contactInfo?.cellNumber || '',

            physicalAddress: learner.address?.physicalAddress || '',
            physicalCity: learner.address?.physicalCity || '',
            physicalProvince: learner.address?.physicalProvince || '',
            physicalCode: learner.address?.physicalCode || '',
            physicalCountryName: learner.address?.physicalCountryName || '',
            postalAddress: learner.address?.postalAddress || '',
            postalCity: learner.address?.postalCity || '',
            postalProvince: learner.address?.postalProvince || '',
            postalCode: learner.address?.postalCode || '',
            postalCountryName: learner.address?.postalCountryName || '',
          }}
          validateOnChange
          validationSchema={Yup.object().shape({
            emailAddress: Yup.string(),
            workNumber: Yup.string(),
            homeNumber: Yup.string(),
            cellNumber: Yup.string(),
          })}
          onSubmit={async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm, setTouched },
          ) => {
            try {
              const updatedEntity: ILearnerChangeDetailsRequest = {
                address: {
                  ...learner.address,
                  physicalAddress: values.physicalAddress || '',
                  physicalCity: values.physicalCity || '',
                  physicalProvince: values.physicalProvince || '',
                  physicalCode: values.physicalCode || '',
                  physicalCountryName: values.physicalCountryName || '',
                  postalAddress: values.postalAddress || '',
                  postalCity: values.postalCity || '',
                  postalProvince: values.postalProvince || '',
                  postalCode: values.postalCode || '',
                  postalCountryName: values.postalCountryName || '',
                },
                contactInfo: {
                  ...learner.contactInfo,
                  emailAddress: values.emailAddress || '',
                  workNumber: values.workNumber || '',
                  homeNumber: values.homeNumber || '',
                  cellNumber: values.cellNumber || '',
                },
                learnerId: learner.id,
              };
              const expand = {};
              const queryString = buildQuery({ expand });
              await axios.post(
                `${rootConfig.odataRoute}/learnerChangeDetailsRequests${queryString}`,
                updatedEntity,
              );

              enqueueSnackbar(`Successfully submitted request.`, { variant: 'success' });
              setStatus({ success: true });
              navigate('/');
            } catch (error) {
              enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
              setStatus({ success: false });
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            isValid,
            dirty,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title='Address' />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                error={Boolean(touched.physicalAddress && errors.physicalAddress)}
                                fullWidth
                                helperText={touched.physicalAddress && errors.physicalAddress}
                                label='Physical Address'
                                name='physicalAddress'
                                onChange={handleChange}
                                value={values.physicalAddress}
                                variant='outlined'
                                multiline
                                maxRows={3}
                                minRows={3}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.physicalCity && errors.physicalCity)}
                                fullWidth
                                helperText={touched.physicalCity && errors.physicalCity}
                                label='Physical City'
                                name='physicalCity'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.physicalCity}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.physicalProvince && errors.physicalProvince)}
                                fullWidth
                                helperText={touched.physicalProvince && errors.physicalProvince}
                                label='Physical Province'
                                name='physicalProvince'
                                onChange={handleChange}
                                value={values.physicalProvince}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <SelectTextAsync
                                field='name'
                                name='physicalCountryName'
                                entityValue={values.physicalCountryName || ''}
                                endPoint='countries'
                                onChange={(_event, value) =>
                                  setFieldValue('physicalCountryName', value)
                                }
                                label='Country'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.physicalCode && errors.physicalCode)}
                                fullWidth
                                helperText={touched.physicalCode && errors.physicalCode}
                                label='Physical Code'
                                name='physicalCode'
                                onChange={handleChange}
                                value={values.physicalCode}
                                variant='outlined'
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                error={Boolean(touched.postalAddress && errors.postalAddress)}
                                fullWidth
                                helperText={touched.postalAddress && errors.postalAddress}
                                label='Postal Address'
                                name='postalAddress'
                                onChange={handleChange}
                                value={values.postalAddress}
                                variant='outlined'
                                multiline
                                maxRows={3}
                                minRows={3}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.postalCity && errors.postalCity)}
                                fullWidth
                                helperText={touched.postalCity && errors.postalCity}
                                label='Postal City'
                                name='postalCity'
                                onChange={handleChange}
                                value={values.postalCity}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.postalProvince && errors.postalProvince)}
                                fullWidth
                                helperText={touched.postalProvince && errors.postalProvince}
                                label='Postal Province'
                                name='postalProvince'
                                onChange={handleChange}
                                value={values.postalProvince}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <SelectTextAsync
                                field='name'
                                name='postalCountryName'
                                entityValue={values.postalCountryName || ''}
                                endPoint='countries'
                                onChange={(_event, value) =>
                                  setFieldValue('postalCountryName', value)
                                }
                                label='Country'
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                              <TextField
                                error={Boolean(touched.postalCode && errors.postalCode)}
                                fullWidth
                                helperText={touched.postalCode && errors.postalCode}
                                label='Postal Code'
                                name='postalCode'
                                onChange={handleChange}
                                value={values.postalCode}
                                variant='outlined'
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card>
                    <CardHeader title='Contact' />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={Boolean(touched.emailAddress && errors.emailAddress)}
                            fullWidth
                            helperText={touched.emailAddress && errors.emailAddress}
                            label='Secondary Email'
                            name='emailAddress'
                            onChange={handleChange}
                            value={values.emailAddress}
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={Boolean(touched.cellNumber && errors.cellNumber)}
                            fullWidth
                            helperText={touched.cellNumber && errors.cellNumber}
                            label='Cell Number'
                            name='cellNumber'
                            onChange={handleChange}
                            value={values.cellNumber}
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={Boolean(touched.homeNumber && errors.homeNumber)}
                            fullWidth
                            helperText={touched.homeNumber && errors.homeNumber}
                            label='Home Number'
                            name='homeNumber'
                            onChange={handleChange}
                            value={values.homeNumber}
                            variant='outlined'
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            error={Boolean(touched.workNumber && errors.workNumber)}
                            fullWidth
                            helperText={touched.workNumber && errors.workNumber}
                            label='Work Number'
                            name='workNumber'
                            onChange={handleChange}
                            value={values.workNumber}
                            variant='outlined'
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      color='primary'
                      disabled={isSubmitting || !isValid || !dirty}
                      type='submit'
                      variant='contained'
                      fullWidth
                      size='large'
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Page>
  );
};

export default ChangeDetails;
