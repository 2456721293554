import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { rootConfig } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    p: 3,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  image: {
    maxWidth: '90%',
    marginBottom: theme.spacing(3),
  },
}));

const SplashScreen: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img
        className={classes.image}
        src={`${rootConfig.staticFileRoot}/static/logos/core.svg`}
        alt='Loading'
      />
      <CircularProgress />
    </Box>
  );
};

export default SplashScreen;
