import ReactQuill from 'react-quill';
import 'quill-mention';
import React, { useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import buildQuery from 'odata-query';

import clsx from 'clsx';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import axios from 'utils/axios';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { useSnackbar } from 'notistack';
import { IODataResponse } from 'app/models/responses/IODataResponse';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .mention': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      padding: '2px 8px',
    },
    '& .ql-mention-list-item': {
      padding: theme.spacing(1),
      cursor: 'pointer',
    },
    '& .ql-mention-list-item:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ql-mention-list-item.selected': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ql-mention-denotation-char': {
      display: 'none',
    },
    '& .ql-mention-list-container': {
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      maxHeight: 250,
      minWidth: 150,
      overflowY: 'auto',
    },
    '& .ql-mention-list-container ul': {
      listStyleType: 'none !important',
      padding: 0,
    },
    border: 1,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    '& .ql-tooltip::before': {
      color: theme.palette.text.primary,
    },
    '& .ql-tooltip': {
      border: 'none',
      boxShadow: theme.shadows[1],
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      '& input[type=text]': {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
      },
      '& input[type=text]:focus': {
        outline: 'none',
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '& .ql-preview': {
        color: theme.palette.text.primary,
      },
      '& .ql-action': {
        color: theme.palette.primary.main,
      },
      '& .ql-remove': {
        color: theme.palette.secondary.main,
      },
    },
    '& .ql-snow.ql-toolbar': {
      borderColor: theme.palette.divider,
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      '& .ql-picker-label:hover': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-label.ql-active': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-item:hover': {
        color: theme.palette.primary.main,
      },
      '& .ql-picker-item.ql-selected': {
        color: theme.palette.primary.main,
      },
      '& button:hover': {
        color: theme.palette.primary.main,
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& button:focus': {
        color: theme.palette.primary.main,
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& button.ql-active': {
        '& .ql-stroke': {
          stroke: theme.palette.primary.main,
        },
      },
      '& .ql-stroke': {
        stroke: theme.palette.text.primary,
      },
      '& .ql-picker': {
        color: theme.palette.text.primary,
      },
      '& .ql-picker-options': {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[10],
        padding: theme.spacing(2),
      },
    },
    '& .ql-snow.ql-container': {
      borderBottom: 'none',
      borderColor: theme.palette.divider,
      borderLeft: 'none',
      borderRight: 'none',
      flexGrow: 1,
      '& .ql-editor': {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(2),
        '&.ql-blank::before': {
          color: theme.palette.text.secondary,
          fontStyle: 'normal',
          left: theme.spacing(2),
        },
      },
    },
  },
}));

interface IProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const RichTextInput: React.FC<IProps> = (props) => {
  const { value, onChange, className } = props;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const getAtOptions = useCallback(async () => {
    try {
      const select = ['id', 'userName'];
      const queryString = buildQuery({ select });
      const response = await axios.get<IODataResponse<IApplicationUser[]>>(
        `${rootConfig.odataRoute}/users${queryString}`,
      );
      return response.data.value!.map((user: IApplicationUser) => ({
        id: user.id,
        value: user.userName,
      }));
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const mention = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@', '#'],
    source: useCallback(
      async (searchTerm: any, renderList: any, mentionChar: any) => {
        let values: any = [];

        if (mentionChar === '@') {
          values = await getAtOptions();
        } else {
          values = [];
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i: number = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
      [getAtOptions],
    ),
  };

  return (
    <ReactQuill
      modules={{
        mention,
      }}
      value={value}
      onChange={onChange}
      className={clsx(className, classes.root)}
    />
  );
};

export default RichTextInput;
