import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  LinearProgress,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IProjectClient } from 'app/models/entities/IProjectClient';
import { IProject } from 'app/models/responses/IProject';
import React, { useEffect, useState } from 'react';
import { ProjectClientItem } from './components';
import { ODataGrid, SelectOneAsync } from 'components/_dashboard';
import axios from 'utils/axios';
import { IContact } from 'app/models/responses/IContact';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { useSnackbar } from 'notistack';
import buildQuery, { OrderBy } from 'odata-query';
import { IClient } from 'app/models/responses/IClient';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  accordionDetails: {
    padding: 0,
  },
}));

interface IProps {
  project: IProject;
  onProjectClientUpdate: (updatedProjectClient: IProjectClient) => void;
}

const Clients: React.FC<IProps> = (props: IProps) => {
  const { project, onProjectClientUpdate } = props;
  const classes = useStyles();
  const [clientLookup, setClientLookup] = useState<any>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const orderBy: OrderBy<IClient> = ['name asc'];
        const select = ['id', 'name'];
        const queryString = buildQuery({ select, orderBy });
        const response = await axios.get(`${rootConfig.odataRoute}/clients${queryString}`);
        if (mounted) {
          const clientLookups: any = {};
          response.data.value.forEach((client: IClient) => {
            clientLookups[client.id!] = `${client.name}`;
          });
          setClientLookup(clientLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  if (!clientLookup) return <LinearProgress />;

  return (
    <Box className={classes.root}>
      <ODataGrid<IProjectClient>
        entityType='projectClients'
        title='Add or Remove Clients'
        filter={{
          projectId: project.id,
        }}
        columns={[
          {
            field: 'id',
            hidden: true,
          },
          {
            field: 'projectId',
            title: 'Project',
            initialEditValue: project.id,
            hidden: true,
          },
          {
            field: 'clientId',
            title: 'Client',
            lookup: clientLookup,
            editComponent: (props) => (
              <SelectOneAsync
                name='clientId'
                orderBy={['name asc']}
                entityId={props.value || null}
                entityType='clients'
                labelFields={['name']}
                filterFields={['name']}
                onChange={(event: any, value: any) => props.onChange(value?.id)}
                label='Search client'
                required
                error={props.error}
              />
            ),
            validate: (rowData) =>
              !rowData.clientId ? { isValid: false, helperText: 'Client is required' } : true,
          },
        ]}
        canAdd
        canDelete
      />
      <Box sx={{ mt: 2 }}>
        {project.projectClients!.map((projectClient, index) => (
          <Accordion
            key={projectClient.id}
            expanded={project.projectClients!.length === 1 ? true : undefined}
          >
            <AccordionSummary expandIcon={project.projectClients!.length > 1 && <ExpandMoreIcon />}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant='h6'>{projectClient.client?.name} Details</Typography>
                </Grid>
                <Box flexGrow={1} />
                <Grid item>
                  <Chip
                    label={projectClient.status!.name}
                    variant='default'
                    color='primary'
                    size='small'
                    style={{ backgroundColor: projectClient.status!.color }}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <ProjectClientItem
                projectClient={projectClient}
                onUpdate={onProjectClientUpdate}
                project={project}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default Clients;
