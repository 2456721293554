import {
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { SelectOneAsync } from 'components/_dashboard';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import { IFolderItem } from 'app/models/responses/IFolderItem';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import buildQuery from 'odata-query';

interface IProps {
  folder: IFolderItem;
  open: boolean;
  onUpdate: (updatedFolder: IFolderItem) => void;
  onClose: () => void;
}

const FolderEdit: React.FC<IProps> = (props) => {
  const { folder, open, onUpdate, onClose } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [updatingFolder, setUpdatingFolder] = useState(false);
  const [name, setName] = useState<string>(folder.name! || '');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    setName(folder.name! || '');
    onClose();
  };

  const handleUpdate = async () => {
    try {
      setUpdatingFolder(true);

      const originalFolder = {
        ...folder,
      };
      const folderToUpdate = {
        ...folder,
        name,
      };
      const diff = jsonpatch.compare(originalFolder, folderToUpdate);
      const expand = {
        childFileItems: { top: 0, count: true },
        childFolderItems: { top: 0, count: true },
      };
      const queryString = buildQuery({ expand });
      const response = await axios.patch<IFolderItem>(
        `${rootConfig.odataRoute}/folderItems(${folder.id!})${queryString}`,
        diff,
      );
      const updatedFolder = response.data;
      setName(updatedFolder.name! || '');
      onUpdate(updatedFolder);
      enqueueSnackbar('Successfully updated folder.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setUpdatingFolder(false);
      onClose();
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>Edit Folder</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Name'
              name='name'
              onChange={(event: any) => setName(event.target.value)}
              value={name}
              variant='outlined'
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleUpdate}
          color='primary'
          disabled={!name || updatingFolder}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FolderEdit;
