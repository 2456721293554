import React, { createRef } from 'react';
import { Button, useMediaQuery, useTheme, makeStyles, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider as Snackbar } from 'notistack';
import { createCustomTheme } from 'theme';
import useSettings from 'hooks/useSettings';

interface IProps {
  children: React.ReactNode;
}

const SnackbarProvider: React.FC<IProps> = (props) => {
  const { children } = props;

  const theme = useTheme();

  const notistackRef: any = createRef();
  const handleSnackbarDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <Snackbar
      ref={notistackRef}
      style={{
        borderRadius: 16,
      }}
      action={(key) => (
        <Button
          onClick={handleSnackbarDismiss(key)}
          color='inherit'
          size='small'
          style={{ borderRadius: 16 }}
        >
          Dismiss
        </Button>
      )}
      dense
      maxSnack={2}
      anchorOrigin={{
        vertical: useMediaQuery(theme.breakpoints.down('xs')) ? 'bottom' : 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </Snackbar>
  );
};

export default SnackbarProvider;
