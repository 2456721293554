import {
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { SelectOneAsync } from 'components/_dashboard';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import { IFileItem } from 'app/models/responses/IFileItem';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import buildQuery from 'odata-query';

interface IProps {
  file: IFileItem;
  open: boolean;
  onUpdate: (updatedFile: IFileItem) => void;
  onClose: () => void;
}

const FileEdit: React.FC<IProps> = (props) => {
  const { file, open, onUpdate, onClose } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [updatingFile, setUpdatingFile] = useState(false);
  const [fileTypeId, setFileTypeId] = useState<number | null>(file.fileTypeId!);
  const [displayName, setDisplayName] = useState<string>(file.displayName! || '');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    setDisplayName(file.displayName! || '');
    setFileTypeId(file.fileTypeId!);
    onClose();
  };

  const handleUpdate = async () => {
    try {
      setUpdatingFile(true);

      const originalFile = {
        ...file,
      };
      const fileToUpdate = {
        ...file,
        displayName,
        fileTypeId,
      };
      const diff = jsonpatch.compare(originalFile, fileToUpdate);

      const expand = ['fileType'];
      const queryString = buildQuery({ expand });
      const response = await axios.patch<IFileItem>(
        `${rootConfig.odataRoute}/fileItems(${file.id!})${queryString}`,
        diff,
      );
      const updatedFile = response.data;
      setDisplayName(updatedFile.displayName! || '');
      setFileTypeId(updatedFile.fileTypeId!);
      onUpdate(updatedFile);
      enqueueSnackbar('Successfully updated file.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setUpdatingFile(false);
      onClose();
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose}>
      <DialogTitle>Edit File</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Display Name'
              name='displayName'
              onChange={(event: any) => setDisplayName(event.target.value)}
              value={displayName}
              variant='outlined'
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOneAsync
              name='fileTypeId'
              entityId={fileTypeId}
              entityType='fileTypes'
              labelFields={['name']}
              filterFields={['name']}
              filter={{ not: { id: { in: [1, 2, 3] } } }}
              onChange={(event: any, value: any) => setFileTypeId(value?.id || null)}
              label='File Type'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleUpdate}
          color='primary'
          disabled={!displayName || !fileTypeId || updatingFile}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileEdit;
