import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { SelectOneAsync, SelectTextAsync } from 'components/_dashboard';
import { IAddress } from 'app/models/responses/IAddress';

interface IProps {
  parentEntityType:
    | 'clients'
    | 'hostSites'
    | 'facilitators'
    | 'learners'
    | 'candidates'
    | 'mentors'
    | 'contacts'
    | 'venues';
  parentEntityId: number;
  address: IAddress | null;
  onAddressUpdate?: (updatedAddress: IAddress) => void;
}

const GenericAddressForm: React.FC<IProps> = (props) => {
  const { address, parentEntityType, parentEntityId, onAddressUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        physicalAddress: address?.physicalAddress || '',
        physicalCity: address?.physicalCity || '',
        physicalProvince: address?.physicalProvince || '',
        physicalCode: address?.physicalCode || '',
        physicalCountryName: address?.physicalCountryName || '',
        postalAddress: address?.postalAddress || '',
        postalCity: address?.postalCity || '',
        postalProvince: address?.postalProvince || '',
        postalCode: address?.postalCode || '',
        postalCountryName: address?.postalCountryName || '',
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            address,
          };
          const updatedEntity: any = {
            address: {
              ...address,
              ...values,
            },
          };
          delete updatedEntity.address['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedAddress: IAddress = response.data.address;
          resetForm({
            values: {
              physicalAddress: updatedAddress?.physicalAddress || '',
              physicalCity: updatedAddress?.physicalCity || '',
              physicalProvince: updatedAddress?.physicalProvince || '',
              physicalCode: updatedAddress?.physicalCode || '',
              physicalCountryName: updatedAddress?.physicalCountryName || '',
              postalAddress: updatedAddress?.postalAddress || '',
              postalCity: updatedAddress?.postalCity || '',
              postalProvince: updatedAddress?.postalProvince || '',
              postalCode: updatedAddress?.postalCode || '',
              postalCountryName: updatedAddress?.postalCountryName || '',
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated address.`, { variant: 'success' });
          setStatus({ success: true });
          onAddressUpdate && onAddressUpdate(updatedAddress);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Address' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.physicalAddress && errors.physicalAddress)}
                        fullWidth
                        helperText={touched.physicalAddress && errors.physicalAddress}
                        label='Physical Address'
                        name='physicalAddress'
                        onChange={handleChange}
                        value={values.physicalAddress}
                        variant='outlined'
                        multiline
                        maxRows={3}
                        minRows={3}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.physicalCity && errors.physicalCity)}
                        fullWidth
                        helperText={touched.physicalCity && errors.physicalCity}
                        label='Physical City'
                        name='physicalCity'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.physicalCity}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.physicalProvince && errors.physicalProvince)}
                        fullWidth
                        helperText={touched.physicalProvince && errors.physicalProvince}
                        label='Physical Province'
                        name='physicalProvince'
                        onChange={handleChange}
                        value={values.physicalProvince}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <SelectTextAsync
                        field='name'
                        name='physicalCountryName'
                        entityValue={values.physicalCountryName || ''}
                        endPoint='countries'
                        onChange={(_event, value) => setFieldValue('physicalCountryName', value)}
                        label='Country'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.physicalCode && errors.physicalCode)}
                        fullWidth
                        helperText={touched.physicalCode && errors.physicalCode}
                        label='Physical Code'
                        name='physicalCode'
                        onChange={handleChange}
                        value={values.physicalCode}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.postalAddress && errors.postalAddress)}
                        fullWidth
                        helperText={touched.postalAddress && errors.postalAddress}
                        label='Postal Address'
                        name='postalAddress'
                        onChange={handleChange}
                        value={values.postalAddress}
                        variant='outlined'
                        multiline
                        maxRows={3}
                        minRows={3}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.postalCity && errors.postalCity)}
                        fullWidth
                        helperText={touched.postalCity && errors.postalCity}
                        label='Postal City'
                        name='postalCity'
                        onChange={handleChange}
                        value={values.postalCity}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.postalProvince && errors.postalProvince)}
                        fullWidth
                        helperText={touched.postalProvince && errors.postalProvince}
                        label='Postal Province'
                        name='postalProvince'
                        onChange={handleChange}
                        value={values.postalProvince}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <SelectTextAsync
                        field='name'
                        name='postalCountryName'
                        entityValue={values.postalCountryName || ''}
                        endPoint='countries'
                        onChange={(_event, value) => setFieldValue('postalCountryName', value)}
                        label='Country'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12}>
                      <TextField
                        error={Boolean(touched.postalCode && errors.postalCode)}
                        fullWidth
                        helperText={touched.postalCode && errors.postalCode}
                        label='Postal Code'
                        name='postalCode'
                        onChange={handleChange}
                        value={values.postalCode}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericAddressForm;
