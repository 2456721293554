import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import { CircularProgress, TextField, useTheme } from '@material-ui/core/';
import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import buildQuery, { Expand, Filter, OrderBy } from 'odata-query';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { ExceptionHandler } from 'components';
import { IEntityBase } from 'app/models/responses/IEntityBase';
import { rootConfig } from 'config';
import deepCopy from 'utils/deepCopy';
import _ from 'lodash';
import { IODataResponse } from 'app/models/responses/IODataResponse';

interface IProps<T> {
  entityType: string;
  endPoint?: string;
  entityId: number | null;
  labelFields: string[];
  filterFields: string[];
  selectFields?: string[];
  onChange: (event: React.ChangeEvent<{}>, value: T | null) => void;
  name: string;
  label?: React.ReactNode;
  filter?: Filter;
  required?: boolean;
  disabled?: boolean;
  orderBy?: OrderBy<any>;
  error?: boolean;
  top?: number;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
  placeholder?: string;
  expand?: Expand<T>;
  renderOption?: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
}

const SelectOneAsync = <T extends IEntityBase>(props: IProps<T>) => {
  const {
    name,
    entityType,
    endPoint,
    entityId,
    filter: providedFilter,
    onChange,
    labelFields,
    selectFields,
    label,
    required,
    disabled,
    error,
    orderBy,
    filterFields,
    size,
    style,
    placeholder,
    expand,
    top: providedTop,
    renderOption,
  } = props;

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[] | null>(null);
  const [optionValue, setOptionValue] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options === null;

  useEffect(() => {
    let mounted = true;

    if (!entityId) {
      if (mounted) {
        setOptionValue(null);
      }
      return undefined;
    }

    (async () => {
      try {
        const select = (selectFields || labelFields).concat('id');
        const queryString = buildQuery({ select });
        const response = await axios.get<T>(
          `${rootConfig.odataRoute}/${entityType}(${entityId})${queryString}`,
        );
        if (mounted) {
          setOptionValue(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, entityType, entityId, labelFields, selectFields]);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const top = providedTop || 250;
        const select = (selectFields || labelFields).concat('id');

        let filter: any = [{ or: [] }];
        filterFields.forEach((field) => {
          filter[0].or.push({
            [field]: { contains: inputValue },
          });
        });
        filter.push(providedFilter);
        const queryString = buildQuery({ select, filter, orderBy, top, expand });
        const response = await axios.get<IODataResponse<T[]>>(
          `${rootConfig.odataRoute}/${endPoint || entityType}${queryString}`,
        );
        if (mounted) {
          setOptions(response.data.value || []);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [
    loading,
    enqueueSnackbar,
    entityType,
    labelFields,
    providedFilter,
    orderBy,
    inputValue,
    providedTop,
    filterFields,
    expand,
    selectFields,
  ]);

  useEffect(() => {
    if (!open) {
      setOptions(null);
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<{}>, value: T | null) => {
    setOptionValue(value);
    onChange(event, value);
  };

  const handleOptionLabelBuild = (option: T) => {
    let label = '';

    for (let i = 0; i < labelFields.length; i++) {
      if (i === 0) {
        label += option[labelFields[i]];
      } else {
        label += ` | ${option[labelFields[i]]}`;
      }
    }

    return label;
  };

  const handleInput = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(value);
  };

  return (
    <Autocomplete<T>
      style={style}
      size={size}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => handleOptionLabelBuild(option)}
      renderOption={(option, state) => (
        <li>{(renderOption && renderOption(option, state)) || handleOptionLabelBuild(option)}</li>
      )}
      options={options || []}
      loading={loading}
      disabled={disabled || false}
      value={optionValue}
      onChange={handleChange}
      onInputChange={handleInput}
      inputValue={inputValue}
      popupIcon={<ArrowDropDownIcon style={{ fill: theme.palette.text.secondary }} />}
      closeIcon={<CloseIcon style={{ fill: theme.palette.text.secondary }} />}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          placeholder={placeholder}
          error={error || false}
          required={required}
          variant='outlined'
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

SelectOneAsync.defaultProps = {
  filter: {},
};

export default SelectOneAsync;
