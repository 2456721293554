import { forwardRef } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import { Box } from '@material-ui/core';

interface IProps extends ScrollBarProps {
  children: React.ReactNode;
}

const Scrollbar: React.FC<IProps> = forwardRef((props, ref: any) => {
  const { children, ...other } = props;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  if (isMobile) {
    return (
      <Box
        /* @ts-ignore */
        ref={ref}
        style={{ height: '100%' }}
      >
        {children}
      </Box>
    );
  }

  return (
    <PerfectScrollbar ref={ref} {...other}>
      {children}
    </PerfectScrollbar>
  );
});

export default Scrollbar;
