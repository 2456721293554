import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

interface IProps {
  children: React.ReactNode;
}

const GuestGuard: React.FC<IProps> = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to='/dashboard/account' />;
  }

  return <>{children}</>;
};

export default GuestGuard;
