const variableCaseToSentence = (text: string) => {
  let sentence =
    text
      .match(/[a-z]+|[^a-z]+/gi)
      ?.map((word) => word.replace(/([A-Z])/g, ' $1'))
      .join(' ') || '';
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export default variableCaseToSentence;
