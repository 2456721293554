import { colors, makeStyles, Typography } from '@material-ui/core';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor:
      //@ts-ignore
      theme.palette.mode === 'dark' ? '#1F2937' : colors.grey[100],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    padding: theme.spacing(1),
  },
  text: {
    '& span': {
      fontWeight: 700,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

interface IProps {
  message: string;
  className?: string;
}

const Tip: React.FC<IProps> = (props) => {
  const { message, className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <EmojiObjectsIcon className={classes.icon} fontSize='small' />
      <Typography color='textSecondary' className={classes.text} variant='caption'>
        <span>Tip.</span> {message}
      </Typography>
    </div>
  );
};

export default Tip;
