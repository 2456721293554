import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  LinearProgress,
  Typography,
  colors,
  makeStyles,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import Color from 'color';
import { rootConfig } from 'config';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import getFileUrl from 'utils/getFileUrl';
import variableCaseToSentence from 'utils/variableCaseToSentence';

interface IStyleProps {
  courseColor: string;
}

const useStyles = (props: IStyleProps) =>
  makeStyles((theme) => ({
    root: {},
    content: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1.5),
    },
    placeholder: {
      height: 160,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconContainer: {
      '& svg': {
        color: props.courseColor,
        height: '100%',
        width: '100%',
      },
      backgroundColor: props.courseColor, //Color(props.courseColor).alpha(0.1).toString(),
      borderRadius: '50%',
      height: 120,
      width: 120,
      padding: theme.spacing(3),
    },
    coverImage: {
      borderRadius: theme.shape.borderRadius,
      padding: '6px',
      width: '100%',
      height: '150px',

      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  }));

interface IProps {
  enrolment: IProjectLearner;
}

const Course: React.FC<IProps> = ({ enrolment }: IProps) => {
  const courseColor = useMemo(() => {
    const courseType = enrolment.project!.courseRevision!.course!.type!;
    switch (courseType) {
      case 'SkillsProgramme':
        return colors.green[500];
      case 'ShortCourse':
        return colors.amber[500];
      case 'Learnership':
      default:
        return colors.blue[500];
    }
  }, [enrolment]);
  const classes = useStyles({ courseColor })();
  const coverUrl = useMemo(
    () =>
      enrolment.project?.courseRevision?.avatarFileItem
        ? getFileUrl(enrolment.project.courseRevision.avatarFileItem)
        : `${rootConfig.avatarFallbackRoute}/shapes/svg?seed=${
            enrolment.project?.courseRevision?.course?.id ?? 'cover'
          }`,
    [enrolment],
  );

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={`/learn/course/${enrolment.id}`}>
        <Box sx={{ p: 1 }}>
          <Box style={{ backgroundImage: `url(${coverUrl})` }} className={classes.coverImage} />
        </Box>

        <CardContent className={classes.content}>
          <Typography variant='subtitle1'>
            {enrolment.project!.courseRevision!.course!.name}
          </Typography>
          <Typography variant='subtitle2' color='textSecondary'>
            {variableCaseToSentence(enrolment.project!.courseRevision!.course!.type!)}
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '4px', marginTop: '2px' }}>
            <ScheduleIcon style={{ fontSize: '14px', marginTop: '-2px' }} />
            <Typography variant='caption' color='textSecondary'>
              {enrolment.project!.courseRevision!.durationInDays} day(s)
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mr: -1 }}>
            <Box flexGrow={1} />
            <Button endIcon={<ArrowForwardIcon />}>Continue</Button>
          </Box>
        </CardContent>
        {/* <LinearProgress variant='determinate' value={40} /> */}
      </CardActionArea>
    </Card>
  );
};

export default Course;
