import { IFileItem } from 'app/models/responses/IFileItem';
import { FileContentType } from 'app/types/data';
import ImageIcon from '@material-ui/icons/Image';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export class ApolloUtil {
  static getFileContentType = (file: IFileItem): FileContentType => {
    const mimeType = (file.contentType ?? '').toLowerCase();
    if (mimeType.startsWith('image/')) {
      return 'image';
    } else {
      return 'document'; // Default to 'document' if none of the above
    }
  };

  static getFileContentIcon = (file: IFileItem) => {
    const fileContentType = this.getFileContentType(file);
    switch (fileContentType) {
      case 'image':
        return ImageIcon;
      case 'document':
      default:
        return AttachFileIcon;
    }
  };

  static enumKey<T>(enumObj: T, value: T[keyof T]): keyof T {
    // Find the enum key by its value and return it as keyof typeof the enum
    const key = Object.keys(enumObj as any).find((key) => (enumObj as any)[key] === value);
    if (!key) {
      throw new Error('Invalid enum value provided.');
    }
    return key as keyof T;
  }
}
