import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
  Box,
  FormControlLabel,
  Typography,
  Switch,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { ITransportInfo } from 'app/models/responses/ITransportInfo';

interface IProps {
  parentEntityType: 'facilitators';
  parentEntityId: number;
  transportInfo: ITransportInfo | null;
  onTransportInfoUpdate?: (updatedTransportInfo: ITransportInfo) => void;
}

const GenericTransportInfoForm: React.FC<IProps> = (props) => {
  const { transportInfo, parentEntityId, parentEntityType, onTransportInfoUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        make: transportInfo?.make || '',
        model: transportInfo?.model || '',
        color: transportInfo?.color || '',
        registrationNumber: transportInfo?.registrationNumber || '',
        hasOwnTransport: transportInfo?.hasOwnTransport || false,
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        make: Yup.string(),
        model: Yup.string(),
        color: Yup.string(),
        registrationNumber: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            transportInfo,
          };
          const updatedEntity: any = {
            transportInfo: {
              ...transportInfo,
              ...values,
            },
          };
          delete updatedEntity.transportInfo['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedTransportInfo: ITransportInfo = response.data.transportInfo;
          resetForm({
            values: {
              make: updatedTransportInfo?.make || '',
              model: updatedTransportInfo?.model || '',
              color: updatedTransportInfo?.color || '',
              registrationNumber: updatedTransportInfo?.registrationNumber || '',
              hasOwnTransport: updatedTransportInfo?.hasOwnTransport || false,
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated transport info.`, { variant: 'success' });
          setStatus({ success: true });
          onTransportInfoUpdate && onTransportInfoUpdate(updatedTransportInfo);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Transport' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ px: 2 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.hasOwnTransport}
                          color='secondary'
                          edge='start'
                          name='hasOwnTransport'
                          onChange={(event) =>
                            setFieldValue('hasOwnTransport', event.target.checked)
                          }
                        />
                      }
                      label={
                        <div>
                          <Typography variant='subtitle1'>Has Own Transport?</Typography>
                          <Typography color='textSecondary' variant='caption' component='p'>
                            Indicate whether or not this individual has access to their own
                            transport.
                          </Typography>
                        </div>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.make && errors.make)}
                    fullWidth
                    helperText={touched.make && errors.make}
                    label='Make'
                    name='make'
                    onChange={handleChange}
                    value={values.make}
                    variant='outlined'
                    disabled={!values.hasOwnTransport}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.model && errors.model)}
                    fullWidth
                    helperText={touched.model && errors.model}
                    label='Model'
                    name='model'
                    onChange={handleChange}
                    value={values.model}
                    variant='outlined'
                    disabled={!values.hasOwnTransport}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.color && errors.color)}
                    fullWidth
                    helperText={touched.color && errors.color}
                    label='Color'
                    name='color'
                    onChange={handleChange}
                    value={values.color}
                    variant='outlined'
                    disabled={!values.hasOwnTransport}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.registrationNumber && errors.registrationNumber)}
                    fullWidth
                    helperText={touched.registrationNumber && errors.registrationNumber}
                    label='Registration Number'
                    name='registrationNumber'
                    onChange={handleChange}
                    value={values.registrationNumber}
                    variant='outlined'
                    disabled={!values.hasOwnTransport}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericTransportInfoForm;
