import { createSlice } from '@reduxjs/toolkit';
import { ProjectEventType } from 'app/enums/ProjectEventType';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import moment from 'moment';
import buildQuery from 'odata-query';
import axios from 'utils/axios';

interface IInitialState {
  projectEvents: any[];
  isModalOpen: boolean;
  selectedProjectEventId: any;
  selectedRange: any;
}

const initialState: IInitialState = {
  projectEvents: [],
  isModalOpen: false,
  selectedProjectEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'projectEventsCalendar',
  initialState,
  reducers: {
    getProjectEvents(state, action) {
      state.projectEvents = action.payload;
    },
    createProjectEvent(state, action) {
      state.projectEvents.push(action.payload);
    },
    selectProjectEvent(state, action) {
      state.isModalOpen = true;
      state.selectedProjectEventId = action.payload;
    },
    updateProjectEvent(state, action) {
      const projectEvent = action.payload;

      state.projectEvents = state.projectEvents.map((_projectEvent) => {
        if (_projectEvent.id === projectEvent.id) {
          return projectEvent;
        }

        return _projectEvent;
      });
    },
    deleteProjectEvent(state, action) {
      state.projectEvents = state.projectEvents.filter(
        (projectEvent) => projectEvent.id !== action.payload,
      );
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedProjectEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

const mapProjectEvent = (projectEvent: IProjectEvent) => {
  return {
    ...projectEvent,
    color: projectEvent.projectEventType!.color,
    title: projectEvent.title || projectEvent.projectEventType!.title,
    start: projectEvent.start!,
  };
};

export const getProjectEvents = (projectId: number) => async (dispatch: any) => {
  const filter = { projectId: projectId };
  const expand = ['project', 'projectEventType'];
  const queryString = buildQuery({ expand, filter });
  const response = await axios.get(`${rootConfig.odataRoute}/projectEvents${queryString}`);

  const projectEvents = response.data.value.map((e: IProjectEvent) => mapProjectEvent(e));

  dispatch(slice.actions.getProjectEvents(projectEvents));
};

export const createProjectEvent = (createData: IProjectEvent) => async (dispatch: any) => {
  const expand = ['project', 'projectEventType'];
  const queryString = buildQuery({ expand });
  const response = await axios.post<IProjectEvent>(
    `${rootConfig.odataRoute}/projectEvents${queryString}`,
    createData,
  );

  const newProjectEvent = mapProjectEvent(response.data);

  dispatch(slice.actions.createProjectEvent(newProjectEvent));
};

export const selectProjectEvent = (projectEventId: any) => async (dispatch: any) => {
  dispatch(slice.actions.selectProjectEvent(projectEventId));
};

export const updateProjectEvent =
  (oldProjectEvent: any, updatedProjectEvent: any) => async (dispatch: any) => {
    const expand = ['project', 'projectEventType'];
    const queryString = buildQuery({ expand });

    try {
      delete oldProjectEvent['submit'];
    } catch {}

    oldProjectEvent.start = (oldProjectEvent.start as Date).toLocaleString();
    oldProjectEvent.end = (oldProjectEvent.end as Date)?.toLocaleString();
    updatedProjectEvent.start = (updatedProjectEvent.start as Date).toLocaleString();
    updatedProjectEvent.end = (updatedProjectEvent.end as Date)?.toLocaleString();

    const diff = jsonpatch.compare(oldProjectEvent, updatedProjectEvent);

    try {
      const response = await axios.patch<IProjectEvent>(
        `${rootConfig.odataRoute}/projectEvents(${oldProjectEvent.id})${queryString}`,
        diff,
      );
      const _updatedProjectEvent = mapProjectEvent(response.data);
      dispatch(slice.actions.updateProjectEvent(_updatedProjectEvent));
    } catch (error) {
      throw error;
    }
  };

export const deleteProjectEvent = (projectEventId: any) => async (dispatch: any) => {
  // eslint-disable-next-line
  const response = await axios.delete(`${rootConfig.odataRoute}/projectEvents(${projectEventId})`);

  dispatch(slice.actions.deleteProjectEvent(projectEventId));
};

export const selectRange = (start: any, end: any) => (dispatch: any) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = () => (dispatch: any) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch: any) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
