import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import buildQuery from 'odata-query';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { useSnackbar } from 'notistack';
import * as jsonpatch from 'fast-json-patch';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import RateReviewIcon from '@material-ui/icons/RateReview';
import { IProject } from 'app/models/responses/IProject';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import useMounted from 'hooks/useMounted';
import { IProjectMilestoneFeedback } from 'app/models/responses/IProjectMilestoneFeedback';
import { ExceptionHandler } from 'components';
import { IProjectMilestoneFeedbackItem } from 'app/models/responses/IProjectMilestoneFeedbackItem';
import deepCopy from 'utils/deepCopy';
import { IProjectLearnerMilestone } from 'app/models/responses/IProjectLearnerMilestone';
import { EntityContext } from 'app/enums/EntityContext';
import { Alert } from '@material-ui/lab';

interface IProps {
  project: IProject;
  section?: ILearnershipRevisionSection;
}

const EditProjectFeedback: React.FC<IProps> = (props) => {
  const { project, section } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);
  const mounted = useMounted();
  const [projectMilestoneFeedbacks, setProjectMilestoneFeedbacks] = useState<
    IProjectMilestoneFeedback[]
  >([]);
  const [projectMilestoneFeedbackItems, setProjectMilestoneFeedbackItems] = useState<
    IProjectMilestoneFeedbackItem[] | null
  >(null);
  const [formStates, setFormStates] = useState<IProjectMilestoneFeedbackItem[]>([]);

  const getProjectMilestoneFeedbacks = useCallback(async () => {
    try {
      const response = await axios.get(`${rootConfig.odataRoute}/projectMilestoneFeedbacks`);
      if (mounted.current) {
        setProjectMilestoneFeedbacks(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar]);

  useEffect(() => {
    getProjectMilestoneFeedbacks();
  }, [getProjectMilestoneFeedbacks]);

  const handleClose = () => {
    setOpen(false);
  };

  const getProjectMilestoneFeedbackItems = useCallback(async () => {
    if (projectMilestoneFeedbacks.length === 0) return;

    try {
      let filter: any = { projectId: project.id };
      if (section) {
        filter['parentContextId'] = section.id;
      }

      const queryString = buildQuery({ filter });
      const response = await axios.get(
        `${rootConfig.odataRoute}/projectMilestoneFeedbackItems${queryString}`,
      );

      if (mounted.current) {
        setProjectMilestoneFeedbackItems(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, projectMilestoneFeedbacks]);

  useEffect(() => {
    getProjectMilestoneFeedbackItems();
  }, [getProjectMilestoneFeedbackItems]);

  useEffect(() => {
    if (projectMilestoneFeedbackItems) {
      setFormStates([...projectMilestoneFeedbackItems]);
    }
  }, [projectMilestoneFeedbackItems]);

  const handleSave = () => {
    try {
      let updatedFeedbackItems: IProjectMilestoneFeedbackItem[] = [];
      formStates!.forEach(async (feedbackItem) => {
        if (feedbackItem.id) {
          const oldFeedbackItem = (
            deepCopy(projectMilestoneFeedbackItems!) as IProjectMilestoneFeedbackItem[]
          ).find((e) => e.id === feedbackItem.id)!;
          const diff = jsonpatch.compare(oldFeedbackItem, feedbackItem);
          const response = await axios.patch<IProjectMilestoneFeedbackItem>(
            `${rootConfig.odataRoute}/projectMilestoneFeedbackItems(${feedbackItem.id})`,
            diff,
          );

          updatedFeedbackItems = [...updatedFeedbackItems].concat(response.data);
          setProjectMilestoneFeedbackItems(updatedFeedbackItems);
        } else {
          const response = await axios.post<IProjectMilestoneFeedbackItem>(
            `${rootConfig.odataRoute}/projectMilestoneFeedbackItems`,
            feedbackItem,
          );
          updatedFeedbackItems = [...updatedFeedbackItems].concat(response.data);
          setProjectMilestoneFeedbackItems(updatedFeedbackItems);
        }
      });

      enqueueSnackbar('Successfully updated feedback.', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating feedback. Please try again later.', {
        variant: 'error',
      });
    } finally {
      setOpen(false);
    }
  };

  const handleChange = (
    feedbackItem: IProjectMilestoneFeedbackItem | null | undefined,
    feedbackQuestion: IProjectMilestoneFeedback,
    value: string,
  ) => {
    if (feedbackItem) {
      const updatedFormStates = [...formStates];
      const updatedFeedbackItem: IProjectMilestoneFeedbackItem = {
        ...feedbackItem,
        feedback: value,
      };
      updatedFormStates[updatedFormStates.indexOf(feedbackItem)] = updatedFeedbackItem;
      setFormStates(updatedFormStates);
    } else {
      const newFeedbackItem: IProjectMilestoneFeedbackItem = {
        feedback: value,
        projectMilestoneFeedbackId: feedbackQuestion.id,
        projectId: project.id,
        parentContext: EntityContext[
          EntityContext.LearnershipRevisionSection
        ] as keyof typeof EntityContext,
        parentContextId: section?.id,
      };
      setFormStates((formStates) => [...formStates].concat(newFeedbackItem));
    }
  };

  return (
    <>
      <Tooltip title='Manage feedback.'>
        <IconButton onClick={() => setOpen(true)} color='secondary'>
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth='lg' fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{section ? section.name : 'Project'} Feedback</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems='center'>
            {projectMilestoneFeedbacks.map((feedbackQuestion) => {
              const feedbackItem = formStates?.find(
                (e) => e.projectMilestoneFeedbackId === feedbackQuestion.id,
              );
              return feedbackQuestion.isActive || feedbackItem?.feedback ? (
                <Fragment key={feedbackQuestion.id}>
                  <Grid item xs={12} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2'>{feedbackQuestion.title}</Typography>
                    {!feedbackQuestion.isActive && (
                      <Tooltip
                        title='This feedback has since been set to not required. Clearing the response in
                      the text box will remove this feedback item from this project.'
                      >
                        <WarningIcon
                          color='secondary'
                          style={{ cursor: 'help', marginLeft: theme.spacing(1) }}
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      label='Feedback'
                      name='feedback'
                      variant='outlined'
                      multiline
                      value={feedbackItem?.feedback || ''}
                      minRows={3}
                      maxRows={5}
                      onChange={(event) =>
                        handleChange(feedbackItem, feedbackQuestion, event.target.value)
                      }
                    />
                  </Grid>
                </Fragment>
              ) : (
                <></>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSave} color='primary'>
            Save
          </Button>
          <Button variant='outlined' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProjectFeedback;
