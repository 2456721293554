import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { IBankingInfo } from 'app/models/responses/IBankingInfo';

interface IProps {
  parentEntityType: 'facilitators';
  parentEntityId: number;
  bankingInfo: IBankingInfo | null;
  onBankingInfoUpdate?: (updatedBankingInfo: IBankingInfo) => void;
}

const GenericBankingInfoForm: React.FC<IProps> = (props) => {
  const { bankingInfo, parentEntityId, parentEntityType, onBankingInfoUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        accountHolderName: bankingInfo?.accountHolderName || '',
        accountType: bankingInfo?.accountType || '',
        accountNumber: bankingInfo?.accountNumber || '',
        bankName: bankingInfo?.bankName || '',
        branchName: bankingInfo?.branchName || '',
        branchCode: bankingInfo?.branchCode || '',
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        accountHolderName: Yup.string(),
        accountType: Yup.string(),
        accountNumber: Yup.string(),
        bankName: Yup.string(),
        branchName: Yup.string(),
        branchCode: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            bankingInfo,
          };
          const updatedEntity: any = {
            bankingInfo: {
              ...bankingInfo,
              ...values,
            },
          };
          delete updatedEntity.bankingInfo['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedBankingInfo: IBankingInfo = response.data.bankingInfo;
          resetForm({
            values: {
              accountHolderName: updatedBankingInfo?.accountHolderName || '',
              accountType: updatedBankingInfo?.accountType || '',
              accountNumber: updatedBankingInfo?.accountNumber || '',
              bankName: updatedBankingInfo?.bankName || '',
              branchName: updatedBankingInfo?.branchName || '',
              branchCode: updatedBankingInfo?.branchCode || '',
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated banking info.`, { variant: 'success' });
          setStatus({ success: true });
          onBankingInfoUpdate && onBankingInfoUpdate(updatedBankingInfo);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Banking' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.accountHolderName && errors.accountHolderName)}
                    fullWidth
                    helperText={touched.accountHolderName && errors.accountHolderName}
                    label='Account Holder Name'
                    name='accountHolderName'
                    onChange={handleChange}
                    value={values.accountHolderName}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.accountType && errors.accountType)}
                    fullWidth
                    helperText={touched.accountType && errors.accountType}
                    label='Account Type'
                    name='accountType'
                    onChange={handleChange}
                    value={values.accountType}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.accountNumber && errors.accountNumber)}
                    fullWidth
                    helperText={touched.accountNumber && errors.accountNumber}
                    label='Account Number'
                    name='accountNumber'
                    onChange={handleChange}
                    value={values.accountNumber}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.bankName && errors.bankName)}
                    fullWidth
                    helperText={touched.bankName && errors.bankName}
                    label='Bank Name'
                    name='bankName'
                    onChange={handleChange}
                    value={values.bankName}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.branchName && errors.branchName)}
                    fullWidth
                    helperText={touched.branchName && errors.branchName}
                    label='Branch Name'
                    name='branchName'
                    onChange={handleChange}
                    value={values.branchName}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.branchCode && errors.branchCode)}
                    fullWidth
                    helperText={touched.branchCode && errors.branchCode}
                    label='Branch Code'
                    name='branchCode'
                    onChange={handleChange}
                    value={values.branchCode}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericBankingInfoForm;
