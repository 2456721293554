import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import HomeIcon from '@material-ui/icons/Home';
import TuneIcon from '@material-ui/icons/Tune';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChatIcon from '@material-ui/icons/Chat';
import CodeIcon from '@material-ui/icons/Code';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MenuIcon from '@material-ui/icons/Menu';
import SecurityIcon from '@material-ui/icons/Security';
import { UserType } from 'app/enums/UserType';
import { Scrollbar } from 'components';
import { NavSection } from 'components/_dashboard';
import { rootConfig } from 'config';
import useAuth from 'hooks/useAuth';
import { TFunction, useTranslation } from 'react-i18next';
import SchoolIcon from '@material-ui/icons/School';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

const getSections = (t: TFunction<'translation'>) => [
  {
    title: t('Pages'),
    items: [
      {
        title: t('Home'),
        path: '/',
        icon: HomeIcon,
      },
      {
        title: t('Recruitment and Hosting'),
        path: '/recruitment-and-hosting',
        icon: DirectionsRunIcon,
        children: [
          {
            title: t('Candidates'),
            path: '/recruitment-and-hosting/candidates',
          },
          {
            title: t('Host Sites'),
            path: '/recruitment-and-hosting/host-sites',
          },
          {
            title: t('Hosting Requests'),
            path: '/recruitment-and-hosting/hosting-requests',
          },
          {
            title: t('Recruitment Requests'),
            path: '/recruitment-and-hosting/recruitment-requests',
          },
        ],
      },
      {
        title: t('Training'),
        path: '/training',
        icon: SchoolIcon,
        children: [
          {
            title: t('Clients'),
            path: '/training/clients',
          },
          {
            title: t('Contacts'),
            path: '/training/contacts',
          },
          {
            title: t('Facilitators'),
            path: '/training/facilitators',
          },

          {
            title: t('Learners'),
            path: '/training/learners',
          },
          {
            title: t('Mentors'),
            path: '/training/mentors',
          },
          {
            title: t('Projects'),
            path: '/training/projects',
          },
        ],
      },
      {
        title: t('Quality Assurance'),
        path: '/quality-assurance',
        icon: DoneAllIcon,
        children: [
          {
            title: t('SETAs'),
            path: '/quality-assurance/setas',
          },
          {
            title: t('Verifications'),
            path: '/quality-assurance/verifications',
          },
        ],
      },
      {
        title: t('Administration'),
        path: '/administration',
        icon: SupervisorAccountIcon,
        children: [
          {
            title: t('Approvals'),
            path: '/administration/approvals',
          },
        ],
      },
    ],
  },
  {
    title: t('Management'),
    items: [
      {
        title: t('Communication'),
        path: '/communication',
        icon: ChatIcon,
        children: [
          {
            title: t('Emails'),
            path: '/communication/emails',
          },
          // {
          // 	title: t('Notifications'),
          // 	path: '/communication/notifications',
          // },
        ],
      },
      {
        title: t('Employees'),
        path: '/employees',
        icon: GroupIcon,
      },
      // {
      // 	title: t('Finance'),
      // 	path: '/finance',
      // 	icon: AttachMoneyIcon,
      // 	children: [
      // 		{
      // 			title: t('Credit Notes'),
      // 			path: '/finance/credit-notes',
      // 		},
      // 		{
      // 			title: t('Sales Items'),
      // 			path: '/finance/sales-items',
      // 		},
      // 	],
      // },
      {
        title: t('Integration'),
        path: '/integration',
        icon: CodeIcon,
        children: [
          {
            title: t('API Docs'),
            path: '/integration/api-docs',
          },
          {
            title: t('API Keys'),
            path: '/integration/api-keys',
          },
        ],
      },
      {
        title: t('Reporting'),
        path: '/reporting',
        icon: AssessmentIcon,
      },
      {
        title: t('Setups'),
        path: '/setups',
        icon: TuneIcon,
        children: [
          {
            title: t('BEE Levels'),
            path: '/setups/bee-levels',
          },
          {
            title: t('Countries'),
            path: '/setups/countries',
          },
          {
            title: t('Course Exemptions'),
            path: '/setups/course-exemptions',
          },
          {
            title: t('Dietary Preferences'),
            path: '/setups/dietary-preferences',
          },
          {
            title: t('Disabilities'),
            path: '/setups/disabilities',
          },
          {
            title: t('Default Folders'),
            path: '/setups/default-folders',
          },
          {
            title: t('Ethnicities'),
            path: '/setups/ethnicities',
          },
          {
            title: t('File Types'),
            path: '/setups/file-types',
          },
          {
            title: t('Genders'),
            path: '/setups/genders',
          },
          {
            title: t('Hosting Request Statuses'),
            path: '/setups/hosting-request-statuses',
          },
          {
            title: t('Industries'),
            path: '/setups/industries',
          },
          {
            title: t('Languages'),
            path: '/setups/languages',
          },
          {
            title: t('Learnerships'),
            path: '/setups/learnerships',
          },
          {
            title: t('NQF Levels'),
            path: '/setups/nqf-levels',
          },
          {
            title: t('Project Delivery Methods'),
            path: '/setups/project-delivery-methods',
          },
          {
            title: t('Project Event Types'),
            path: '/setups/project-event-types',
          },
          {
            title: t('Project Feedback'),
            path: '/setups/project-feedback',
          },
          {
            title: t('Project Phases'),
            path: '/setups/project-phases',
          },
          {
            title: t('Project Statuses'),
            path: '/setups/project-statuses',
          },
          {
            title: t('Qualification Categories'),
            path: '/setups/qualification-categories',
          },
          {
            title: t('Recruitment Request Statuses'),
            path: '/setups/recruitment-request-statuses',
          },
          {
            title: t('Regions'),
            path: '/setups/regions',
          },
          {
            title: t('School Qualifications'),
            path: '/setups/school-qualifications',
          },

          {
            title: t('Short Courses'),
            path: '/setups/short-courses',
          },
          {
            title: t('Skills Programmes'),
            path: '/setups/skills-programmes',
          },
          {
            title: t('Socio-Economic Statuses'),
            path: '/setups/socio-economic-statuses',
          },
          {
            title: t('Titles'),
            path: '/setups/titles',
          },
          {
            title: t('Venues'),
            path: '/setups/venues',
          },
          {
            title: t('Verification Statuses'),
            path: '/setups/verification-statuses',
          },
        ],
      },
      {
        title: t('System'),
        path: '/system',
        icon: SecurityIcon,
      },
    ],
  },
];

const getLearnerSections = (t: TFunction<'translation'>) => [
  {
    title: t('Pages'),
    items: [
      {
        title: t('Home'),
        path: '/',
        icon: HomeOutlinedIcon,
      },
      {
        title: t('Courses'),
        path: '/learn/course-history',
        icon: BookOutlinedIcon,
      },
      {
        title: t('Calendar'),
        path: '/learn/calendar',
        icon: CalendarTodayOutlinedIcon,
      },
      {
        title: t('Certificates'),
        path: '/learn/certificates',
        icon: ReceiptOutlinedIcon,
      },
    ],
  },
];

const getFacilitatorSections = (t: TFunction<'translation'>) => [
  {
    title: t('Pages'),
    items: [
      {
        title: t('Home'),
        path: '/',
        icon: HomeOutlinedIcon,
      },
    ],
  },
];

const getContactSections = (t: TFunction<'translation'>) => [
  {
    title: t('Pages'),
    items: [
      {
        title: t('Home'),
        path: '/',
        icon: HomeOutlinedIcon,
      },
    ],
  },
];

interface IStyleProps {
  isExpanded: boolean;
}

const useStyles = (props: IStyleProps) =>
  makeStyles((theme) => ({
    root: {
      height: '100%',
      overflowY: 'auto',
      transition: 'width 0.1s, minWidth 0.1s',
      zIndex: theme.zIndex.drawer,
      width: props.isExpanded ? 280 : 55,
      minWidth: props.isExpanded ? 280 : 55,
      flex: '0 0 auto',
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    navSection: {
      '& + &': {
        marginTop: theme.spacing(3),
      },
    },
    tempDrawer: {
      backgroundColor: theme.palette.background.paper,
      width: 280,
    },
    permDrawer: {
      backgroundColor: theme.palette.background.paper,
      height: 'calc(100% - 64px) !important',
      top: '64px !Important',
      width: 280,
    },
    logoContainer: {
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      height: 50,
    },
    expandButtonContainer: {
      marginRight: theme.spacing(1),
      position: 'absolute',
      zIndex: theme.zIndex.drawer + 2000,
      right: 0,
      top: theme.spacing(0.5),
    },
  }));

interface IProps {
  onMobileClose?: any;
  openMobile?: boolean;
}

const DashboardSidebar: React.FC<IProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const [isExpanded, setExpanded] = useState(true);
  const classes = useStyles({ isExpanded })();
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const sections = useMemo(
    () =>
      user?.type === UserType[UserType.Learner]
        ? getLearnerSections(t)
        : user?.type === UserType[UserType.Contact]
        ? getContactSections(t)
        : user?.type === UserType[UserType.Facilitator]
        ? getFacilitatorSections(t)
        : getSections(t),
    [t, user],
  );

  const handleSidebarExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box style={{ height: '100%', overflowY: 'auto' }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <div className={classes.logoContainer}>
          <RouterLink to='/'>
            <img
              alt='iLearn'
              src={`${rootConfig.staticFileRoot}/static/logos/core.svg`}
              height='50'
              style={{ marginTop: 10, padding: 6 }}
            />
          </RouterLink>
        </div>
        {lgUp && (
          <div className={classes.expandButtonContainer}>
            <Tooltip title='Collapse Menu'>
              <IconButton onClick={handleSidebarExpand}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <Box sx={{ p: 2, mt: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              className={classes.navSection}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='subtitle2'>
            {t('Need Help?')}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Button
              color='primary'
              fullWidth
              variant='contained'
              component={RouterLink}
              to={`/communication/chat`}
            >
              {t('Support')}
            </Button>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Paper className={classes.root} square elevation={0}>
        {isExpanded ? (
          content
        ) : (
          <Box style={{ height: '100%', overflowY: 'auto' }}>
            <div style={{ padding: 8 }}>
              <Tooltip title='Expand Menu'>
                <IconButton onClick={handleSidebarExpand}>
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Box>
        )}
      </Paper>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        className: classes.tempDrawer,
      }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
