import { useMemo } from 'react';
import { useDeepCompareMemoize } from './useDeepCompareMemoize';
import useAuth from './useAuth';
import { Permission, PermissionClaim } from 'app/types/auth';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';

export interface AccessControlParams<T extends Record<string, any>> {
  requiredPermissions?: Permission[];
  accessCheck?: (
    accessCheckData: T | undefined,
    user: IApplicationUser | null,
    userPermissions: Permission[],
  ) => boolean;
  accessCheckData?: T;
  /**
   * Indicate whether both or only one of requiredPermissions and accessCheck
   * needs to evaluate to true for access to be allowed.
   * @default 'and'
   */
  accessCheckRule?: 'or' | 'and';
  /**
   * Indicate that access is allowed only when a user is logged in. Defaults to true if requiredPermissions is present.
   * @default false
   */
  sessionRequired?: boolean;
}

const checkPermissions = (
  userPermissions: Permission[],
  requiredPermissions: Permission[],
): boolean => {
  return requiredPermissions.every((permission) => userPermissions.includes(permission));
};
const getPermissionsFromClaims = (claims: PermissionClaim[]): Permission[] => {
  const permissions = claims.map((e) => e.replace('Permissions.', '') as Permission);
  return permissions;
};

const useAccessControl = <T extends Record<string, any>>({
  requiredPermissions = [],
  accessCheck,
  accessCheckData,
  accessCheckRule = 'and',
  sessionRequired = false,
}: AccessControlParams<T>): boolean => {
  const { user, permissions } = useAuth();
  const userPermissions: Permission[] = useMemo<Permission[]>(
    () => getPermissionsFromClaims(permissions),
    [permissions],
  );

  const requiredPermissionsInternal = useDeepCompareMemoize(requiredPermissions);
  // Compute sessionRequired based on the presence of requiredPermissions.
  const sessionRequiredComputed =
    (requiredPermissionsInternal || []).length > 0 ? true : sessionRequired;

  return useMemo(() => {
    let permitted = checkPermissions(userPermissions, requiredPermissionsInternal || []);
    permitted = permitted && (sessionRequiredComputed ? (!!user ? true : false) : true);

    if (!accessCheck) return permitted;

    const hasAccess = accessCheck(accessCheckData, user, userPermissions);
    if (accessCheckRule === 'and') return permitted && hasAccess;
    return permitted || hasAccess;
  }, [
    userPermissions,
    requiredPermissionsInternal,
    accessCheck,
    accessCheckData,
    user,
    accessCheckRule,
    sessionRequiredComputed,
  ]);
};

export default useAccessControl;
