import {
  Box,
  Chip,
  ClickAwayListener,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  colors,
  useTheme,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import TodayIcon from '@material-ui/icons/Today';
import { IProjectPhase } from 'app/models/entities/IProjectPhase';
import { IProjectStatus } from 'app/models/entities/IProjectStatus';
import { IProject } from 'app/models/responses/IProject';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'utils/axios';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';

interface IProps {
  project: IProject;
}

const Header: React.FC<IProps> = (props) => {
  const { project: providedProject } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [isChangingPhase, setIsChangingPhase] = useState(false);
  const [statusId, setStatusId] = useState(providedProject.projectStatusId!);
  const [phaseId, setPhaseId] = useState(providedProject.projectPhaseId!);
  const [project, setProject] = useState(providedProject);
  const [phases, setPhases] = useState<IProjectPhase[] | null>(null);
  const [statuses, setStatuses] = useState<IProjectStatus[] | null>(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const select = ['id', 'name', 'color'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/projectPhases${queryString}`);
        if (mounted) {
          setPhases(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const select = ['id', 'name', 'color'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/projectStatuses${queryString}`);
        if (mounted) {
          setStatuses(response.data.value);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  const handleStatusChange = async () => {
    if (statusId === project.projectStatusId) {
      setIsChangingStatus(false);
      setStatusId(project.projectStatusId);
      return;
    }

    try {
      const updatedProject = {
        ...project,
        projectStatusId: statusId,
      };
      const diff = jsonpatch.compare(project, updatedProject);
      const select = ['id', 'projectStatusId'];
      const queryString = buildQuery({ select });
      const response = await axios.patch(
        `${rootConfig.odataRoute}/projects(${project.id})${queryString}`,
        diff,
      );
      setStatusId(response.data.projectStatusId);
      enqueueSnackbar(`Successfully changed status.`, { variant: 'success' });
      setProject(updatedProject);
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setIsChangingStatus(false);
    }
  };

  const handlePhaseChange = async () => {
    if (phaseId === project.projectPhaseId) {
      setIsChangingPhase(false);
      setPhaseId(project.projectPhaseId);
      return;
    }

    try {
      const updatedProject = {
        ...project,
        projectPhaseId: phaseId,
      };
      const diff = jsonpatch.compare(project, updatedProject);
      const select = ['id', 'projectPhaseId'];
      const queryString = buildQuery({ select });
      const response = await axios.patch(
        `${rootConfig.odataRoute}/projects(${project.id})${queryString}`,
        diff,
      );
      setPhaseId(response.data.projectPhaseId);
      enqueueSnackbar(`Successfully changed phase.`, { variant: 'success' });
      setProject(updatedProject);
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setIsChangingPhase(false);
    }
  };

  if (!statuses || !phases) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Grid container spacing={1}>
        {project.isArchived && (
          <Grid item>
            <Chip
              icon={<ArchiveOutlinedIcon />}
              variant='default'
              label={'Archived'}
              style={{
                backgroundColor: colors.deepOrange[500],
              }}
              color='primary'
              size='small'
            />
          </Grid>
        )}
        <Grid item>
          {isChangingStatus ? (
            <ClickAwayListener onClickAway={handleStatusChange} mouseEvent='onMouseUp'>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusId}
                  label='Status'
                  onChange={(e: any) => setStatusId(e.target.value)}
                  name='status'
                  variant='outlined'
                >
                  {statuses.map((status) => (
                    <MenuItem key={status.id} value={status.id}>
                      <Chip
                        variant='default'
                        label={status.name}
                        style={{
                          backgroundColor: status.color,
                          cursor: 'pointer',
                        }}
                        color='primary'
                        size='small'
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ClickAwayListener>
          ) : (
            <Chip
              onClick={() => setIsChangingStatus(true)}
              variant='default'
              label={statuses.find((e) => e.id === statusId)!.name}
              style={{
                backgroundColor: statuses.find((e) => e.id === statusId)!.color,
              }}
              color='primary'
              size='small'
            />
          )}
        </Grid>
        <Grid item>
          {isChangingPhase ? (
            <ClickAwayListener onClickAway={handlePhaseChange} mouseEvent='onMouseUp'>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel>Phase</InputLabel>
                <Select
                  value={phaseId}
                  label='Phase'
                  onChange={(e: any) => setPhaseId(e.target.value)}
                  name='phase'
                  variant='outlined'
                >
                  {phases.map((phase) => (
                    <MenuItem key={phase.id} value={phase.id}>
                      <Chip
                        variant='default'
                        label={phase.name}
                        style={{
                          backgroundColor: phase.color,
                          cursor: 'pointer',
                        }}
                        color='primary'
                        size='small'
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ClickAwayListener>
          ) : (
            <Chip
              onClick={() => setIsChangingPhase(true)}
              variant='default'
              label={phases.find((e) => e.id === phaseId)!.name}
              style={{
                backgroundColor: phases.find((e) => e.id === phaseId)!.color,
              }}
              color='primary'
              size='small'
            />
          )}
        </Grid>
        <Grid item>
          <Grid container style={{ color: theme.palette.text.primary }}>
            <Grid item>
              <Box sx={{ ml: 1, mr: 0.5 }}>
                <TodayIcon color='inherit' />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant='subtitle2' color='inherit'>
                {new Date(project.startDate!) > new Date() ? 'Starts ' : 'Started '}
                {moment(project.startDate!).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {project.hostingRequest && (
          <Grid item>
            <Chip
              label='View Hosting Request'
              size='small'
              component={RouterLink}
              to={`/recruitment-and-hosting/hosting-requests/${project.hostingRequest.id}`}
              style={{ cursor: 'pointer' }}
              icon={<LinkIcon />}
              color='primary'
            />
          </Grid>
        )}
        {project.recruitmentRequest && (
          <Grid item>
            <Chip
              label='View Recruitment Request'
              size='small'
              component={RouterLink}
              to={`/recruitment-and-hosting/recruitment-requests/${project.recruitmentRequest.id}`}
              style={{ cursor: 'pointer' }}
              icon={<LinkIcon />}
              color='primary'
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Header;
