import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { DashboardNavbar, DashboardSidebar, Page } from 'components/_dashboard';
import useAuth from 'hooks/useAuth';
import { UserType } from 'app/enums/UserType';
import { rootConfig } from 'config';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  outerContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
}));

const DashboardLayout: React.FC = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <div className={classes.root}>
      <div className={classes.outerContainer}>
        <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
        <div className={classes.container}>
          {user!.type === UserType[UserType.Employee] ||
          user!.type === UserType[UserType.Learner] ||
          user!.type === UserType[UserType.Contact] ||
          user!.type === UserType[UserType.Facilitator] ? (
            <>
              <DashboardSidebar
                onMobileClose={() => setIsSidebarMobileOpen(false)}
                openMobile={isSidebarMobileOpen}
              />
              <div className={classes.content}>
                <Outlet />
              </div>
            </>
          ) : (
            <div className={classes.content}>
              <Page title='Welcome' maxWidth='lg'>
                <Box sx={{ textAlign: 'center' }}>
                  <img
                    style={{
                      width: '100%',
                      maxHeight: 380,
                      marginBottom: 30,
                      marginTop: 30,
                    }}
                    src={`${rootConfig.staticFileRoot}/static/illustrations/undraw_under_construction_-46-pa.svg`}
                    alt='Welcome'
                  />
                  <Typography variant='h2'>Watch this space!</Typography>
                  <Typography variant='subtitle1' color='textSecondary'>
                    We will soon have a portal for you.
                  </Typography>
                </Box>
              </Page>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
