import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { IFileItem } from 'app/models/responses/IFileItem';
import React, { useMemo } from 'react';
//@ts-ignore
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import Color from 'color';
import { ApolloUtil } from 'utils/ApolloUtil';
import getFileUrl from 'utils/getFileUrl';

import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    height: '100%',
    backgroundColor: Color(theme.palette.background.paper).alpha(0.8).toString(),
    backdropFilter: 'blur(4px)',
  },
  documentContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  file: IFileItem;
}

//NOT WORKING: FIND ANOTHER DOC VIEWER NPM PACKAGE
const CustomDocViewer: React.FC<IProps> = (props) => {
  const { open, onClose, file } = props;
  const fileUrl = useMemo(() => getFileUrl(file), [file]);
  const classes = useStyles();
  const fileContentType = useMemo(() => ApolloUtil.getFileContentType(file), [file]);
  const Icon = useMemo(() => {
    return ApolloUtil.getFileContentIcon(file);
  }, [file]);

  const handleDownload = () => {
    saveAs(getFileUrl(file), file.displayName! + file.extension!);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xl'
      fullWidth
      fullScreen
      className={classes.root}
    >
      <Box className={classes.content}>
        <Box style={{ position: 'absolute', top: 0, right: 0, zIndex: 2 }}>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize='large' />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: '100%',
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '100vw',
              height: '100vh',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                margin: 0,
                padding: 0,
                transform: 'translate(-50%,-50%)',
              }}
            >
              {fileContentType === 'image' ? (
                <>
                  <img
                    loading='lazy'
                    style={{
                      overflow: 'hidden',
                      maxWidth: '100vw',
                      maxHeight: '100vh',
                    }}
                    src={fileUrl}
                    alt={file.displayName}
                  />
                </>
              ) : (
                <Box sx={{ width: '250px', px: 3 }}>
                  <Box className={classes.documentContainer}>
                    <Avatar>
                      <Icon />
                    </Avatar>
                    <Box>
                      <Tooltip title={`${file.displayName}${file.extension}`} enterDelay={500}>
                        <Typography variant='subtitle2'>
                          {file.displayName}
                          {file.extension}
                        </Typography>
                      </Tooltip>
                      <Typography
                        variant='caption'
                        color='textSecondary'
                        style={{ display: 'block' }}
                      >
                        {bytesToSize(file.size!)}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    startIcon={<DownloadIcon />}
                    color='primary'
                    variant='contained'
                    size='large'
                    fullWidth
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CustomDocViewer;
