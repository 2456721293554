import { AttendanceStatus } from 'app/enums/AttendanceStatus';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ODataGrid } from 'components/_dashboard';
import React from 'react';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';

interface IProps {
  projectLearner: IProjectLearner;
}

const WorkplaceAttendance: React.FC<IProps> = (props) => {
  const { projectLearner } = props;

  return (
    <ODataGrid
      entityType='projectLearnerWorkplaceAttendances'
      filter={{ projectLearnerId: projectLearner.id }}
      columns={[
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'date',
          title: 'Date',
          type: 'date',
          filtering: false,
          validate: (rowData) =>
            !rowData.date ? { isValid: false, helperText: 'Date is required' } : true,
        },
        {
          field: 'status',
          title: 'Status',
          lookup: odataLookupObjFromEnum(AttendanceStatus),
          initialEditValue: AttendanceStatus[AttendanceStatus.Absent],
          validate: (rowData) =>
            rowData.status === undefined
              ? { isValid: false, helperText: 'Status is required' }
              : true,
        },
        {
          field: 'reason',
          title: 'Reason',
        },
        {
          field: 'projectLearnerId',
          title: 'Project Learner',
          initialEditValue: projectLearner.id,
          hidden: true,
        },
      ]}
      canAdd
      canDelete
      canEdit
    />
  );
};

export default WorkplaceAttendance;
