import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface IProps {
  currencySymbol: string;
  rateSymbol?: string;
  onChange: (value: number) => void;
  name: string;
  value: number | string | null;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
}

const MoneyField: React.FC<IProps> = (props) => {
  const { currencySymbol, onChange, name, value, rateSymbol, ...rest } = props;

  const handleChange = (vals: any) => {
    onChange(vals.floatValue);
  };

  return (
    /* @ts-ignore */
    <NumberFormat
      customInput={TextField}
      variant='outlined'
      isNumericString={true}
      thousandSeparator={true}
      decimalScale={2}
      onValueChange={handleChange}
      name={name}
      value={value}
      InputProps={{
        startAdornment: <span style={{ marginRight: '3px' }}>{currencySymbol}</span>,
        endAdornment: rateSymbol && <span style={{ marginLeft: '3px' }}>{rateSymbol}</span>,
      }}
      {...rest}
    />
  );
};

export default MoneyField;
