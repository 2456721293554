import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, makeStyles } from '@material-ui/core';
import useSettings from 'hooks/useSettings';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

interface IProps {
  children: any;
  title: string;
  className?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Page: React.FC<IProps> = (props) => {
  const { title, className, children, maxWidth } = props;
  const { settings } = useSettings();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box className={clsx(classes.root, className)}>
        <Container maxWidth={maxWidth || 'xl'}>{children}</Container>
      </Box>
    </>
  );
};

export default Page;
