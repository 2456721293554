import React, { useState, useRef } from 'react';
import {
  makeStyles,
  Card,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  Badge,
} from '@material-ui/core';
import useAuth from 'hooks/useAuth';
import { EntityContext } from 'app/enums/EntityContext';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { ExceptionHandler, RichTextInput } from 'components';
import { IComment } from 'app/models/responses/IComment';
import { rootConfig } from 'config';
import { GenericAvatar } from 'components/_dashboard';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReplyIcon from '@material-ui/icons/Reply';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { HubConnection } from '@microsoft/signalr';

const useStyles = makeStyles((theme) => ({
  root: {},
  bodyInput: {
    minHeight: 320,
    marginBottom: theme.spacing(2),
  },
  embeddedComment: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  embeddedCommentAvatar: {
    marginRight: 4,
    width: '13px',
    height: '13px',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    height: 14,
    width: 14,
    marginRight: 5,
  },
  actionReplyContent: {
    display: 'flex',
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  placeholder: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'help',
    padding: theme.spacing(1),
  },
  clearAttachments: {
    alignSelf: 'center',
    marginLeft: theme.spacing(0.5),
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  context: EntityContext;
  contextId: number;
  parentComment?: IComment;
  connection: HubConnection | null;
}

const CommentAdd: React.FC<IProps> = (props) => {
  const { context, contextId, open, onClose, parentComment, connection } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fileInputRef: any = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [body, setBody] = useState('');
  const [addingComment, setAddingComment] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const { user } = useAuth();

  const handleAdd = async () => {
    try {
      setAddingComment(true);
      const formData = new FormData();
      formData.append('body', body);
      formData.append('userId', user!.id!.toString());
      formData.append('contextId', contextId.toString());
      formData.append('context', EntityContext[context]);
      formData.append('parentCommentId', parentComment?.id?.toString() || 'null');
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      const expand = ['user/avatarFileItem'];
      const queryString = buildQuery({ expand });
      const response = await axios.post<IComment>(
        `${rootConfig.odataRoute}/comments${queryString}`,
        formData,
      );
      if (connection) await connection.send('CreateComment', response.data);
      enqueueSnackbar('Successfully added comment.', { variant: 'success' });
      setBody('');
      setFiles([]);
      onClose();
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setAddingComment(false);
    }
  };

  const handleFilesSelect = () => {
    fileInputRef.current.click();
  };

  const handleAttach = (event: any) => {
    setFiles((files: any) => [...files, ...event.target.files]);
  };

  const handleClose = () => {
    setBody('');
    setFiles([]);
    onClose();
  };

  const handleAttachmentRemove = (index: number) => {
    setFiles((prevFiles: []) =>
      prevFiles.filter((file: any, oldIndex: number) => oldIndex !== index),
    );
  };

  const handleChange = (body: string) => {
    setBody(body);
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add Comment</DialogTitle>
      <DialogContent>
        {parentComment && (
          <div className={classes.embeddedComment}>
            <div className={classes.actionReplyContent}>
              <ReplyIcon className={classes.infoIcon} />
              <GenericAvatar
                avatarFile={parentComment.user?.avatarFileItem}
                className={classes.embeddedCommentAvatar}
                name={parentComment.user?.userName!}
              />
              <Typography variant='body2'>{parentComment.user?.userName}</Typography>
            </div>
          </div>
        )}
        <RichTextInput value={body} onChange={handleChange} className={classes.bodyInput} />

        <input
          className={classes.fileInput}
          ref={fileInputRef}
          type='file'
          name='files[]'
          multiple
          onChange={handleAttach}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
        {files.length > 0 && (
          <Grid container spacing={2} alignItems='center'>
            {files.map((file: any, index: number) => (
              <Grid item key={index}>
                <Badge
                  badgeContent={
                    <Tooltip title='Remove' placement='top'>
                      <IconButton
                        onClick={() => handleAttachmentRemove(index)}
                        color='secondary'
                        size='small'
                      >
                        <HighlightOffIcon fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Tooltip title={file.name} placement='top'>
                    <Card>
                      <div className={classes.placeholder}>
                        <InsertDriveFileIcon />
                      </div>
                    </Card>
                  </Tooltip>
                </Badge>
              </Grid>
            ))}
            <Grid item className={classes.clearAttachments}>
              <Tooltip title='Remove All' placement='right'>
                <IconButton color='secondary' onClick={() => setFiles([])} disabled={addingComment}>
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFilesSelect} color='secondary' variant='outlined'>
          Attach Files
        </Button>
        <Button
          variant='contained'
          onClick={handleAdd}
          color='primary'
          disabled={!body || addingComment}
        >
          Add
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentAdd;
