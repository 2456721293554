import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { IContactInfo } from 'app/models/responses/IContactInfo';

interface IProps {
  parentEntityType: 'facilitators' | 'learners' | 'candidates' | 'mentors' | 'contacts';
  parentEntityId: number;
  contactInfo: IContactInfo | null;
  onContactInfoUpdate?: (updatedContactInfo: IContactInfo) => void;
}

const GenericContactInfoForm: React.FC<IProps> = (props) => {
  const { contactInfo, parentEntityId, parentEntityType, onContactInfoUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        emailAddress: contactInfo?.emailAddress || '',
        workNumber: contactInfo?.workNumber || '',
        homeNumber: contactInfo?.homeNumber || '',
        cellNumber: contactInfo?.cellNumber || '',
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        emailAddress: Yup.string(),
        workNumber: Yup.string(),
        homeNumber: Yup.string(),
        cellNumber: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            contactInfo,
          };
          const updatedEntity: any = {
            contactInfo: {
              ...contactInfo,
              ...values,
            },
          };
          delete updatedEntity.contactInfo['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedContactInfo: IContactInfo = response.data.contactInfo;
          resetForm({
            values: {
              emailAddress: updatedContactInfo?.emailAddress || '',
              workNumber: updatedContactInfo?.workNumber || '',
              homeNumber: updatedContactInfo?.homeNumber || '',
              cellNumber: updatedContactInfo?.cellNumber || '',
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated contact info.`, { variant: 'success' });
          setStatus({ success: true });
          onContactInfoUpdate && onContactInfoUpdate(updatedContactInfo);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Contact' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.emailAddress && errors.emailAddress)}
                    fullWidth
                    helperText={touched.emailAddress && errors.emailAddress}
                    label='Secondary Email'
                    name='emailAddress'
                    onChange={handleChange}
                    value={values.emailAddress}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.cellNumber && errors.cellNumber)}
                    fullWidth
                    helperText={touched.cellNumber && errors.cellNumber}
                    label='Cell Number'
                    name='cellNumber'
                    onChange={handleChange}
                    value={values.cellNumber}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.homeNumber && errors.homeNumber)}
                    fullWidth
                    helperText={touched.homeNumber && errors.homeNumber}
                    label='Home Number'
                    name='homeNumber'
                    onChange={handleChange}
                    value={values.homeNumber}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.workNumber && errors.workNumber)}
                    fullWidth
                    helperText={touched.workNumber && errors.workNumber}
                    label='Work Number'
                    name='workNumber'
                    onChange={handleChange}
                    value={values.workNumber}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericContactInfoForm;
