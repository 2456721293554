import { IProject } from 'app/models/responses/IProject';
import { ODataGrid } from 'components/_dashboard';
import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { ILearner } from 'app/models/responses/ILearner';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';
import { ApprovalRequestStatus } from 'app/enums/ApprovalRequestStatus';
import { Chip, LinearProgress } from '@material-ui/core';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import { EXTRATIMEREQUESTCOLORS } from '../../../../../constants';
import { ProjectLearnerStatus } from 'app/enums/ProjectLearnerStatus';
import { IProjectLearnerReplacement } from 'app/models/entities/IProjectLearnerReplacement';

interface IProps {
  project: IProject;
}

const Replacements: React.FC<IProps> = (props) => {
  const { project } = props;
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [learnerLookup, setLearnerLookup] = useState<any>(undefined);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const select = ['id', 'email', 'idNumber', 'fullName'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/learners${queryString}`);
        if (mounted) {
          const learnerLookups: any = {};
          response.data.value.forEach((learner: ILearner) => {
            learnerLookups[
              learner.id!
            ] = `${learner.fullName} | ${learner.idNumber} | ${learner.email}`;
          });
          setLearnerLookup(learnerLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  if (!learnerLookup) {
    return <LinearProgress />;
  }

  return (
    <ODataGrid<IProjectLearnerReplacement>
      entityType='projectLearnerReplacements'
      filter={{ projectId: project.id }}
      expand={['replacementLearner', 'replacedProjectLearner/learner']}
      columns={[
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'replacedProjectLearnerId',
          title: 'Replaced Learner',
          filtering: false,
          render: (rowData) => (
            <span>
              {rowData.replacedProjectLearner!.learner!.fullName} |{' '}
              {rowData.replacedProjectLearner!.learner!.idNumber} |{' '}
              {rowData.replacedProjectLearner!.learner!.email}
            </span>
          ),
        },
        {
          field: 'reason',
          title: 'Reason',
          lookup: odataLookupObjFromEnum(ProjectLearnerStatus),
        },
        {
          field: 'replacementLearnerId',
          title: 'Replaced By',
          filtering: false,
          render: (rowData) => (
            <span>
              {rowData.replacementLearner.fullName} | {rowData.replacementLearner.idNumber} |{' '}
              {rowData.replacementLearner.email}
            </span>
          ),
        },
        {
          field: 'replaceDate',
          title: 'Replace Date',
          filtering: false,
          type: 'datetime',
        },
        {
          field: 'projectId',
          hidden: true,
        },
      ]}
    />
  );
};

export default Replacements;
