import { Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { IFileItem } from 'app/models/responses/IFileItem';
import { DocViewer } from 'components/_dashboard';
import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles((theme) => ({
  fileItem: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface IProps {
  file: IFileItem;
  onDownload: () => void;
}

const CommentAttachment: React.FC<IProps> = (props) => {
  const { file, onDownload } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <Tooltip title={bytesToSize(file.size!)} placement='right'>
          <Typography
            key={file.id}
            variant='body2'
            className={classes.fileItem}
            color='textSecondary'
            onClick={onDownload}
          >
            {file.displayName! + file.extension!}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default CommentAttachment;
