import { useCallback, useState, useEffect, ChangeEvent, useMemo } from 'react';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import buildQuery from 'odata-query';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import useMounted from 'hooks/useMounted';
import { Link as RouterLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import {
  GenericCommentsEditor,
  GenericDocumentsEditor,
  GenericHeader,
  SelectManyAsync,
  SelectOneAsync,
} from 'components/_dashboard';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { EntityContext } from 'app/enums/EntityContext';
import { IProject } from 'app/models/responses/IProject';
import { IEntityBase } from 'app/models/responses/IEntityBase';

interface IProps {
  project: IProject;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const BulkEnrolment: React.FC<IProps> = (props) => {
  const { project, onClose, open, onConfirm } = props;
  const mounted = useMounted();
  const classes = useStyles();
  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [learnerIds, setLearnerIds] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const clientIds = useMemo(() => project.projectClients?.map((e) => e.clientId!) || [], [project]);
  const [clientId, setClientId] = useState<number>();

  useEffect(() => {
    if (clientIds.length === 1) {
      setClientId(clientIds[0]);
    }
  }, [clientIds]);

  const handleClose = () => {
    setLearnerIds([]);
    setClientId(undefined);
    onClose();
  };

  const handleConfirm = async () => {
    if (!clientId) return;

    try {
      setSubmitting(true);
      const payload = {
        clientId,
        learnerIds,
      };
      const response = await axios.post(
        `${rootConfig.odataRoute}/projects(${project.id})/bulkEnrolment`,
        payload,
      );
      enqueueSnackbar('Successfully enrolled learners.', { variant: 'success' });
      onConfirm();
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth maxWidth='md'>
      <DialogTitle>Bulk Enrolment</DialogTitle>
      <DialogContent>
        <Box>
          <SelectManyAsync
            name='learnerIds'
            entityIds={learnerIds || []}
            entityType='learners'
            endPoint={`projects(${project.id!})/getAllowedLearners()`}
            labelFields={['fullName', 'idNumber', 'email']}
            filterFields={['email', 'idNumber', 'firstName', 'lastName']}
            onChange={(event: any, value: any) =>
              setLearnerIds(value?.map((e: IEntityBase) => e.id!) || [])
            }
            label='Search Learners'
            required
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <SelectOneAsync
            name='clientId'
            filter={{
              ...(clientIds.length > 0 && {
                id: {
                  in: clientIds,
                },
              }),
            }}
            entityId={clientId || null}
            entityType='clients'
            labelFields={['name']}
            filterFields={['name']}
            onChange={(event: any, value: any) => setClientId(value?.id || null)}
            label='Client'
            disabled={clientIds.length === 1}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleConfirm}
          color='primary'
          disabled={learnerIds.length === 0 || !clientId || isSubmitting}
        >
          Confirm
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkEnrolment;
