import { Box, Grid } from '@material-ui/core';
import { IProject } from 'app/models/responses/IProject';
import React from 'react';
import { Team, LearnerMetrics, Overview } from './components';
import { IProjectClient } from 'app/models/entities/IProjectClient';

interface IProps {
  project: IProject;
  onProjectUpdate: (updatedProject: IProject) => void;
}

const Details: React.FC<IProps> = (props) => {
  const { project, onProjectUpdate } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <Overview project={project} onProjectUpdate={onProjectUpdate} />
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Team project={project} />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <LearnerMetrics project={project} />
      </Grid>
    </Grid>
  );
};

export default Details;
