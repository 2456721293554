import { useEffect, useRef, useState } from 'react';
import { subDays, subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Switch,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import useSettings from 'hooks/useSettings';
import { THEMES } from '../../constants';
import useAuth from 'hooks/useAuth';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'utils/axios';
import { rootConfig } from 'config';
import ExceptionHandler from 'components/ExceptionHandler';
import { useSnackbar } from 'notistack';
import { IAppHealthResponse } from 'app/models/responses/IAppHealthResponse';
import { IPublicAppHealthResponse } from 'app/models/responses/IPublicAppHealthResponse';

const getValues = (settings: any) => ({
  compact: settings.compact,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
  },
  notificationLink: {
    cursor: 'pointer',
  },
  notificationAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const SettingsPopover = () => {
  const anchorRef = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState<any>(getValues(settings));
  const { user } = useAuth();
  const [health, setHealth] = useState<IPublicAppHealthResponse | null>(null);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const response = await axios.get(`${rootConfig.odataRoute}/appConfigs/GetPublicHealth()`);
        if (mounted) {
          setHealth(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field: any, value: any) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='Site Settings'>
        <IconButton color='inherit' ref={anchorRef} onClick={handleOpen}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <Typography variant='h6'>Site Settings</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
              {user ? (
                <Link
                  component={RouterLink}
                  to='/changelog'
                  color='textPrimary'
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  {health?.currentDisplayVersion ? `v${health.currentDisplayVersion}` : 'v1.0.0'}
                </Link>
              ) : (
                <Typography variant='body2' color='textPrimary' style={{ fontSize: 14 }}>
                  {health?.currentDisplayVersion ? `v${health.currentDisplayVersion}` : 'v1.0.0'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel>Theme</InputLabel>
              <Select
                value={values.theme}
                label='Theme'
                onChange={(event) => handleChange('theme', event.target.value)}
                name='theme'
                variant='outlined'
              >
                {Object.keys(THEMES).map((theme) => (
                  <MenuItem key={theme} value={theme}>
                    {theme
                      .split('_')
                      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                      .join(' ')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Button color='primary' fullWidth onClick={handleSave} variant='contained'>
              Save Settings
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default SettingsPopover;
