import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { UserType } from 'app/enums/UserType';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'utils/axios';
import { ILoginOption } from '../LoginJWT/LoginJWT';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { Alert } from '@material-ui/lab';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ResetPassword: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [userType, setUserType] = useState<keyof typeof UserType>('Learner');
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const availableLoginAs = useMemo<ILoginOption[]>(
    () => [
      { value: 'Contact', display: 'Client' },
      { value: 'Employee', display: 'Employee' },
      { value: 'Facilitator', display: 'Facilitator' },
      { value: 'Learner', display: 'Learner' },
    ],
    [],
  );

  useEffect(() => {
    const userTypeParam = searchParams.get('userType') ?? '';
    if (availableLoginAs.map((e) => e.value).includes(userTypeParam)) {
      setUserType(userTypeParam as keyof typeof UserType);
    } else {
      setUserType('Learner');
    }
  }, [searchParams, availableLoginAs]);

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    setEmail(event.target.value);
  };

  const handleConfirm = async () => {
    try {
      setSubmitting(true);
      const resetDetail = {
        email,
        userType,
      };
      // eslint-disable-next-line
      const response = await axios.post(
        `${rootConfig.odataRoute}/users/resetForgottenPassword`,
        resetDetail,
      );
      enqueueSnackbar(`Succesfully created a password reset request. Please check your email.`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please provide us with your unique email address and we'll send you an email with
          instructions on how to reset your password.
        </DialogContentText>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={12} sm={5}>
              <Alert icon={<SupervisedUserCircleIcon />} severity='info'>
                <Typography variant='subtitle2'>I am a </Typography>
              </Alert>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormControl fullWidth variant='outlined'>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value as keyof typeof UserType)}
                  name='userType'
                  variant='outlined'
                >
                  {availableLoginAs.map((userType) => (
                    <MenuItem key={userType.value} value={userType.value}>
                      {userType.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TextField
            fullWidth
            label='Email Address'
            name='email'
            onChange={handleChange}
            value={email}
            variant='outlined'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          color='primary'
          variant='contained'
          disabled={isSubmitting || !email}
        >
          Confirm
        </Button>
        <Button onClick={handleCancel} color='primary' variant='outlined'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPassword;
