import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  makeStyles,
} from '@material-ui/core';
import { IProject } from 'app/models/responses/IProject';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { StepOne, StepTwo } from './components';
import { ProjectNotification } from 'app/enums/ProjectNotification';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

interface IProps {
  project: IProject;
  open: boolean;
  onClose: () => void;
}

function getSteps() {
  return ['Select notification'];
}

const Notifications: React.FC<IProps> = (props) => {
  const { project, open, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [notification, setNotification] = useState<keyof typeof ProjectNotification | null>(null);
  const steps = getSteps();

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <StepOne
            onNext={handleNext}
            selectedNotification={notification}
            onNotificationChange={(n) => setNotification(n)}
            project={project}
            onClose={handleClose}
          />
        );
      case 1:
        return <StepTwo onNext={handleNext} onBack={handleBack} notification={notification} />;
      default:
        return 'Unknown step';
    }
  };

  const handleClose = () => {
    onClose();
    handleReset();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setNotification(null);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Send Notification</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} orientation='vertical'>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Box sx={{ my: 2 }}>{getStepContent(index)}</Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined' color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Notifications;
