import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Link,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { IContact } from 'app/models/responses/IContact';
import { ExceptionHandler } from 'components';
import { ConfirmationModal, GenericAvatar } from 'components/_dashboard';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import axios from 'utils/axios';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { IMentor } from 'app/models/responses/IMentor';
import PropertyList from 'components/_dashboard/PropertyList';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PropertyListItem from 'components/_dashboard/PropertyListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: theme.spacing(4, 3),
  },

  chip: {},
}));

interface IProps {
  mentor: IMentor;
  onDelete: () => void;
}

const Mentor: React.FC<IProps> = (props: IProps) => {
  const { mentor, onDelete } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);

  const handleDelete = () => {
    setConfirmDeleteOpen(false);
    onDelete();
  };

  return (
    <Grid item sm={6} xs={12}>
      <Box className={classes.root}>
        <Box
          style={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <GenericAvatar name={mentor.userName!} avatarFile={mentor.avatarFileItem} />
          <Box sx={{ ml: 2 }}>
            <Tooltip title={`View Details`} placement='top'>
              <Typography
                color='textPrimary'
                component={Link}
                variant='subtitle2'
                style={{ cursor: 'pointer' }}
                onClick={() => setDetailOpen(true)}
              >
                {mentor.fullName}
              </Typography>
            </Tooltip>

            <Typography
              color='textSecondary'
              variant='body2'
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              {mentor.email}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Tooltip title='Remove'>
            <IconButton onClick={() => setConfirmDeleteOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <ConfirmationModal
        open={confirmDeleteOpen}
        title='Remove Mentor'
        message='Are you sure you wish to remove this person as a mentor?'
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDelete}
        error={true}
      />
      <Dialog open={detailOpen} onClose={() => setDetailOpen(false)}>
        <DialogTitle>
          <Box display='flex' style={{ gap: 2 }}>
            <Typography color='textPrimary' variant='h5'>
              {mentor.fullName}
            </Typography>
            <Box flexGrow={1} />
            <Button
              color='secondary'
              variant='outlined'
              component={RouterLink}
              to={`/training/mentors/${mentor.id}`}
              startIcon={<AccountCircleIcon />}
            >
              Profile
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PropertyList>
            <PropertyListItem label='Username' align='horizontal' value={mentor.userName} />

            <PropertyListItem label='Email Address' align='horizontal' value={mentor.email} />
            <PropertyListItem
              label='Cell Number'
              align='horizontal'
              value={mentor.contactInfo?.cellNumber || '-'}
            />
          </PropertyList>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailOpen(false)} color='primary' variant='outlined'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Mentor;
