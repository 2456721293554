import { useRef, useState } from 'react';
import { subDays, subHours } from 'date-fns';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
  },
  notificationLink: {
    cursor: 'pointer',
  },
  notificationAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title='Notifications'>
        <IconButton color='inherit' ref={anchorRef} onClick={handleOpen}>
          <Badge color='error' badgeContent={1}>
            <NotificationsIcon fontSize='small' />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='h6'>
            Notifications
          </Typography>
        </Box>
        {0 !== 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color='textPrimary' variant='subtitle2'>
              Nothing to show at this moment...
            </Typography>
          </Box>
        ) : (
          <>
            <Box sx={{ p: 1 }}>
              <Alert severity='info'>
                <div>Not yet implemented.</div>
              </Alert>
            </Box>
            {/* <List disablePadding>
                <ListItem
                  divider
                >
                  <ListItemAvatar>
                    <Avatar className={classes.notificationAvatar}>
                      NO
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <Link
                        color="textPrimary"
                        className={classes.notificationLink}
                        underline="none"
                        variant="subtitle2"
                      >
                        Something happened
                      </Link>
                    )}
                    secondary={"This happened"}
                  />
                </ListItem>
              </List> */}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}
            >
              <Button color='primary' size='small' variant='text' fullWidth>
                Mark all as read
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
