import { Avatar, Box, Card, Typography, colors, useTheme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Color from 'color';
import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

interface IProps {
  rawData: string;
}

const CourseUnitContent = ({ rawData }: IProps) => {
  const theme = useTheme();
  const units = useMemo(() => {
    let records: string[] = [];
    try {
      records = JSON.parse(rawData) as string[];
    } catch {}
    return records;
  }, [rawData]);

  return (
    <Box sx={{ mb: 2 }}>
      <Card variant='outlined'>
        <Box sx={{ p: 1 }}>
          <Box
            style={{
              display: 'flex',
              gap: '6px',
              alignItems: 'center',
              marginBottom: theme.spacing(1),
            }}
          >
            <Avatar style={{ backgroundColor: Color(colors.green[500]).alpha(0.1).toString() }}>
              <InfoOutlinedIcon style={{ color: colors.green[500] }} />
            </Avatar>
            <Typography variant='h6'>Learning Outcomes</Typography>
          </Box>
          {units.map((unit) => (
            <Typography key={uuid()}>- {unit}</Typography>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default CourseUnitContent;
