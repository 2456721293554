import { combineReducers } from '@reduxjs/toolkit';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as projectEventsCalendarReducer } from '../slices/projectEventsCalendar';

const rootReducer = combineReducers({
  chat: chatReducer,
  projectEventsCalendar: projectEventsCalendarReducer,
});

export default rootReducer;
