import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { IClient } from 'app/models/responses/IClient';
import { IContact } from 'app/models/responses/IContact';
import { IHostSite } from 'app/models/responses/IHostSite';
import { ISeta } from 'app/models/responses/ISeta';
import { ExceptionHandler, Scrollbar } from 'components';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import useMounted from 'hooks/useMounted';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { ContactPerson, ContactPersonAdd } from './components';

interface IProps {
  parentEntityType: 'clients' | 'hostSites' | 'setas';
  parentEntity: IClient | IHostSite | ISeta;
  onContactsUpdate: (contactIds: number[]) => void;
}

const GenericContactPersonForm: React.FC<IProps> = (props) => {
  const { parentEntityType, parentEntity, onContactsUpdate } = props;
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [contactAddOpen, setContactAddOpen] = useState(false);
  const [contactPersons, setContactPersons] = useState<IContact[] | null>(null);
  const [contactIds, setContactIds] = useState(
    parentEntity.contactPersons?.map((e) => e.contactId) || [],
  );

  useEffect(() => {
    setContactIds(parentEntity.contactPersons?.map((e) => e.contactId) || []);
  }, [parentEntity]);

  const getContactPersons = useCallback(async () => {
    try {
      const filter = {
        id: {
          in: contactIds,
        },
      };
      const expand = ['avatarFileItem'];
      const queryString = buildQuery({ filter, expand });
      const response = await axios.get(`${rootConfig.odataRoute}/contacts${queryString}`);
      if (mounted.current) {
        setContactPersons(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, enqueueSnackbar, contactIds]);

  useEffect(() => {
    getContactPersons();
  }, [getContactPersons]);

  const handleAdd = (contactPersonId: number) => {
    const updatedIds = [...(contactIds as number[])].concat(contactPersonId);
    onContactsUpdate(updatedIds);
  };

  const handleDelete = async (contactPerson: IContact) => {
    try {
      const updatedContacts = parentEntity.contactPersons ? [...parentEntity.contactPersons] : [];
      const updatedEntity: IHostSite | IClient = {
        ...parentEntity,
        contactPersons: updatedContacts.filter((e) => e.contactId !== contactPerson.id),
      };

      const diff = jsonpatch.compare(parentEntity, updatedEntity);
      await axios.patch(`${rootConfig.odataRoute}/${parentEntityType}(${parentEntity.id})`, diff);
      const updatedIds = [...(contactIds as number[])].filter((e) => e !== contactPerson.id);
      onContactsUpdate(updatedIds);
      enqueueSnackbar(`Successfully removed contact.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  if (!contactPersons) return null;

  return (
    <Card>
      <CardHeader title='Contact Persons' />
      <Divider />

      {contactPersons.length > 0 && (
        <>
          <Scrollbar options={{ suppressScrollX: true }}>
            <CardContent style={{ maxHeight: 420 }}>
              <Grid container spacing={2}>
                {contactPersons.map((contactPerson) => (
                  <ContactPerson
                    key={contactPerson.id}
                    contactPerson={contactPerson}
                    onDelete={() => handleDelete(contactPerson)}
                    parentEntity={parentEntity}
                  />
                ))}
              </Grid>
            </CardContent>
          </Scrollbar>

          <Divider />
        </>
      )}

      <CardActions>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          color='secondary'
          onClick={() => setContactAddOpen(true)}
        >
          Add
        </Button>
      </CardActions>
      <ContactPersonAdd
        open={contactAddOpen}
        onClose={() => setContactAddOpen(false)}
        onAdd={handleAdd}
        parentEntityType={parentEntityType}
        parentEntity={parentEntity}
      />
    </Card>
  );
};

export default GenericContactPersonForm;
