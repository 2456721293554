import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';
import React, { Fragment, useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import axios from 'utils/axios';

import { IAvatarEntity } from 'app/models/responses/IAvatarEntity';
import { ExceptionHandler } from 'components';
import { ConfirmationModal, GenericAvatar } from 'components/_dashboard';
import { rootConfig } from 'config';
import variableCaseToSentence from 'utils/variableCaseToSentence';
import getFileUrl from 'utils/getFileUrl';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center',
    paddingBottom: 0,
  },
  name: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    height: 100,
    width: 100,
  },
  editButtonContainer: {
    width: '100%',
  },
  internalLabel: {
    marginTop: theme.spacing(1),
  },
  companyLabel: {
    marginTop: theme.spacing(1),
    transition: '0.2s',
    '&:hover': {
      boxShadow: '2px 2px 6px rgba(0,0,0,0.25)',
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  fileInput: {
    display: 'none',
  },
  clearUploadAvatar: {
    marginLeft: theme.spacing(1),
  },
  uploadAvatar: {
    marginTop: theme.spacing(1),
    float: 'right',
  },
  coverImage: {
    borderRadius: theme.shape.borderRadius,
    padding: '6px',
    width: '100%',
    minHeight: '200px',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}));

interface IProps {
  entity: IAvatarEntity;
  onUpdate: () => void;
  className?: string;
  endpoint: 'users' | 'courseRevisions';
  type: 'avatar' | 'cover';
  seed?: string;
}

const GenericAvatarEditor: React.FC<IProps> = (props) => {
  const { entity, onUpdate, endpoint, type, seed, className, ...rest } = props;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const canEdit = true;

  const fileInputRef: any = useRef(null);
  const [uploadAvatar, setUploadAvatar] = useState<any>(null);
  const [uploadingAvatar, setUploadingAvatar] = useState(false);
  const [confirmAvatarClear, setConfirmAvatarClear] = useState(false);

  const avatarUrl = useMemo(
    () =>
      entity.avatarFileItem
        ? getFileUrl(entity.avatarFileItem)
        : `${rootConfig.avatarFallbackRoute}/${
            type === 'avatar' ? 'identicon' : 'shapes'
          }/svg?seed=${seed ?? type}`,
    [entity, type, seed],
  );

  const handleAvatarSelect = () => {
    fileInputRef.current.click();
  };

  const handleAvatarClear = async () => {
    setUploadAvatar(null);
    await handleAvatarUpdate();
    setConfirmAvatarClear(false);
  };

  const handleAvatarUpdate = async () => {
    try {
      setUploadingAvatar(true);
      const formData = new FormData();
      formData.append('file', uploadAvatar);
      const response = await axios.post(
        `${rootConfig.odataRoute}/${endpoint}(${entity.id})/updateAvatar`,
        formData,
      );
      onUpdate();
      enqueueSnackbar(`Successfully updated ${type}.`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setUploadingAvatar(false);
      setUploadAvatar(null);
    }
  };

  const handleAttach = (event: any) => {
    const file = event.target.files[0];
    if (!file.type.includes('image')) {
      enqueueSnackbar('Invalid file type.', { variant: 'error' });
    } else {
      setUploadAvatar(file);
    }
  };

  return (
    <Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader title={variableCaseToSentence(type)} />
        <Divider />
        <CardContent className={classes.content}>
          {type === 'avatar' ? (
            <GenericAvatar
              className={classes.avatar}
              name={type}
              avatarFile={entity.avatarFileItem}
            />
          ) : (
            <Box style={{ width: '100%' }}>
              <Box
                style={{ backgroundImage: `url(${avatarUrl})` }}
                className={classes.coverImage}
              />
            </Box>
          )}
        </CardContent>
        <CardActions>
          <span className={classes.editButtonContainer}>
            {uploadAvatar ? (
              <Fragment>
                <Button
                  fullWidth
                  color='secondary'
                  variant='contained'
                  onClick={handleAvatarUpdate}
                  disabled={uploadingAvatar}
                >
                  {uploadingAvatar ? (
                    <CircularProgress size={23} color='secondary' />
                  ) : (
                    'Confirm Change'
                  )}
                </Button>
                <Typography className={classes.uploadAvatar} variant='body2'>
                  {uploadAvatar.name}
                  <Tooltip title='Clear File'>
                    <IconButton
                      onClick={() => setUploadAvatar(null)}
                      className={classes.clearUploadAvatar}
                      size='small'
                    >
                      <RemoveCircleOutlineIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Fragment>
            ) : (
              <Grid spacing={1} container>
                <Grid item xs={6}>
                  <Button variant='text' color='primary' fullWidth onClick={handleAvatarSelect}>
                    Change
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {entity.avatarFileItem && (
                    <Button
                      variant='text'
                      color='secondary'
                      fullWidth
                      onClick={() => setConfirmAvatarClear(true)}
                    >
                      Clear
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
            <input
              className={classes.fileInput}
              ref={fileInputRef}
              type='file'
              name='files[]'
              onChange={handleAttach}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              accept='image/*'
            />
          </span>
        </CardActions>
      </Card>
      <ConfirmationModal
        open={confirmAvatarClear}
        title='Clear Avatar'
        message={`Are you sure you wish to permanently remove this ${type}?`}
        onCancel={() => setConfirmAvatarClear(false)}
        onConfirm={handleAvatarClear}
        error={true}
      />
    </Fragment>
  );
};

export default GenericAvatarEditor;
