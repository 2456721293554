import React, { useState } from 'react';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as jsonpatch from 'fast-json-patch';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { ExceptionHandler, RichTextInput } from 'components';
import { IComment } from 'app/models/responses/IComment';
import { rootConfig } from 'config';
import { HubConnection } from '@microsoft/signalr';

const useStyles = makeStyles((theme) => ({
  root: {},
  bodyInput: {
    minHeight: 320,
  },
  embeddedComment: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  embeddedCommentAvatar: {
    marginRight: 4,
    width: '13px',
    height: '13px',
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    height: 14,
    width: 14,
    marginRight: 5,
  },
  actionReplyContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  comment: IComment;
  connection: HubConnection | null;
}

const CommentEdit: React.FC<IProps> = (props) => {
  const { open, onClose, comment, connection } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [body, setBody] = useState(comment.body!);
  const [updatingComment, setUpdatingComment] = useState(false);

  const handleUpdate = async () => {
    try {
      setUpdatingComment(true);

      const originalComment = {
        ...comment,
      };
      const commentToUpdate = {
        ...comment,
        body,
      };
      const diff = jsonpatch.compare(originalComment, commentToUpdate);

      const expand = ['user/avatarFileItem'];
      const queryString = buildQuery({ expand });
      const response = await axios.patch<IComment>(
        `${rootConfig.odataRoute}/comments(${comment.id!})${queryString}`,
        diff,
      );
      const updatedComment = response.data;
      if (connection) await connection.send('UpdateComment', updatedComment);
      setBody(updatedComment.body!);
      enqueueSnackbar('Successfully updated comment.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    } finally {
      setUpdatingComment(false);
      onClose();
    }
  };

  const handleClose = () => {
    setBody(comment.body!);
    onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Edit Comment</DialogTitle>
      <DialogContent>
        <RichTextInput
          value={body}
          onChange={(body) => setBody(body)}
          className={classes.bodyInput}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={handleUpdate}
          color='primary'
          disabled={!body || updatingComment}
        >
          Save
        </Button>
        <Button variant='outlined' onClick={handleClose} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommentEdit;
