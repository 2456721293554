import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  flag: {
    width: '100%',
  },
  popover: {
    width: 240,
  },
}));

const languageOptions: any = {
  en: {
    icon: '/static/flags/uk_flag.svg',
    label: 'English',
  },
  de: {
    icon: '/static/flags/de_flag.svg',
    label: 'German',
  },
  es: {
    icon: '/static/flags/es_flag.svg',
    label: 'Spanish',
  },
};

const LanguagePopover = () => {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: any) => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  const selectedOption = languageOptions[i18n.language];

  return (
    <>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
          }}
        >
          <img alt={selectedOption.label} src={selectedOption.icon} className={classes.flag} />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          className: classes.popover,
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                  className={classes.flag}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  {languageOptions[language].label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
