export const rootConfig = {
  staticFileRoot: process.env.REACT_APP_STATIC_FILE_ROOT,
  basename: process.env.REACT_APP_BASENAME,
  apiDocsRoute: process.env.REACT_APP_API_DOCS_ROUTE,
  odataRoute: process.env.REACT_APP_ODATA_ROUTE,
  apiRoute: process.env.REACT_APP_API_ROUTE,
  serverUrl: process.env.REACT_APP_SERVER_URL,
  avatarFallbackRoute: process.env.REACT_APP_AVATAR_FALLBACK_ROUTE,
  passwordRegex:
    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
};
