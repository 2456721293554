import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { IChangeEvent, withTheme } from '@rjsf/core';
import { Theme } from '@rjsf/material-ui';

const Form = withTheme(Theme);

interface IProps {
  schema: RJSFSchema;
  uiSchema: UiSchema;
  formData?: Record<string, any>;
  onChange?: (data: Record<string, any>) => void;
  onSubmit?: (event: IChangeEvent<any, RJSFSchema, any>) => void;
}

const RenderForm = ({ schema, uiSchema, formData, onChange, onSubmit }: IProps) => {
  const handleChange = (event: IChangeEvent<any, RJSFSchema, any>) => {
    onChange && onChange(event.formData ?? {});
  };

  const handleSubmit = (event: IChangeEvent<any, RJSFSchema, any>) => {
    onSubmit && onSubmit(event);
  };

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      onChange={handleChange}
      onSubmit={handleSubmit}
      formData={formData}
    />
  );
};

export default RenderForm;
