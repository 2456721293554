import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, IconButton, Toolbar, makeStyles, Theme } from '@material-ui/core';
import { AccountPopover, NotificationsPopover } from 'components/_dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import { LanguagePopover, SettingsPopover } from 'components';
import { rootConfig } from 'config';
import ChatPopover from '../ChatPopover';

const useStyles = makeStyles((theme: Theme | any) => ({
  root: {
    ...(theme.palette.mode === 'light' && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      color: theme.palette.text.primary,
      position: 'relative',
    }),
    ...(theme.palette.mode === 'dark' && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
      position: 'relative',
    }),
    zIndex: theme.zIndex.drawer + 100,
  },
  menuIconButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  logo: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

interface IProps {
  onSidebarMobileOpen: any;
}

const DashboardNavbar: React.FC<IProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const classes = useStyles();

  return (
    <AppBar className={classes.root} {...other}>
      <Toolbar style={{ height: '64px' }}>
        <IconButton
          color='inherit'
          onClick={onSidebarMobileOpen}
          className={classes.menuIconButton}
        >
          <MenuIcon fontSize='small' />
        </IconButton>
        <RouterLink to='/' className={classes.logo}>
          <img
            alt='iLearn'
            src={`${rootConfig.staticFileRoot}/static/logos/core.svg`}
            height='50'
            style={{ marginTop: 5, padding: 6 }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <Box sx={{ ml: 1 }}>
          <LanguagePopover />
        </Box> */}
        <Box sx={{ ml: 1 }}>
          <ChatPopover />
        </Box>
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 1 }}>
          <SettingsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
