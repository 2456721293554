import { AttendanceStatus } from 'app/enums/AttendanceStatus';
import { EntityContext } from 'app/enums/EntityContext';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ODataGrid } from 'components/_dashboard';
import React from 'react';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';

interface IProps {
  projectLearner: IProjectLearner;
}

const History: React.FC<IProps> = (props) => {
  const { projectLearner } = props;

  return (
    <ODataGrid
      entityType='manualAudits'
      filter={{
        entityContextId: projectLearner.id,
        entityContext: EntityContext[EntityContext.ProjectLearner],
      }}
      columns={[
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'actionDate',
          title: 'Date',
          type: 'datetime',
          filtering: false,
        },
        {
          field: 'field',
          title: 'Field',
        },
        {
          field: 'oldValues',
          title: 'Old Value',
        },
        {
          field: 'newValues',
          title: 'New Value',
        },
        {
          field: 'reason',
          title: 'Change Reason',
        },
      ]}
    />
  );
};

export default History;
