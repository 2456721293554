import useMounted from 'hooks/useMounted';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { IProject } from 'app/models/responses/IProject';
import { useCallback, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { rootConfig } from 'config';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import { IProjectLearnerMetricResponse } from 'app/models/responses/IProjectLearnerMetricResponse';

interface IProps {
  project: IProject;
}

const LearnerMetrics: React.FC<IProps> = (props) => {
  const { project } = props;
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [metrics, setMetrics] = useState<IProjectLearnerMetricResponse | null>(null);

  const getMetrics = useCallback(async () => {
    try {
      const response = await axios.get(
        `${rootConfig.odataRoute}/projects(${project.id})/getLearnerMetrics()`,
      );
      if (mounted.current) {
        setMetrics(response.data);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, project, enqueueSnackbar]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  if (!metrics) return null;

  return (
    <Card>
      <CardHeader title='Combined Learner Metrics' />
      <Divider />
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.learnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Total
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.enrolledLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Enrolled
                </Typography>
              }
            />
          </ListItem>

          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.abledLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Able Bodied
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.disabledLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Disabled
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.absorbedLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Absorbed
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.droppedOffLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Dropped Off
                </Typography>
              }
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Typography variant='h6'>{metrics.terminatedLearnerTotal}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography color='textPrimary' variant='subtitle2'>
                  Terminated
                </Typography>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default LearnerMetrics;
