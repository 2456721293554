import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import { IProjectLearnerHostSiteUpdateRequest } from 'app/models/requests/IProjectLearnerHostSiteUpdateRequest';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { ExceptionHandler } from 'components';
import { SelectOneAsync } from 'components/_dashboard';
import { rootConfig } from 'config';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import React from 'react';
import axios from 'utils/axios';

interface IProps {
  projectLearner: IProjectLearner;
  onSave: (projectLearnerHostSite: IProjectLearner) => void;
}

const Hosting: React.FC<IProps> = (props) => {
  const { projectLearner, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        hostSiteId: projectLearner.hostSiteId,
        reason: '',
        submit: null,
      }}
      validateOnChange
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          setSubmitting(true);
          const newEntity: IProjectLearnerHostSiteUpdateRequest = {
            hostSiteId: values.hostSiteId,
            reason: values.reason,
            entityContextId: projectLearner.id,
          };
          const expand = ['learner', 'mentor', 'project'];
          const queryString = buildQuery({ expand });
          const response = await axios.post(
            `${rootConfig.odataRoute}/projectLearners(${projectLearner.id})/updateHostSite${queryString}`,
            newEntity,
          );
          const updatedProjectLearner: IProjectLearner = response.data;
          resetForm({
            values: {
              hostSiteId: updatedProjectLearner.hostSiteId,
              reason: '',
              submit: null,
            },
          });
          projectLearner.hostSiteId = values.hostSiteId;
          onSave(projectLearner); //state
          enqueueSnackbar(`Successfully updated learner.`, { variant: 'success' });
          setStatus({ success: true });
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <Card>
              <CardHeader title='Hosting' />
              <Divider />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectOneAsync
                      name='hostSiteId'
                      entityId={values.hostSiteId || null}
                      entityType='hostSites'
                      labelFields={['name']}
                      filterFields={['name']}
                      onChange={(event: any, value: any) =>
                        setFieldValue('hostSiteId', value?.id || null)
                      }
                      label='Host Site'
                    />
                  </Grid>
                  {projectLearner.hostSiteId !== values.hostSiteId && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label='Reason for changing'
                        name='reason'
                        onChange={handleChange}
                        value={values.reason}
                        variant='outlined'
                        multiline
                        minRows={1}
                        maxRows={5}
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color='primary'
                  type='submit'
                  variant='contained'
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Save Changes
                </Button>
              </CardActions>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default Hosting;
