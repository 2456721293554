import {
  Card,
  CardHeader,
  Divider,
  TextField,
  Button,
  CardContent,
  Grid,
  CardActions,
} from '@material-ui/core';
import React from 'react';
import * as Yup from 'yup';
import * as jsonpatch from 'fast-json-patch';
import { Formik } from 'formik';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import buildQuery from 'odata-query';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { ICompanyInfo } from 'app/models/responses/ICompanyInfo';

interface IProps {
  parentEntityType: 'facilitators';
  parentEntityId: number;
  companyInfo: ICompanyInfo | null;
  onCompanyInfoUpdate?: (updatedCompanyInfo: ICompanyInfo) => void;
}

const GenericCompanyInfoForm: React.FC<IProps> = (props) => {
  const { companyInfo, parentEntityId, parentEntityType, onCompanyInfoUpdate } = props;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        name: companyInfo?.name || '',
        registrationNumber: companyInfo?.registrationNumber || '',
        vatNumber: companyInfo?.vatNumber || '',
        individualTaxNumber: companyInfo?.individualTaxNumber || '',
        submit: null,
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        registrationNumber: Yup.string(),
        vatNumber: Yup.string(),
        individualTaxNumber: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          const originalEntity: any = {
            companyInfo,
          };
          const updatedEntity: any = {
            companyInfo: {
              ...companyInfo,
              ...values,
            },
          };
          delete updatedEntity.companyInfo['submit'];
          const diff = jsonpatch.compare(originalEntity, updatedEntity);
          const expand = {};
          const queryString = buildQuery({ expand });
          const response = await axios.patch(
            `${rootConfig.odataRoute}/${parentEntityType}(${parentEntityId})${queryString}`,
            diff,
          );
          const updatedCompanyInfo: ICompanyInfo = response.data.companyInfo;
          resetForm({
            values: {
              name: updatedCompanyInfo?.name || '',
              registrationNumber: updatedCompanyInfo?.registrationNumber || '',
              vatNumber: updatedCompanyInfo?.vatNumber || '',
              individualTaxNumber: updatedCompanyInfo?.individualTaxNumber || '',
              submit: null,
            },
          });
          enqueueSnackbar(`Successfully updated company info.`, { variant: 'success' });
          setStatus({ success: true });
          onCompanyInfoUpdate && onCompanyInfoUpdate(updatedCompanyInfo);
        } catch (error) {
          enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
          setStatus({ success: false });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
        dirty,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Card>
            <CardHeader title='Company' />
            <Divider />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label='Name'
                    name='name'
                    onChange={handleChange}
                    value={values.name}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.registrationNumber && errors.registrationNumber)}
                    fullWidth
                    helperText={touched.registrationNumber && errors.registrationNumber}
                    label='Registration Number'
                    name='registrationNumber'
                    onChange={handleChange}
                    value={values.registrationNumber}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.vatNumber && errors.vatNumber)}
                    fullWidth
                    helperText={touched.vatNumber && errors.vatNumber}
                    label='VAT Number'
                    name='vatNumber'
                    onChange={handleChange}
                    value={values.vatNumber}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.individualTaxNumber && errors.individualTaxNumber)}
                    fullWidth
                    helperText={touched.individualTaxNumber && errors.individualTaxNumber}
                    label='Individual Tax Number'
                    name='individualTaxNumber'
                    onChange={handleChange}
                    value={values.individualTaxNumber}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                disabled={isSubmitting || !isValid || !dirty}
                type='submit'
                variant='contained'
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GenericCompanyInfoForm;
