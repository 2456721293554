import { IProject } from 'app/models/responses/IProject';
import { ODataGrid, SelectOneAsync } from 'components/_dashboard';
import React, { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { rootConfig } from 'config';
import { ExceptionHandler } from 'components';
import { ILearner } from 'app/models/responses/ILearner';
import odataLookupObjFromEnum from 'utils/odataLookupObjFromEnum';
import { ApprovalRequestStatus } from 'app/enums/ApprovalRequestStatus';
import { Chip, LinearProgress, Typography } from '@material-ui/core';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';
import { EXTRATIMEREQUESTCOLORS } from '../../../../../constants';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';
import { EntityContext } from 'app/enums/EntityContext';
import { CourseType } from 'app/enums/CourseType';
import moment from 'moment';
import { IVenue } from 'app/models/responses/IVenue';

interface IProps {
  project: IProject;
}

const TrainingSessions: React.FC<IProps> = (props) => {
  const { project } = props;
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [revisionLookup, setRevisionLookup] = useState<any>(undefined);
  const [venueLookup, setVenueLookup] = useState<any>(undefined);

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const select = ['id', 'name'];
        const filter = { learnershipRevisionId: project.courseRevisionId };
        const queryString = buildQuery({ select, filter });
        const response = await axios.get(
          `${rootConfig.odataRoute}/learnershipRevisionSections${queryString}`,
        );
        if (mounted) {
          const revisionLookups: any = {};
          response.data.value.forEach((revision: ILearnershipRevisionSection) => {
            revisionLookups[revision.id!] = revision.name;
          });
          setRevisionLookup(revisionLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();
    (async () => {
      try {
        const select = ['id', 'name'];
        const queryString = buildQuery({ select });
        const response = await axios.get(`${rootConfig.odataRoute}/venues${queryString}`);
        if (mounted) {
          const venueLookups: any = {};
          response.data.value.forEach((venue: IVenue) => {
            venueLookups[venue.id!] = venue.name;
          });
          setVenueLookup(venueLookups);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar]);

  if (!revisionLookup || !venueLookup) {
    return <LinearProgress />;
  }

  return (
    <ODataGrid
      entityType='projectEvents'
      filter={{ projectId: project.id, projectEventTypeId: 1 }}
      orderBy={['start asc']}
      option={{
        grouping: project.courseRevision?.course?.type === CourseType[CourseType.Learnership],
      }}
      columns={[
        {
          field: 'id',
          hidden: true,
        },
        {
          field: 'date',
          isBorrowed: true,
          filtering: false,
          grouping: false,
          title: 'Date',
          editable: 'never',
          render: (rowData) => <span>{moment(rowData.start).format('DD MMMM YYYY')}</span>,
        },
        {
          field: 'start',
          title: 'Start Time',
          type: 'time',
          filtering: false,
          grouping: false,
          render: (rowData) => <span>{moment(rowData.start).format('hh:mm A')}</span>,
        },
        {
          field: 'end',
          title: 'End Time',
          type: 'time',
          filtering: false,
          grouping: false,
          render: (rowData) => <span>{moment(rowData.end).format('hh:mm A')}</span>,
        },
        {
          field: 'venueId',
          title: 'Venue',
          lookup: venueLookup,
          initialEditValue: project.venueId,
          editable: 'onUpdate',
          validate: (rowData) =>
            !rowData.venueId ? { isValid: false, helperText: 'Venue is required' } : true,
          editComponent: (props) => (
            <SelectOneAsync
              name='venueId'
              entityId={props.value || null}
              entityType='venues'
              labelFields={['name']}
              filterFields={['name']}
              onChange={(event: any, value: any) => props.onChange(value?.id)}
              label='Search venue'
              required
              error={props.error}
            />
          ),
        },
        {
          field: 'parentContextId',
          title: 'Section',
          lookup: revisionLookup,
          hidden: project.courseRevision?.course?.type !== CourseType[CourseType.Learnership],
          validate: (rowData) =>
            !rowData.parentContextId ? { isValid: false, helperText: 'Section is required' } : true,
          editComponent: (props) => (
            <SelectOneAsync
              name='learnershipRevisionSectionId'
              entityId={props.value || null}
              entityType='learnershipRevisionSections'
              filter={{ learnershipRevisionId: project.courseRevisionId }}
              labelFields={['name']}
              filterFields={['name']}
              onChange={(event: any, value: any) => props.onChange(value?.id)}
              label='Search section'
              required
              error={props.error}
            />
          ),
        },
        {
          field: 'parentContext',
          title: 'Parent Context',
          initialEditValue:
            project.courseRevision?.course?.type === CourseType[CourseType.Learnership]
              ? EntityContext[EntityContext.LearnershipRevisionSection]
              : null,
          hidden: true,
        },
        {
          field: 'projectId',
          title: 'Project',
          initialEditValue: project.id,
          hidden: true,
        },
      ]}
      canDelete
      canEdit
    />
  );
};

export default TrainingSessions;
