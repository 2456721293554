import React, { useState, useEffect, useCallback } from 'react';
import {
  Link,
  Box,
  LinearProgress,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Page, GenericHeader, ODataGrid } from 'components/_dashboard';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from 'hooks/useMounted';
import { IMoney } from 'app/models/responses/IMoney';
import { IAccountEntry } from 'app/models/responses/IAccountEntry';
import { AccountEntryType } from 'app/enums/AccountEntryType';
import { rootConfig } from 'config';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import { ExceptionHandler } from 'components';
import MaterialTable, { Options } from 'material-table';
import { Currency } from 'app/enums/Currency';
import odataOptionsArrayFromEnum from 'utils/odataOptionsArrayFromEnum';
import { IApplicationUser } from 'app/models/responses/IApplicationUser';

interface IProps {
  className?: string;
  user: IApplicationUser;
}

const GenericAccountEntries: React.FC<IProps> = (props) => {
  const { className, user } = props;
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [accountEntries, setAccountEntries] = useState<any>(null);
  const [currency, setCurrency] = useState(user.currency!);

  const getAccountEntries = useCallback(async () => {
    try {
      const response = await axios.get(
        `${rootConfig.odataRoute}/users(${user.id})/getAccountEntries(currency=\'${currency}\')`,
      );
      if (mounted.current) {
        setAccountEntries(response.data.value);
      }
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  }, [mounted, user.id, enqueueSnackbar, currency]);

  useEffect(() => {
    getAccountEntries();
  }, [getAccountEntries]);

  if (!accountEntries) return <LinearProgress />;

  return (
    <div className={className}>
      <MaterialTable
        title={
          <Grid container spacing={1} style={{ marginTop: '10px' }}>
            <Grid item>
              <Typography variant='h6'>Account Entries</Typography>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant='outlined' size='small'>
                <InputLabel>Currency</InputLabel>
                <Select
                  value={currency}
                  label='Currency'
                  onChange={(e: any) => setCurrency(e.target.value)}
                  name='currency'
                  variant='outlined'
                >
                  {odataOptionsArrayFromEnum(Currency, true).map((currency) => (
                    <MenuItem key={currency.value} value={currency.value}>
                      {currency.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        }
        data={accountEntries}
        options={{
          emptyRowsWhenPaging: false,
          pageSizeOptions: [5, 10, 25, 50, 100],
          pageSize: 10,
          columnsButton: true,
        }}
        columns={[
          {
            field: 'createdDate',
            title: 'Date',
            type: 'date',
          },
          {
            field: 'reference',
            title: 'Reference',
            sorting: false,
          },
          {
            field: 'price',
            title: 'Debit',
            render: (rowData) =>
              (rowData as IAccountEntry).type === AccountEntryType[AccountEntryType.Debit] &&
              (rowData.price as IMoney).amount?.toFixed(2),
            sorting: false,
          },
          {
            field: 'price',
            title: 'Credit',
            render: (rowData) =>
              (rowData as IAccountEntry).type === AccountEntryType[AccountEntryType.Credit] &&
              (rowData.price as IMoney).amount?.toFixed(2),
            sorting: false,
          },
          {
            field: 'balance',
            title: 'Balance',
            render: (rowData) => (rowData.balance as IMoney).amount?.toFixed(2),
            sorting: false,
          },
          {
            field: 'createdByUserFullName',
            title: 'User',
            sorting: false,
          },
        ]}
      />
    </div>
  );
};

export default GenericAccountEntries;
