import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { ExceptionHandler } from 'components';
import { rootConfig } from 'config';
import query from 'devextreme/data/query';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import axios from 'utils/axios';
import { ILearnerPortalStaticContentResponse } from 'app/models/responses/ILearnerPortalStaticContentResponse';
import { Box, LinearProgress } from '@material-ui/core';
import { ProjectEventTypeStaticContent } from 'app/enums/ProjectEventTypeStaticContent';
import { CourseUnitContent } from './components';

interface IProps {
  projectEvent: IProjectEvent;
}

const StaticContent = ({ projectEvent }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rawContent, setRawContent] = useState<ILearnerPortalStaticContentResponse>();

  useEffect(() => {
    let mounted = true;

    (async () => {
      try {
        const response = await axios.get(
          `${rootConfig.odataRoute}/projectEvents(${projectEvent.id})/getLearnerPortalStaticContent()`,
        );
        if (mounted) {
          setRawContent(response.data);
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, projectEvent]);

  if (!rawContent) return <LinearProgress />;

  return (
    <Fragment>
      {rawContent.isValid ? (
        <Box>
          {projectEvent.projectEventType?.learnerPortalStaticContent ===
            ProjectEventTypeStaticContent[ProjectEventTypeStaticContent.CourseUnit] && (
            <CourseUnitContent rawData={rawContent.rawData} />
          )}
        </Box>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default StaticContent;
