import React, { useState, Fragment } from 'react';
import {
  Card,
  Box,
  Button,
  TextField,
  makeStyles,
  CardContent,
  Typography,
  Switch,
  Grid,
  CardActions,
} from '@material-ui/core';
import buildQuery from 'odata-query';
import axios from 'utils/axios';
import { useSnackbar } from 'notistack';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';

import { ExceptionHandler } from 'components';
import { EntityContext } from 'app/enums/EntityContext';
import { IFolderItem } from 'app/models/responses/IFolderItem';
import { rootConfig } from 'config';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface IProps {
  onAdd: (folderItem: IFolderItem) => void;
  parentContext: EntityContext;
  parentContextId: number;
  parentFolderItemId?: number | null;
}

const FolderAdd: React.FC<IProps> = (props) => {
  const { onAdd, parentContext, parentContextId, parentFolderItemId } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isExpanded, setExpanded] = useState(false);
  const [name, setName] = useState('');

  const handleChange = (event: React.ChangeEvent<any>) => {
    event.persist();
    setName(event.target.value);
  };

  const handleAddInit = () => {
    setExpanded(true);
  };

  const handleAddCancel = () => {
    setExpanded(false);
    setName('');
  };

  const handleAdd = async () => {
    if (!name) return;

    const folderToAdd = {
      parentContext: EntityContext[parentContext],
      parentContextId,
      parentFolderItemId,
      name,
    };

    try {
      setExpanded(false);
      setName('');
      const expand = {
        childFileItems: { top: 0, count: true },
        childFolderItems: { top: 0, count: true },
      };
      const queryString = buildQuery({ expand });
      const response = await axios.post(
        `${rootConfig.odataRoute}/folderItems${queryString}`,
        folderToAdd,
      );
      onAdd(response.data);
      enqueueSnackbar('Successfully added folder.', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
    }
  };

  return (
    <Card className={classes.root}>
      <Fragment>
        {isExpanded ? (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Name'
                  name='name'
                  onChange={handleChange}
                  value={name}
                  variant='outlined'
                />
              </Grid>
            </Grid>
            <Box mt={2} display='flex' justifyContent='space-between'>
              <Button onClick={handleAddCancel} variant='text'>
                Cancel
              </Button>
              <Button onClick={handleAdd} variant='contained' color='primary' disabled={!name}>
                Add
              </Button>
            </Box>
          </CardContent>
        ) : (
          <CardActions>
            <Button color='secondary' fullWidth variant='text' onClick={handleAddInit}>
              <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <CreateNewFolderOutlinedIcon fontSize='small' />
                <Grid item>New Folder</Grid>
              </Grid>
            </Button>
          </CardActions>
        )}
      </Fragment>
    </Card>
  );
};

export default FolderAdd;
