import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { IProjectLearner } from 'app/models/responses/IProjectLearner';
import { IProjectLearnerMilestone } from 'app/models/responses/IProjectLearnerMilestone';
import { rootConfig } from 'config';
import * as jsonpatch from 'fast-json-patch';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import axios from 'utils/axios';
import buildQuery from 'odata-query';

import deepCopy from 'utils/deepCopy';
import { EntityContext } from 'app/enums/EntityContext';
import { ILearnershipRevisionSection } from 'app/models/entities/ILearnershipRevisionSection';

interface IProps {
  learners: IProjectLearner[];
  section?: ILearnershipRevisionSection;
  onSave: (updatedLearners: IProjectLearner[]) => void;
}

const EditLearnerFeedback: React.FC<IProps> = (props) => {
  const { learners, section, onSave } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open, setOpen] = useState(false);
  const [formStates, setFormStates] = useState<IProjectLearner[]>([]);

  useEffect(() => {
    setFormStates([...learners]);
  }, [learners]);

  const handleClose = () => {
    setOpen(false);
    setFormStates([...learners]);
  };

  const handleSave = () => {
    try {
      let updatedLearners: IProjectLearner[] = [];
      formStates!.forEach(async (projectLearner) => {
        const oldProjectLearner = (deepCopy(learners) as IProjectLearner[]).find(
          (e) => e.id === projectLearner.id,
        )!;
        const expand = ['learner'];
        const queryString = buildQuery({ expand });
        const diff = jsonpatch.compare(oldProjectLearner, projectLearner);
        const response = await axios.patch<IProjectLearner>(
          `${rootConfig.odataRoute}/projectLearners(${projectLearner.id})${queryString}`,
          diff,
        );
        updatedLearners = [...updatedLearners].concat(response.data);
        onSave(updatedLearners.sort((a, b) => a.id! - b.id!));
      });

      enqueueSnackbar('Successfully updated feedback.', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Error updating feedback. Please try again later.', {
        variant: 'error',
      });
    } finally {
      setOpen(false);
    }
  };

  const handleChange = (
    eventTargetName: string,
    projectLearnerMilestone: IProjectLearnerMilestone | null | undefined,
    value: any,
    projectLearner: IProjectLearner,
  ) => {
    if (projectLearnerMilestone) {
      const updatedMilestone: IProjectLearnerMilestone = {
        ...projectLearnerMilestone,
        [eventTargetName]: value,
      };
      const updatedMilestones = [...(projectLearner.projectLearnerMilestones || [])];
      updatedMilestones[
        updatedMilestones
          .map((e) => `${e.parentContext!}${e.parentContextId!}`)
          .indexOf(
            `${projectLearnerMilestone.parentContext!}${projectLearnerMilestone.parentContextId!}`,
          )
      ] = updatedMilestone;
      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerMilestones: updatedMilestones,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    } else {
      const updatedMilestone: IProjectLearnerMilestone = {
        [eventTargetName]: value,
        parentContext: EntityContext[
          EntityContext.LearnershipRevisionSection
        ] as keyof typeof EntityContext,
        parentContextId: section?.id,
      };
      const updatedMilestones = [...(projectLearner.projectLearnerMilestones || [])].concat(
        updatedMilestone,
      );

      const updatedLearner: IProjectLearner = {
        ...projectLearner,
        projectLearnerMilestones: updatedMilestones,
      };

      const updatedLearners = [...formStates];
      updatedLearners[updatedLearners.indexOf(projectLearner)] = updatedLearner;
      setFormStates(updatedLearners);
    }
  };

  return (
    <>
      <Tooltip title='Manage learner feedback.'>
        <IconButton onClick={() => setOpen(true)} color='secondary'>
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth='lg' fullScreen={fullScreen} open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{section ? section.name : 'Project'} Outcome</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems='center'>
            {formStates.map((projectLearner) => {
              const learnerMilestone = section
                ? projectLearner.projectLearnerMilestones?.find(
                    (learnerMilestone) =>
                      learnerMilestone.parentContext ===
                        EntityContext[EntityContext.LearnershipRevisionSection] &&
                      learnerMilestone.parentContextId === section.id,
                  )
                : projectLearner.projectLearnerMilestones &&
                  projectLearner.projectLearnerMilestones.length >= 1
                ? projectLearner.projectLearnerMilestones[0]
                : null;

              return (
                <Fragment key={projectLearner.id}>
                  <Grid item xs={2}>
                    <Typography variant='body2'>{projectLearner?.learner?.fullName}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body2'>{projectLearner?.learner?.idNumber}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label='Feedback'
                      name='feedback'
                      variant='outlined'
                      value={learnerMilestone?.feedback}
                      multiline
                      minRows={3}
                      maxRows={5}
                      onChange={(event: React.ChangeEvent<any>) =>
                        handleChange(
                          event.target.name,
                          learnerMilestone,
                          event.target.value,
                          projectLearner,
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label='iLearn Feedback'
                      name='internalFeedback'
                      variant='outlined'
                      value={learnerMilestone?.internalFeedback}
                      multiline
                      minRows={3}
                      maxRows={5}
                      onChange={(event: React.ChangeEvent<any>) =>
                        handleChange(
                          event.target.name,
                          learnerMilestone,
                          event.target.value,
                          projectLearner,
                        )
                      }
                    />
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleSave} color='primary'>
            Save
          </Button>
          <Button variant='outlined' onClick={handleClose} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditLearnerFeedback;
