import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827',
};

const baseOptions = {
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'h6',
      },
      subheaderTypographyProps: {
        variant: 'body2',
      },
    },
  },
  overrides: {
    MuiCardHeader: {
      root: {
        padding: '32px 24px',
      },
    },
    MuiCardActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '32px 24px',
        '&:last-child': {
          paddingBottom: '32px',
        },
      },
    },
    MuiAvatar: {
      fallback: {
        height: '75%',
        width: '75%',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      sizeSmall: {
        padding: '4px 12px',
      },
      sizeMedium: {
        padding: '8px 20px',
      },
      sizeLarge: {
        padding: '11px 24px',
      },
      textSizeSmall: {
        padding: '7px 12px',
      },
      textSizeMedium: {
        padding: '9px 16px',
      },
      textSizeLarge: {
        padding: '12px 16px',
      },
    },
    MuiIconButton: {
      root: {
        padding: 8,
      },
      sizeSmall: {
        padding: 4,
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: '10px',
      },
    },
    MuiPaper: {
      root: {
        backgroundImage: 'none',
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: '0px !important',
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 0,
        textTransform: 'none',
        '& + &': {
          marginLeft: 24,
        },
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: 'none',
        '& .MuiTableCell-root': {
          borderBottom: 'none',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0.5,
          textTransform: 'uppercase',
        },
        '& .MuiTableCell-paddingCheckbox': {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.57,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      lineHeight: 2.5,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    h2: {
      fontWeight: 700,
      fontSize: '3rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    h5: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: 1.375,
      fontFamily:
        '"Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    },
  },
  shape: {
    borderRadius: 16,
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
          },
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: neutral[100],
          '.MuiTableCell-root': {
            color: neutral[700],
          },
        },
      },
    },
    palette: {
      action: {
        active: '#6b778c',
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff',
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#00B3F0',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#f04c00',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    overrides: {
      MuiPopover: {
        paper: {
          borderColor: 'rgba(145, 158, 171, 0.24)',
          borderStyle: 'solid',
          borderWidth: 1,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
        },
      },
      MuiButton: {
        contained: {
          backgroundColor: '#111827',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#0B0F19',
          },
        },
      },
      MuiTableHead: {
        root: {
          backgroundColor: neutral[800],
          '.MuiTableCell-root': {
            color: neutral[300],
          },
        },
      },
      MuiIconButton: {
        root: {
          color: '#919eab',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: 'rgba(145, 158, 171, 0.24)',
        },
      },
      MuiInput: {
        underline: {
          '&&&:before': {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
          '&:hover&&:before': {
            borderBottom: '2px solid #fff',
          },
        },
      },

      MuiAlert: {
        standardInfo: {
          color: '#a6d5fa',
          backgroundColor: '#030e18',
        },
        standardError: {
          color: '#fab3ae',
          backgroundColor: '#180605',
        },
        standardWarning: {
          color: '#ffd599',
          backgroundColor: '#190f00',
        },
        standardSuccess: {
          color: '#b7dfb9',
          backgroundColor: '#071107',
        },
        outlinedInfo: {
          color: '#a6d5fa',
        },
        outlinedError: {
          color: '#fab3ae',
        },
        outlinedWarning: {
          color: '#ffd599',
        },
        outlinedSuccess: {
          color: '#b7dfb9',
        },
      },
      MuiChip: {
        root: {
          backgroundColor: '#222b36',
          color: '#ffffff',
        },
        clickable: {
          '&:hover, &:focus': {
            backgroundColor: '#141921',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#0B0F19',
        paper: '#111827',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#00B3F0',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#f04c00',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config: any = {}) => {
  let themeOptions: any = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(merge(baseOptions, themeOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
