import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
  Box,
  Card,
  Theme,
  Typography,
  alpha,
  makeStyles,
  useMediaQuery,
  Link,
} from '@material-ui/core';
import buildQuery, { Expand } from 'odata-query';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { IODataResponse } from 'app/models/responses/IODataResponse';
import { IProject } from 'app/models/responses/IProject';
import { IProjectEvent } from 'app/models/responses/IProjectEvent';
import { ExceptionHandler } from 'components';
import { Page, Tip } from 'components/_dashboard';
import { rootConfig } from 'config';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { LegacyRef, useEffect, useRef, useState } from 'react';
import axios from 'utils/axios';
import { EventDetails, Toolbar } from './components';
import { View } from './components/Toolbar/Toolbar';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme | any) => ({
  calendarContainer: {
    '& .fc-license-message': {
      display: 'none',
    },
    '& .fc': {
      '--fc-bg-event-opacity': 1,
      '--fc-border-color': theme.palette.divider,
      '--fc-daygrid-event-dot-width': '10px',
      '--fc-event-text-color': '#ffffff',
      '--fc-list-event-hover-bg-color': theme.palette.background.default,
      '--fc-neutral-bg-color': theme.palette.background.default,
      '--fc-page-bg-color': theme.palette.background.default,
      '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.25),
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
    },
    '& .fc .fc-col-header-cell-cushion': {
      paddingBottom: '10px',
      paddingTop: '10px',
    },
    '& .fc .fc-day-other .fc-daygrid-day-top': {
      color: theme.palette.text.secondary,
    },
    '& .fc-daygrid-event': {
      padding: '10px',
    },
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface IProps {}

const mapProjectEvent = (projectEvent: IProjectEvent) => {
  return {
    ...projectEvent,
    color: projectEvent.projectEventType!.color,
    title: projectEvent.title || projectEvent.projectEventType!.title,
    start: projectEvent.start!,
  };
};

const LearnerCalendar: React.FC<IProps> = (props) => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const calendarRef: LegacyRef<FullCalendar> = useRef(null);
  const mobileDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [selectedProjectEvent, setSelectedProjectEvent] = useState<IProjectEvent | null>(null);
  const [projectEvents, setProjectEvents] = useState<any[]>([]);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState<View>(mobileDevice ? 'listWeek' : 'dayGridMonth');

  useEffect(() => {
    let mounted = true;

    if (!user) return undefined;

    (async () => {
      try {
        const expand: Expand<IProjectEvent> = {
          project: {
            expand: {
              courseRevision: {
                expand: {
                  course: {
                    select: ['id', 'name'] as any,
                  },
                },
              },
            },
          },
          projectEventType: {},
        };
        const query = buildQuery({ expand });
        const response = await axios.get<IODataResponse<IProjectEvent[]>>(
          `${rootConfig.odataRoute}/projectEvents/getLearnerCalendarEvents(learnerId=${user.id},projectId=null)${query}`,
        );
        if (mounted) {
          setProjectEvents(
            (response.data.value ?? []).map((e: IProjectEvent) => mapProjectEvent(e)),
          );
        }
      } catch (error) {
        enqueueSnackbar(<ExceptionHandler exception={error} />, { variant: 'error' });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [enqueueSnackbar, user]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? 'listWeek' : 'dayGridMonth';

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: View) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleProjectEventSelect = (arg: EventClickArg) => {
    const targetEvent = projectEvents.find((e) => String(e.id) === arg.event.id);
    setSelectedProjectEvent(targetEvent ?? null);
  };

  return (
    <Page title='Calendar'>
      <Box sx={{ mb: 2 }}>
        <Link component={RouterLink} to={`/`} color='textPrimary'>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <ArrowBackIcon fontSize='small' />
            <Typography>Home</Typography>
          </Box>
        </Link>
      </Box>
      <Typography variant='h4'>Calendar</Typography>
      <Typography variant='subtitle2' color='textSecondary'>
        View your upcoming events
      </Typography>
      <Alert severity='info' variant='filled' className={classes.alert}>
        Click on an event to view its details.
      </Alert>
      <Box sx={{ mt: 1 }}>
        <Toolbar
          date={date}
          onDateNext={handleDateNext}
          onDatePrev={handleDatePrev}
          onDateToday={handleDateToday}
          onViewChange={handleViewChange}
          view={view}
        />
      </Box>
      <Box
        sx={{
          mt: 3,
          p: 2,
        }}
      >
        <Card>
          <div className={classes.calendarContainer}>
            <FullCalendar
              allDayMaintainDuration
              dayMaxEventRows={3}
              editable={false}
              eventClick={handleProjectEventSelect}
              eventDisplay='block'
              events={projectEvents}
              headerToolbar={false}
              height={mobileDevice ? 550 : 700}
              initialDate={date}
              initialView={view}
              displayEventTime={true}
              plugins={[dayGridPlugin, interactionPlugin, listPlugin, timeGridPlugin]}
              ref={calendarRef}
              rerenderDelay={10}
              selectable
              weekends
              allDaySlot
            />
          </div>
        </Card>
      </Box>

      {!!selectedProjectEvent && (
        <EventDetails
          projectEvent={selectedProjectEvent}
          onClose={() => setSelectedProjectEvent(null)}
        />
      )}
    </Page>
  );
};

export default LearnerCalendar;
